<header>
  <div class="container-wrap zone-menu">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 text-md-left text-center">
          <h1 class="big-title text-uppercase py-3 py-md-4 lnh-24">Vos cartes</h1>
        </div>
        <div class="col-12 col-md-6 text-md-right py-3 py-md-4 text-center align-middle lnh-24">
          <a routerLink="{{'/' + url.CARTE_SELECTION}}" class="top-menu mr-3 active">
            Vos cartes ({{nbCarte}})
          </a>
          <button type="button" (click)="besoinAide()" class="top-menu btn-none">
            Besoin d'aide
          </button>
        </div>
      </div>
    </div>
  </div>
</header>
