import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from '../services/user.service';
import {NGXLogger} from 'ngx-logger';
import {CarteService} from '../services/carte.service';
import {Url} from '../constant/url';

@Injectable({
  providedIn: 'root'
})
export class CarteSelectioneGuard implements CanActivate {

  constructor(
    private readonly userService: UserService,
    private readonly carteService: CarteService,
    private readonly logger: NGXLogger,
    private readonly router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.carteService.selectedCard){
      return true;
    } else {
      this.logger.debug('Redirection selection carte');
      this.router.navigateByUrl('/' + Url.CARTE_SELECTION);
      return false;
    }
  }
}
