/**
 * web services MooF adapté pour BLP2
 * Web Services REST **V1.0** pour le Home Banking de **OFINA** * 0.01: version initale (gestion du login) * 0.02: gestion de la création du login BLP * 0.03: gestion de l'ANR * 0.04: ajout du nom du client et du numéro 3ds dans le retour d'authentification * 0.05: liste des cartes * 0.06: détail carte * 0.07: détail point * 0.08: P3F liste des opérations * 0.09: P3F fractionnement * 0.10: gestion des versions * 0.11: infos sejour * 0.12: cartes supplémentaires * 0.13: coordonnées * 0.14: messagerie * 0.15: parametres * 0.16: oppositions * 0.17: déplafonnements * 0.18: activation carte * 0.19: notification push des messages * 0.20: catalogue Rewards * 0.21: transfert d'argent * 0.22: crédit * 0.23: ajout données NC * 2.00: nouveaux services pour BLP2
 *
 * OpenAPI spec version: 2.00
 * Contact: info@piti.pf
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../Conf/encoder';

import { Observable }                                        from 'rxjs';

import { CompensationOperation } from '../model/compensationOperation';
import { NotificationOperation } from '../model/notificationOperation';
import { Operation } from '../model/operation';
import { PeriodeOperations } from '../model/periodeOperations';
import { PeriodesSituation } from '../model/periodesSituation';
import { RecherchePeriode } from '../model/recherchePeriode';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../Conf/variables';
import { Configuration }                                     from '../Conf/configuration';


@Injectable(
  {providedIn: 'root'}
)
export class OperationService {

    protected basePath = 'https://moof-api.piti.pf';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Consultation des opérations d&#39;une carte
     *
     * @param id id de la carte
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detailOperation(id: string, observe?: 'body', reportProgress?: boolean): Observable<PeriodesSituation>;
    public detailOperation(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PeriodesSituation>>;
    public detailOperation(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PeriodesSituation>>;
    public detailOperation(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling detailOperation.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PeriodesSituation>(`${this.basePath}/operations/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

  /**
   * Recupere les info necessaire a la compensation d&#39;une partie de la transaction en point Aircalin
   *
   * @param idCarte id de la carte
   * @param idOperation id de l&#39;operation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public infoRemboursementPointAC(idCarte: string, idOperation: number, observe?: 'body', reportProgress?: boolean): Observable<CompensationOperation>;
  public infoRemboursementPointAC(idCarte: string, idOperation: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompensationOperation>>;
  public infoRemboursementPointAC(idCarte: string, idOperation: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompensationOperation>>;
  public infoRemboursementPointAC(idCarte: string, idOperation: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (idCarte === null || idCarte === undefined) {
      throw new Error('Required parameter idCarte was null or undefined when calling infoRemboursementPointAC.');
    }

    if (idOperation === null || idOperation === undefined) {
      throw new Error('Required parameter idOperation was null or undefined when calling infoRemboursementPointAC.');
    }

    let headers = this.defaultHeaders;

    // authentication (token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Token-moof"]) {
      headers = headers.set('Token-moof', this.configuration.apiKeys["Token-moof"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.get<CompensationOperation>(`${this.basePath}/operations/${encodeURIComponent(String(idCarte))}/${encodeURIComponent(String(idOperation))}/compensationPointAC`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Notification d&#39;une opération carte
   *
   * @param operation les données de l&#39;opération à notifier
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public notificationOperation(operation: NotificationOperation, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public notificationOperation(operation: NotificationOperation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public notificationOperation(operation: NotificationOperation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public notificationOperation(operation: NotificationOperation, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (operation === null || operation === undefined) {
      throw new Error('Required parameter operation was null or undefined when calling notificationOperation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/notification/operation`,
      operation,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Consultation des dernières opérations d&#39;une cartre
   *
   * @param id id de la carte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public operationsRecentes(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Operation>>;
  public operationsRecentes(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Operation>>>;
  public operationsRecentes(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Operation>>>;
  public operationsRecentes(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling operationsRecentes.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Operation>>(`${this.basePath}/operations/${encodeURIComponent(String(id))}/recentes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Consultation d&#39;une opérations d&#39;une carte sur une période
     *
     * @param id id de la carte
     * @param recherche la période que l&#39;on recherche
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public periodeOperation(id: string, recherche: RecherchePeriode, observe?: 'body', reportProgress?: boolean): Observable<PeriodeOperations>;
    public periodeOperation(id: string, recherche: RecherchePeriode, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PeriodeOperations>>;
    public periodeOperation(id: string, recherche: RecherchePeriode, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PeriodeOperations>>;
    public periodeOperation(id: string, recherche: RecherchePeriode, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling periodeOperation.');
        }

        if (recherche === null || recherche === undefined) {
            throw new Error('Required parameter recherche was null or undefined when calling periodeOperation.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PeriodeOperations>(`${this.basePath}/operations/${encodeURIComponent(String(id))}/periodes`,
            recherche,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Télechargement d&#39;un fichier excel contenant les opérations d&#39;une carte sur une période
     *
     * @param id id de la carte
     * @param recherche la période que l&#39;on recherche
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public periodeOperationExcel(id: string, recherche: RecherchePeriode, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public periodeOperationExcel(id: string, recherche: RecherchePeriode, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public periodeOperationExcel(id: string, recherche: RecherchePeriode, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public periodeOperationExcel(id: string, recherche: RecherchePeriode, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling periodeOperationExcel.');
        }

        if (recherche === null || recherche === undefined) {
            throw new Error('Required parameter recherche was null or undefined when calling periodeOperationExcel.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.ms-excel'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.basePath}/operations/${encodeURIComponent(String(id))}/periodes/excel`,
            recherche,
            {
                responseType: 'blob',
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Télechargement d&#39;un pdf contenant les opérations d&#39;une carte sur une période
     *
     * @param id id de la carte
     * @param recherche la période que l&#39;on recherche
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public periodeOperationPdf(id: string, recherche: RecherchePeriode, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public periodeOperationPdf(id: string, recherche: RecherchePeriode, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public periodeOperationPdf(id: string, recherche: RecherchePeriode, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public periodeOperationPdf(id: string, recherche: RecherchePeriode, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling periodeOperationPdf.');
        }

        if (recherche === null || recherche === undefined) {
            throw new Error('Required parameter recherche was null or undefined when calling periodeOperationPdf.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/pdf'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.basePath}/operations/${encodeURIComponent(String(id))}/periodes/pdf`,
            recherche,
            {
                responseType: 'blob',
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

  /**
   * Compensation d&#39;une partie de la transaction en point Aircalin
   *
   * @param idCarte id de la carte
   * @param idOperation id de l&#39;operation
   * @param nbPoint le nombre de point a compenser
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public remboursementPointAC(idCarte: string, idOperation: number, nbPoint: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public remboursementPointAC(idCarte: string, idOperation: number, nbPoint: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public remboursementPointAC(idCarte: string, idOperation: number, nbPoint: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public remboursementPointAC(idCarte: string, idOperation: number, nbPoint: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (idCarte === null || idCarte === undefined) {
      throw new Error('Required parameter idCarte was null or undefined when calling remboursementPointAC.');
    }

    if (idOperation === null || idOperation === undefined) {
      throw new Error('Required parameter idOperation was null or undefined when calling remboursementPointAC.');
    }

    if (nbPoint === null || nbPoint === undefined) {
      throw new Error('Required parameter nbPoint was null or undefined when calling remboursementPointAC.');
    }

    let headers = this.defaultHeaders;

    // authentication (token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["Token-moof"]) {
      headers = headers.set('Token-moof', this.configuration.apiKeys["Token-moof"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/operations/${encodeURIComponent(String(idCarte))}/${encodeURIComponent(String(idOperation))}/compensationPointAC`,
      nbPoint,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
