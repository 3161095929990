import {Component} from '@angular/core';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout-reinit-mdp.component.html',
  styleUrls: ['./content-layout-reinit-mdp.component.css']
})
export class ContentLayoutReinitMdpComponent {


}
