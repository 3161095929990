

<ng-container *ngIf="!notSupported" >
  <router-outlet></router-outlet>

<app-modal></app-modal>
<app-toast aria-atomic="true" aria-live="polite"></app-toast>
</ng-container>

<main *ngIf="notSupported" class="main">
  <div class="container">
    <div class="jumbotron bg-white">
      <h3 class="display-5"> Votre navigateur n'est pas pris en charge</h3>
      <hr class="my-4">
      <p> Votre navigateur actuel ne vous permet pas d’accéder à l’Espace Client OFINA.</p>
      <p>Cliquez sur le bouton "mettre à jour" pour télécharger un navigateur compatible.</p>
    </div>
  </div>
</main>
