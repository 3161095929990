import { Component, OnInit } from '@angular/core';
import {CarteService} from '../../services/carte.service';
import {Url} from '../../constant/url';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header-selection',
  templateUrl: './header-selection-page.component.html',
  styleUrls: ['./header-selection-page.component.css']
})
export class HeaderSelectionPageComponent implements OnInit {
  nbCarte: number;
  url = Url;

  constructor(
    private readonly carteService: CarteService,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
    const pCarte = this.carteService.portefeuilleCarte;
    this.nbCarte = pCarte.getNbCarte();
  }

  besoinAide(): void {
    this.router.navigateByUrl('/' + Url.BESOIN_AIDE);
  }
}
