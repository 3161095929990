import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {LaddaModule} from 'angular2-ladda';
import {NgBootstrapFormValidationModule} from 'ng-bootstrap-form-validation';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {AppConstantes} from './classes/app-constantes';
import {FooterComponent} from './components/footer/footer.component';
import {ContentLayoutSelectionComponent} from './layouts/content-layout-selection/content-layout-selection.component';
import {PageErreurComponent} from './pages/page-erreur/page-erreur.component';
import {BaseComponent} from './classes/base-component';
import {TokenInterceptor} from './interceptors/token.interceptor';
import {UnauthorizedInterceptor} from './interceptors/unauthorized.interceptor';
import {MyCurrencyPipe} from './pipes/custom.currency.pipe';
import {ContentLayoutCardComponent} from './layouts/content-layout-card/content-layout-card.component';
import {HeaderCardPageComponent} from './components/header-card-page/header-card-page.component';
import {HeaderSelectionPageComponent} from './components/header-selection-page/header-selection-page.component';
import {StyleCurrencyPipe} from './pipes/style.curency.pipe';
import {SidebarComponent} from './components/sidebar/sidebar/sidebar.component';
import {SidebarServicesComponent} from './components/sidebar/sidebar-services/sidebar-services.component';
import {SidebarRelevesComponent} from './components/sidebar/sidebar-releves/sidebar-releves.component';
import {SidebarCompteComponent} from './components/sidebar/sidebar-compte/sidebar-compte.component';
import {SidebarButtonComponent} from './components/sidebar/sidebar-button/sidebar-button.component';
import {AjaxLoaderComponent} from './components/ajax-loader/ajax-loader.component';
import {HeaderTopComponent} from './components/header-top/header-top.component';
import {ContentLayoutComponent} from './layouts/content-layout/content-layout.component';
import {ToastComponent} from './components/toast/toast.component';
import {NgbToastModule} from '@ng-bootstrap/ng-bootstrap';
import {ModalComponent} from './components/modal/modal.component';
import {DEFAULT_TIMEOUT, TimeoutInterceptor} from './interceptors/timeout.interceptor';
import {MyDatePipe} from './pipes/my-date.pipe';
import {CoreFaqComponent} from './components/faq/core-faq.component';
import {CarteImageComponent} from './components/carte-image/carte-image.component';
import {ContentLayoutReinitMdpComponent} from './layouts/content-layout-reinit-mdp/content-layout-reinit-mdp.component';


@NgModule({
  declarations: [
    FooterComponent,
    PageErreurComponent,
    ContentLayoutSelectionComponent,
    BaseComponent,
    MyCurrencyPipe,
    ContentLayoutCardComponent,
    HeaderCardPageComponent,
    HeaderSelectionPageComponent,
    StyleCurrencyPipe,
    SidebarComponent,
    SidebarServicesComponent,
    SidebarRelevesComponent,
    SidebarCompteComponent,
    SidebarButtonComponent,
    AjaxLoaderComponent,
    HeaderTopComponent,
    ModalComponent,
    ContentLayoutComponent,
    ToastComponent,
    MyDatePipe,
    CoreFaqComponent,
    CarteImageComponent,
    ContentLayoutReinitMdpComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgBootstrapFormValidationModule.forRoot(AppConstantes.ngBoostrapFormValidationParams),
    LaddaModule,
    FormsModule,
    HttpClientModule,
    LoggerModule.forRoot({level: NgxLoggerLevel.DEBUG, serverLogLevel: NgxLoggerLevel.OFF}),
    NgbToastModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptor,
      multi: true,
    },
    {
      provide: DEFAULT_TIMEOUT,
      useValue: 60000
    }

  ],
  exports: [
    FooterComponent,
    ContentLayoutCardComponent,
    ContentLayoutSelectionComponent,
    HeaderCardPageComponent,
    HeaderSelectionPageComponent,
    MyCurrencyPipe,
    StyleCurrencyPipe,
    SidebarComponent,
    SidebarButtonComponent,
    AjaxLoaderComponent,
    ToastComponent,
    ModalComponent,
    MyDatePipe,
    CoreFaqComponent,
    CarteImageComponent,
  ],
})
export class CoreModule {
}
