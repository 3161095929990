import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Url} from '../../constant/url';
import {NGXLogger} from 'ngx-logger';
import {Subscription} from 'rxjs';
import {DataMessageService} from '../../services/data-message.service';

@Component({
  selector: 'app-page-erreur',
  templateUrl: './page-erreur.component.html',
  styleUrls: ['./page-erreur.component.css']
})
export class PageErreurComponent implements OnInit, OnDestroy {
  /** utilisé pour détruit les observables */
  subscription: Subscription = new Subscription();
  /** l'erreur a afficher */
  public erreur: number;
  /** Url du portefeuille de carte */
  portefeuilleCarte = '/' + Url.CARTE_SELECTION;
  /** Indique si l'application est initialisée */
  init: boolean;

  constructor(
    private readonly router: Router,
    private readonly logger: NGXLogger,
    private readonly activatedRoute: ActivatedRoute,
    private readonly dataMessageService: DataMessageService
  ) {}

  ngOnInit(): void {
    this.subscription = this.activatedRoute.data.subscribe(value => {
      const data = value;
      const state = history.state;
      if (state && state.erreur) {
        this.erreur = state.erreur;
      } else if (data) {
        this.erreur = data.erreur;
      }
    });
    this.init = this.dataMessageService.init;
  }

  /** Recharge l'application */
  reloadApp(): void {
    window.location.href = '/';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
