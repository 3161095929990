import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {NGXLogger} from 'ngx-logger';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Modal} from '../../models/modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  @ViewChild('content')
  private readonly contentTpl: TemplateRef<any>;

  /** La modale */
  modale: Modal;
  /** Les types de modale */
  enum = Modal.EnumModalType;
  /** Soumission en cours (ladda) */
  submitEnCours = false;
  /** Modale ouverte */
  private openedModal: NgbModalRef;

  constructor(private readonly modalService: ModalService,
              private readonly modalServiceNgb: NgbModal,
              private readonly logger: NGXLogger) {}

  ngOnInit(): void {
    this.modalService.event.subscribe( modal => {
      this.modale = modal;
      this.openModal();
    });
    this.modalService.eventSubmit.subscribe((bool) => {
      this.toggleSubmitEncours(bool);
    });
    this.modalService.eventClose.subscribe((motif) => {
      this.close(motif);
    } );
  }

  /**
   * Ouvre la modale
   */
  openModal() {
    this.logger.debug('modalComponent', this.modale);
    this.submitEnCours = false;
    this.openedModal = this.modalServiceNgb.open(this.contentTpl,
      {
        size: 'sm',
        windowClass: 'test',
        centered: true,
        backdropClass: 'light-grey-backdrop',
        keyboard: false,
        backdrop: 'static'
      });
  }

  /**
   * Au click sur ok
   */
  onClickOk(): void {
    if (this.modale.clickOk) {
      this.submitEnCours = true;
      this.modale.clickOk();

    } else {
      this.submitEnCours = false;
      this.modalService.close();
    }
  }

  /**
   * Au click sur cancel
   */
  onClickCancel(): void {
    if (this.modale.clickCancel) {
      this.modale.clickCancel();
    } else {
      this.submitEnCours = false;
      this.modalService.close();
    }
  }

  /** Ferme la modale */
  close(motif: string): void {
    this.logger.debug('Fermeture de la modale :', motif);
    this.submitEnCours = false;
    this.openedModal?.close();
  }

  /** Change la valeur de submit en cours  */
  toggleSubmitEncours(bool: boolean): void {
    this.submitEnCours = bool;
  }
}
