import { Component, OnInit } from '@angular/core';
import {MenuService} from '../../../services/menu.service';
import {SituationCarte} from '../../../models/situation-carte';
import {CarteService} from '../../../services/carte.service';

@Component({
  selector: 'app-sidebar-releves',
  templateUrl: './sidebar-releves.component.html',
  styleUrls: ['./sidebar-releves.component.css']
})
export class SidebarRelevesComponent implements OnInit {
  carte: SituationCarte;

  constructor(private readonly menuService: MenuService,
              private readonly carteService: CarteService) { }

  ngOnInit(): void {
    this.carte = this.carteService.selectedCard;
  }

  /**
   * @returns l'id de l'accordéon déplié
   */
  loadIdAccordionExpanded(): string {
    return this.menuService.idAccordionExpanded;
  }
}
