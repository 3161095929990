import { Component} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Url} from '../../constant/url';
import {CarteService} from '../../services/carte.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.css']
})
export class HeaderTopComponent  {
  url = Url;

  constructor(
    private readonly userService: UserService,
    private readonly carteService: CarteService,
    private readonly router: Router,
  ) { }


  logOut() {
    this.userService.logOut();
  }

  homeLink() {
    if (this.userService.user.isNC()) {
      return 'http://americanexpress.nc';
    } else {
      return 'http://americanexpress.pf';
    }
  }

  /**
   * Accès a la page des contacts
   */
  nouscontacter(): void {
     this.router.navigateByUrl('/' + Url.NOUS_CONTACTER);
  }
}
