<ul class="navbar-nav mr-auto sidenav" id="navAccordion2" >

  <li class="nav-item">
    <a class="nav-link"
       routerLink="/releves-et-activites/details-operations"
       routerLinkActive="active">
      Détails de vos opérations
    </a>
  </li>

  <li class="nav-item">
    <a class="nav-link"
      routerLink="/releves-et-activites/compte-fidelite"
       routerLinkActive="active" >
      Compte {{carte.nomPoint()}}
    </a>
  </li>

</ul>
