import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ContentLayoutSelectionComponent} from './core/layouts/content-layout-selection/content-layout-selection.component';
import {PageErreurComponent} from './core/pages/page-erreur/page-erreur.component';
import {AuthGuard} from './core/guards/auth.guard';
import {ContentLayoutCardComponent} from './core/layouts/content-layout-card/content-layout-card.component';
import {ContentLayoutComponent} from './core/layouts/content-layout/content-layout.component';
import {Url} from './core/constant/url';
import {CarteSelectioneGuard} from './core/guards/carte-selectione.guard';
import {SelecDefaultCarteGuard} from './core/guards/selec-default-carte.guard';
import {ModificationMdpGuard} from './core/guards/modification-mdp.guard';
import {ContentLayoutReinitMdpComponent} from './core/layouts/content-layout-reinit-mdp/content-layout-reinit-mdp.component';

const routes: Routes = [

  {
    path: Url.AUTHENTIFICATION_BASE,
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: Url.ERREUR_BASE,
    component: PageErreurComponent,
  },
  {
    path: Url.PREMIERE_CONNEXION_BASE,
    loadChildren: () => import('./modules/premiere-connexion/premiere-connexion.module').then((m) => m.PremiereConnexionModule),
  },
  {
    path: Url.ACTIVATION_CARTE_BASE,
    loadChildren: () => import('./modules/activation-carte/activation-carte.module').then((m) => m.ActivationCarteModule),
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [ModificationMdpGuard],
    children: [
      {
        path: Url.ERREUR_BASE,
        component: PageErreurComponent,
      },
      {
        path: Url.REINIT_MOT_PASSE_BASE,
        component: ContentLayoutReinitMdpComponent,
        loadChildren: () => import('./modules/reinit-mdp/reinit-mdp.module').then((s) => s.ReinitMdpModule),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/' + Url.CARTE_SELECTION,
      },
      {
        path: Url.CARTE_SELECTION_BASE,
        component: ContentLayoutSelectionComponent,
        loadChildren: () => import('./modules/selection-carte/selection-carte.module').then((s) => s.SelectionCarteModule),
      },
      {
        path: Url.PAGE_ACCUEIL_BASE,
        component: ContentLayoutCardComponent,
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: '',
        component: ContentLayoutCardComponent,
        canActivate: [CarteSelectioneGuard],
        children: [
          {
            path: Url.SERVICES_BASE,
            loadChildren: () => import('./modules/services/services.module').then((m) => m.ServicesModule),
          },
          {
            path: Url.GESTION_COMPTE_BASE,
            loadChildren: () => import('./modules/gestion-compte/gestion-compte.module').then((m) => m.GestionCompteModule),
          },
          {
            path: Url.SMS_RENFORCE_BASE,
            loadChildren: () => import('./modules/sms-renforce/sms-renforce.module').then((m) => m.SmsRenforceModule),
          },
        ]
      },
      {
        path: Url.RELEVE_ACTIVITES_BASE,
        canActivate: [CarteSelectioneGuard],
        loadChildren: () => import('./modules/releves/releves.module').then((m) => m.RelevesModule),
      },
      {
        path: Url.BESOIN_AIDE_BASE,
        component: ContentLayoutCardComponent,
        canActivate: [SelecDefaultCarteGuard],
        loadChildren: () => import('./modules/besoin-aide/besoin-aide.module').then((m) => m.BesoinAideModule),
      },
    ],
  },


  // Fallback when no prior routes is matched
  {path: '**', component: PageErreurComponent, data: {erreur: 404}},
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
