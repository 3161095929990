/**
 * web services MooF adapté pour BLP2
 * Web Services REST **V1.0** pour le Home Banking de **OFINA** * 0.01: version initale (gestion du login) * 0.02: gestion de la création du login BLP * 0.03: gestion de l'ANR * 0.04: ajout du nom du client et du numéro 3ds dans le retour d'authentification * 0.05: liste des cartes * 0.06: détail carte * 0.07: détail point * 0.08: P3F liste des opérations * 0.09: P3F fractionnement * 0.10: gestion des versions * 0.11: infos sejour * 0.12: cartes supplémentaires * 0.13: coordonnées * 0.14: messagerie * 0.15: parametres * 0.16: oppositions * 0.17: déplafonnements * 0.18: activation carte * 0.19: notification push des messages * 0.20: catalogue Rewards * 0.21: transfert d'argent * 0.22: crédit * 0.23: ajout données NC * 2.00: nouveaux services pour BLP2
 *
 * OpenAPI spec version: 2.00
 * Contact: info@piti.pf
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs/';

import {ActivationCarte} from '../model/activationCarte';
import {CartePoints} from '../model/cartePoints';
import {Deplafonnement} from '../model/deplafonnement';
import {InfoAnr} from '../model/infoAnr';
import {InfosDeplafonnement} from '../model/infosDeplafonnement';
import {MotifOpposition} from '../model/motifOpposition';
import {ParamAlerteCarte} from '../model/paramAlerteCarte';
import {PortefeuilleCarte} from '../model/portefeuilleCarte';
import {SituationCartePoints} from '../model/situationCartePoints';

import {BASE_PATH} from '../Conf/variables';
import {Configuration} from '../Conf/configuration';


@Injectable({
  providedIn: 'root'
})
export class CarteService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'https://moof-api.piti.pf';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * Activation d&#39;une carte
   *
   * @param activationCarte l&#39;activation de la carte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public carteActivation(activationCarte: ActivationCarte, observe?: 'body', reportProgress?: boolean): Observable<InfoAnr>;

  public carteActivation(activationCarte: ActivationCarte, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InfoAnr>>;

  public carteActivation(activationCarte: ActivationCarte, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InfoAnr>>;

  public carteActivation(activationCarte: ActivationCarte, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (activationCarte === null || activationCarte === undefined) {
      throw new Error('Required parameter activationCarte was null or undefined when calling carteActivation.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<InfoAnr>(`${this.basePath}/cartes/activation`,
      activationCarte,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Déplafonnement d&#39;une carte
   *
   * @param id ID de la carte
   * @param deplafonnement le deplafonnement
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public carteDeplafonnement(id: string, deplafonnement: Deplafonnement, observe?: 'body', reportProgress?: boolean): Observable<InfoAnr>;

  public carteDeplafonnement(id: string, deplafonnement: Deplafonnement, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InfoAnr>>;

  public carteDeplafonnement(id: string, deplafonnement: Deplafonnement, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InfoAnr>>;

  public carteDeplafonnement(id: string, deplafonnement: Deplafonnement, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling carteDeplafonnement.');
    }

    if (deplafonnement === null || deplafonnement === undefined) {
      throw new Error('Required parameter deplafonnement was null or undefined when calling carteDeplafonnement.');
    }

    let headers = this.defaultHeaders;

    // authentication (token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
      headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<InfoAnr>(`${this.basePath}/cartes/${encodeURIComponent(String(id))}/deplafonnement`,
      deplafonnement,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Passage en freeze d&#39;une carte active
   *
   * @param id ID de la carte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public carteFreeze(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public carteFreeze(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public carteFreeze(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public carteFreeze(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling carteFreeze.');
    }

    let headers = this.defaultHeaders;

    // authentication (token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
      headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/vnd.moof.v10+json'
    ];

    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.basePath}/cartes/${encodeURIComponent(String(id))}/freeze`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Remonte la liste des informations pour un déplafonnement
   *
   * @param id ID de la carte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public carteInfosDeplafonnement(id: string, observe?: 'body', reportProgress?: boolean): Observable<InfosDeplafonnement>;

  public carteInfosDeplafonnement(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InfosDeplafonnement>>;

  public carteInfosDeplafonnement(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InfosDeplafonnement>>;

  public carteInfosDeplafonnement(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling carteInfosDeplafonnement.');
    }

    let headers = this.defaultHeaders;

    // authentication (token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
      headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<InfosDeplafonnement>(`${this.basePath}/cartes/${encodeURIComponent(String(id))}/infosDeplafonnement`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Mise en opposition d&#39;une carte
   *
   * @param id ID de la carte
   * @param motif le motif de l&#39;opposition
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public carteOpposition(id: string, motif: MotifOpposition, observe?: 'body', reportProgress?: boolean): Observable<InfoAnr>;

  public carteOpposition(id: string, motif: MotifOpposition, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InfoAnr>>;

  public carteOpposition(id: string, motif: MotifOpposition, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InfoAnr>>;

  public carteOpposition(id: string, motif: MotifOpposition, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling carteOpposition.');
    }

    if (motif === null || motif === undefined) {
      throw new Error('Required parameter motif was null or undefined when calling carteOpposition.');
    }

    let headers = this.defaultHeaders;

    // authentication (token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
      headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<InfoAnr>(`${this.basePath}/cartes/${encodeURIComponent(String(id))}/opposition`,
      motif,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Passage en unfreeze d&#39;une carte gelée
   *
   * @param id ID de la carte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public carteUnfreeze(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public carteUnfreeze(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public carteUnfreeze(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public carteUnfreeze(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling carteUnfreeze.');
    }

    let headers = this.defaultHeaders;

    // authentication (token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
      headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/vnd.moof.v10+json'
    ];

    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.basePath}/cartes/${encodeURIComponent(String(id))}/unfreeze`,
      null,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Recupère la liste des cartes
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cartes(observe?: 'body', reportProgress?: boolean): Observable<PortefeuilleCarte>;

  public cartes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PortefeuilleCarte>>;

  public cartes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PortefeuilleCarte>>;

  public cartes(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
      headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<PortefeuilleCarte>(`${this.basePath}/cartes`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Récupère la liste des cartes à points
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public cartesRewards(observe?: 'body', reportProgress?: boolean): Observable<Array<CartePoints>>;

  public cartesRewards(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CartePoints>>>;

  public cartesRewards(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CartePoints>>>;

  public cartesRewards(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<CartePoints>>(`${this.basePath}/cartes/rewards`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Récupère les parametres des alertes associé au compte carte
   *
   * @param id ID de la carte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCartesParamAlerte(id: string, observe?: 'body', reportProgress?: boolean): Observable<ParamAlerteCarte>;

  public getCartesParamAlerte(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParamAlerteCarte>>;

  public getCartesParamAlerte(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParamAlerteCarte>>;

  public getCartesParamAlerte(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getCartesAlerte.');
    }

    let headers = this.defaultHeaders;

    // authentication (token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
      headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/vnd.moof.v10+json'
    ];

    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.get<ParamAlerteCarte>(`${this.basePath}/cartes/${encodeURIComponent(String(id))}/paramAlertes`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Change les parametres des alertes associé au compte carte
   *
   * @param id ID de la carte
   * @param alertes
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public setCartesParamAlerte(id: string, alertes: ParamAlerteCarte, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public setCartesParamAlerte(id: string, alertes: ParamAlerteCarte, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public setCartesParamAlerte(id: string, alertes: ParamAlerteCarte, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public setCartesParamAlerte(id: string, alertes: ParamAlerteCarte, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling setCartesAlerte.');
    }

    if (alertes === null || alertes === undefined) {
      throw new Error('Required parameter alertes was null or undefined when calling setCartesAlerte.');
    }

    let headers = this.defaultHeaders;

    // authentication (token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
      headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/cartes/${encodeURIComponent(String(id))}/paramAlertes`,
      alertes,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Récupèrre la situation carte enrichi des points
   *
   * @param id ID de la carte
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public situationCartePoints(id: string, observe?: 'body', reportProgress?: boolean): Observable<SituationCartePoints>;

  public situationCartePoints(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SituationCartePoints>>;

  public situationCartePoints(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SituationCartePoints>>;

  public situationCartePoints(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling situationCartePoints.');
    }

    let headers = this.defaultHeaders;

    // authentication (token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
      headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<SituationCartePoints>(`${this.basePath}/cartes/${encodeURIComponent(String(id))}/situationCarte`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

}
