import {SituationCarte as ApiSituationCarte} from '../../api/model/situationCarte';
import {Carte} from '../../api/model/carte';
import TypePointEnum = Carte.TypePointEnum;
import EtatCarteEnum = Carte.EtatCarteEnum;

export class SituationCarte implements  ApiSituationCarte {
  /**
   * id de la carte
   */
  id: string;
  /**
   * type de carte
   */
  typeCarte: string;
  /**
   * libelle de la carte
   */
  libelleCarte: string;
  /**
   * numéro de la carte masqué
   */
  numeroCarte: string;
  /**
   * type de point
   */
  typePoint?: Carte.TypePointEnum;
  /**
   * numéro fidélité
   */
  numeroFidelite?: string;
  /**
   * type de carte eligible P3F
   */
  eligibleP3f: boolean;
  /**
   * carte eligible Cnf
   */
  eligibleCnf: boolean;
  /**
   * disponible
   */
  disponible?: number;
  /**
   * encours crédit Plan&Pay
   */
  encoursCnf: number;
  /**
   * encours crédit fl3xpay
   */
  encoursP3f: number;
  /**
   * solde actuel sur la période en cours
   */
  soldeActuel: number;
  /**
   * date du jour coté serveur
   */
  dateJour: string;
  /**
   * état courant de la carte dans Set2U
   */
  etatCarte?: Carte.EtatCarteEnum;
  /**
   * nomPorteur
   */
  nomPorteur: string;

  /**
   * carte supplémentaire
   */
  carteSupp: boolean;

  /**
   * Titulaire
   */
  titulaire: boolean;


  constructor( readonly carte: ApiSituationCarte ) {
    this.dateJour = carte.dateJour;
    this.disponible = carte.disponible;
    this.eligibleCnf = carte.eligibleCnf;
    this.eligibleP3f = carte.eligibleP3f;
    this.encoursCnf = carte.encoursCnf;
    this.encoursP3f = carte.encoursP3f;
    this.id = carte.id;
    this.libelleCarte = carte.libelleCarte;
    this.numeroCarte = carte.numeroCarte;
    this.numeroFidelite = carte.numeroFidelite;
    this.soldeActuel = carte.soldeActuel;
    this.typeCarte = carte.typeCarte;
    this.typePoint = carte.typePoint;
    this.etatCarte = carte.etatCarte;
    this.nomPorteur = carte.nomPorteur;
    this.carteSupp = carte.carteSupp;
    this.titulaire = carte.titulaire;
  }

  /**
   * @returns le nom de ce type de points
   */
  nomPoint(): string {
    switch (this.typePoint) {
      case TypePointEnum.Miles: return 'Miles';
      case TypePointEnum.MilesQantas: return 'Points';
      case TypePointEnum.Rewards: return 'Points';
      case TypePointEnum.PointsAirCalin: return 'Points';
      default: return '';
    }
  }

  /**
   * @returns le nom de ce type de points
   */
  nomPointLong(): string {
    switch (this.typePoint) {
      case TypePointEnum.Miles: return 'Miles Club Tiare';
      case TypePointEnum.MilesQantas: return 'Points Qantas';
      case TypePointEnum.Rewards: return 'Points Privilèges';
      case TypePointEnum.PointsAirCalin: return 'Points Aircalin';
      default: return '';
    }
  }

  /**
   * @returns le libellé des points cumulés
   */
  pointCumul(): string {
    switch (this.typePoint) {
    case TypePointEnum.Miles: return 'acquis';
    case TypePointEnum.MilesQantas: return 'acquis';
    case TypePointEnum.Rewards: return 'cumulés';
      case TypePointEnum.PointsAirCalin: return '';
      default: return '';
    }
  }

  /**
   * @returns le nom de la compagnie d'avion en fonction du type de points
   *
   */
  compagnieAvion(): string {
    switch (this.typePoint) {
      case TypePointEnum.Miles: return 'air tahiti nui';
      case TypePointEnum.MilesQantas: return 'qantas';
      case TypePointEnum.PointsAirCalin: return 'aircalin';
      default: return '';
    }
  }

  /**
   * Correspondance entre le type de la carte et le nom de l'image a utiliser pour l'afficher
   */
  carteImageName(): string {
    switch (this.typeCarte) {
      case 'ATGC': return 'amex_atn_gold';
      case 'ATNC': return 'amex_atn_silver';
      case 'QAGC': return 'amex_qantas_gold';
      case 'QASC': return 'amex_qantas_silver';
      case 'GRVC': return 'amex_grvc';
      case 'GOCC': return 'amex_gold';
      case 'GONC': return 'amex_gold';
      case 'GRCC': return 'amex_green';
      case 'GRNC': return 'amex_green';
      case 'ACSC': return 'amex_aircalin';
      case 'ACGC': return 'amex_aircalin_gold';
      case 'ACTC': return 'amex_aircalin_titane';
      default: return '';
    }
  }

  /**
   * @returns si la carte est en mode freeze
   */
  isFreeze(): boolean {
    const e = EtatCarteEnum;

    return (this.etatCarte === e.Freeze);
  }

  /**
   * @returns si la carte est opposée
   */
  isOppose(): boolean {
    const e = EtatCarteEnum;

    return (this.etatCarte === e.Oppose);
  }

  /**
   * @returns si la carte est en mode bloqué
   */
  isBloque(): boolean {
    return (this.etatCarte === EtatCarteEnum.Bloque);
  }

  /**
   * @returns true si la carte est active
   */
  isActive(): boolean{
    return (this.etatCarte === EtatCarteEnum.Active);
  }


}
