import { Component} from '@angular/core';
import {MenuService} from '../../../services/menu.service';
import {Url} from '../../../constant/url';
import {Router} from '@angular/router';
import {CarteService} from '../../../services/carte.service';

@Component({
  selector: 'app-sidebar-services',
  templateUrl: './sidebar-services.component.html',
  styleUrls: ['./sidebar-services.component.css']
})
export class SidebarServicesComponent {

  url = Url;

  constructor(
    private readonly menuService: MenuService,
    public readonly carteService: CarteService,
    private readonly routerService: Router,
    ) { }


  /**
   * @returns l'id de l'accordéon ouvert
   */
  loadIdAccordionExpanded(): string {
    return this.menuService.idAccordionExpanded;
  }



  /**
   * Action au click sur l'intitule de l'accordéon
   *
   * @param idAccordion - id de l'accordéon ouvert
   * @param url - url de redirection
   */
  onClick(idAccordion: string, url: string): void {
    if ( this.loadIdAccordionExpanded()!== idAccordion){ // not open
      this.routerService.navigateByUrl('/' + url);
    } else {
      this.menuService.unExpandeAccordion();
    }
  }
}
