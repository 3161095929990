/**
 * web services MooF
 * Web Services REST **V1.0** pour le Home Banking de **OFINA** * 0.01: version initale (gestion du login) * 0.02: gestion de la création du login BLP * 0.03: gestion de l'ANR * 0.04: ajout du nom du client et du numéro 3ds dans le retour d'authentification * 0.05: liste des cartes * 0.06: détail carte * 0.07: détail point * 0.08: P3F liste des opérations * 0.09: P3F fractionnement * 0.10: gestion des versions * 0.11: infos sejour * 0.12: cartes supplémentaires * 0.13: coordonnées * 0.14: messagerie * 0.15: parametres * 0.16: oppositions * 0.17: déplafonnements * 0.18: activation carte * 0.19: notification push des messages * 0.20: catalogue Rewards * 0.21: transfert d'argent * 0.22: crédit * 0.23: ajout données NC
 *
 * OpenAPI spec version: 0.23
 * Contact: info@piti.pf
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../Conf/encoder';

import { Observable }                                        from 'rxjs/';

import { CoordonneeClient } from '../model/coordonneeClient';
import { DemandeCarteSupplementaire } from '../model/demandeCarteSupplementaire';
import { InfosSejour } from '../model/infosSejour';
import { JourPrelevement } from '../model/jourPrelevement';
import { ModificationCoordonneeClient } from '../model/modificationCoordonneeClient';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../Conf/variables';
import { Configuration }                                     from '../Conf/configuration';
import {EnumTraitement} from '../model/enumTraitement';


@Injectable({
  providedIn: 'root'
})
export class FormulaireService {

    protected basePath = 'https://be.piti.pf/MOOF/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Envoi d&#39;un formulaire de demande de carte supplémentaire
     *
     * @param demandeCarteSupplementaire demande de carte supplémentaire
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public carteSupplementaire(demandeCarteSupplementaire: DemandeCarteSupplementaire, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public carteSupplementaire(demandeCarteSupplementaire: DemandeCarteSupplementaire, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public carteSupplementaire(demandeCarteSupplementaire: DemandeCarteSupplementaire, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public carteSupplementaire(demandeCarteSupplementaire: DemandeCarteSupplementaire, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (demandeCarteSupplementaire === null || demandeCarteSupplementaire === undefined) {
            throw new Error('Required parameter demandeCarteSupplementaire was null or undefined when calling carteSupplementaire.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/formulaires/carteSupplementaire`,
            demandeCarteSupplementaire,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Envoi d&#39;un formulaire de modification des coordonnées client
     *
     * @param coordonnees demande de modification des coordonnées client
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public coordonneeClient(coordonnees: ModificationCoordonneeClient, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public coordonneeClient(coordonnees: ModificationCoordonneeClient, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public coordonneeClient(coordonnees: ModificationCoordonneeClient, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public coordonneeClient(coordonnees: ModificationCoordonneeClient, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (coordonnees === null || coordonnees === undefined) {
            throw new Error('Required parameter coordonnees was null or undefined when calling coordonneeClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/formulaires/coordonnees`,
            coordonnees,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

  /**
   * Envoi d&#39;un formulaire de modification des coordonnées client avec les pieces justificatives
   *
   * @param message demande de modification des coordonnées client à envoyer (sujet + message) en JSON. Il faut utiliser (&#39;#/definitions/ModificationCoordonneeClient&#39;)
   * @param fichiers la liste des fichiers pièces jointes
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public coordonneeClientPieceJointe(message: string, fichiers?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<EnumTraitement>;
  public coordonneeClientPieceJointe(message: string, fichiers?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EnumTraitement>>;
  public coordonneeClientPieceJointe(message: string, fichiers?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EnumTraitement>>;
  public coordonneeClientPieceJointe(message: string, fichiers?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (message === null || message === undefined) {
      throw new Error('Required parameter message was null or undefined when calling coordonneeClientPieceJointe.');
    }


    let headers = this.defaultHeaders;

    // authentication (token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
      headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'multipart/form-data'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): void | HttpParams };
    let useForm = false;
    const convertFormParamsToString = false;
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    }

    // let formParams = new FormData();

    if (message !== undefined) {
      // formParams.append('message', <any>message) ;
      // formParams.append('message', new Blob([message], {type: 'application/json'}))
      formParams = formParams.append('message', <any>message)  || formParams;


    }
    if (fichiers) {
      fichiers.forEach((element) => {
        formParams.append('fichiers', <any>element) ;
      });
    }

    return this.httpClient.post<EnumTraitement>(`${this.basePath}/formulaires/coordonnees/piece`,
      convertFormParamsToString ? formParams.toString() : formParams,
      // formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

    /**
     * Modification de la date de prélèvement
     *
     * @param jourPrelevement jour de prélèvement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public datePrelevement(jourPrelevement: JourPrelevement, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public datePrelevement(jourPrelevement: JourPrelevement, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public datePrelevement(jourPrelevement: JourPrelevement, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public datePrelevement(jourPrelevement: JourPrelevement, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (jourPrelevement === null || jourPrelevement === undefined) {
            throw new Error('Required parameter jourPrelevement was null or undefined when calling datePrelevement.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/formulaires/datePrelevement`,
            jourPrelevement,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Récupération des infos client
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public infoClient(observe?: 'body', reportProgress?: boolean): Observable<CoordonneeClient>;
    public infoClient(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CoordonneeClient>>;
    public infoClient(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CoordonneeClient>>;
    public infoClient(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CoordonneeClient>(`${this.basePath}/formulaires/coordonnees`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Envoi d&#39;un formulaire d&#39;informations séjours
     *
     * @param infosSejour les informations séjours
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public infosSejour(infosSejour: InfosSejour, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public infosSejour(infosSejour: InfosSejour, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public infosSejour(infosSejour: InfosSejour, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public infosSejour(infosSejour: InfosSejour, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (infosSejour === null || infosSejour === undefined) {
            throw new Error('Required parameter infosSejour was null or undefined when calling infosSejour.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/formulaires/infosSejour`,
            infosSejour,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
