<header>

  <!-- Début Zone menu 1 -->
  <div class="container-wrap zone-menu">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6  text-md-left text-center d-flex align-items-center justify-content-center justify-content-lg-start">
          <div class="row mt-1 w-100">
            <div
              class="col-12 col-lg-2  text-lg-left text-center d-flex align-items-center justify-content-center justify-content-lg-start mt-1 mt-lg-0 mb-4 mb-lg-0">
              <app-carte-image [carteSize]="'petit'" [carte]="carte"></app-carte-image>
            </div>
            <div class="col-12 col-lg-10 name-menu text-secondary text-lg-left text-center d-flex flex-column justify-content-center">
              <div>Bonjour <span class="font-weight-bold">
                {{user.prenomClient}}
                {{user.nomClient}}</span>, membre depuis {{memberSince}}.<br>
              </div>
              <div class="nomCarte">
                <span class="text-uppercase txt-13">{{carte?.libelleCarte}}</span>
                <br class="d-none d-lg-inline">
                <span> {{carte?.numeroCarte}}</span>
              </div>

            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 text-lg-right py-3 py-md-4 text-center align-middle lnh-24">
          <a class="top-menu mr-3" routerLink="{{'/' + url.CARTE_SELECTION}}">Vos cartes ({{nbCarte}})</a>
          <a class="top-menu" routerLink="{{'/' + url.BESOIN_AIDE}}">Besoin d'aide</a></div>
      </div>
    </div>
  </div>
  <!-- Fin Zone menu 1 -->
  <!-- Début Zone menu principal -->
  <div class="container-wrap zone-menu-principal">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav class="navbar navbar-expand-lg blpnavbar navbar-light">
            <button (click)="toogleNavBar()"
                    aria-controls="navBarNav"
                    aria-expanded="true"
                    aria-label="Toggle navigation"
                    class="navbar-toggler p-0 mt-1"
                    data-target="#navbarNav"
                    data-toggle="collapse"
                    type="button">
              <img alt="" src="assets/images/btn-menu-burger.png"/>
            </button>

            <div class="navbar-collapse collapse show" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item ">
                  <button (click)="onNavbarClick(url.PAGE_ACCUEIL_BASE)" [ngClass]="menuPageActive() === url.PAGE_ACCUEIL_BASE? 'active': ''"
                          class="nav-link btn btn-link pt-1 pb-0 py-lg-2"
                          id="accueil">Accueil
                  </button>
                </li>
                <li class="nav-item">
                  <button (click)="onRelevesClick()" [ngClass]="menuPageActive() === url.RELEVE_ACTIVITES_BASE? 'active': ''"
                          class="nav-link btn btn-link pt-1 pb-0 py-lg-2"
                          id="releves">Relevés & activités
                  </button>
                </li>
                <li class="nav-item">
                  <button (click)="onNavbarClick(url.SERVICES_BASE)" [ngClass]="menuPageActive() === url.SERVICES_BASE? 'active': ''"
                          class="nav-link btn btn-link pt-1 pb-0 py-lg-2"
                          id="services">Services
                  </button>
                </li>
                <li class="nav-item">
                  <button (click)="onNavbarClick(url.GESTION_COMPTE_BASE)" [ngClass]="menuPageActive() === url.GESTION_COMPTE_BASE? 'active': ''"
                          class="nav-link btn btn-link pt-1 pb-0 py-lg-2 pb-2"
                          id="compte">Gestion Compte
                  </button>
                </li>
                <li *ngIf="!user.keypass" class="nav-item">
                  <button (click)="onSmsRenforceClick()" [ngClass]="menuPageActive() === url.SMS_RENFORCE_BASE? 'active': ''"
                          class="nav-link btn btn-link text-success font-weight-bold pt-1 pb-0 py-lg-2 pb-2"
                          id="smsRenforce">Créer un code de sécurité renforcée
                  </button>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <!-- fin Zone menu principal -->
</header>

