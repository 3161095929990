import {Component, Input, OnInit} from '@angular/core';
import {SituationCarte} from '../../models/situation-carte';

@Component({
  selector: 'app-carte-image',
  templateUrl: './carte-image.component.html',
  styleUrls: ['./carte-image.component.css']
})
export class CarteImageComponent  {
  @Input()
  carte: SituationCarte;
  @Input()
  carteStatus: string;
  @Input()
  carteSize: string;

  imageName(): string{
    return (this.carteSize === 'petit')?
      `assets/images/carte/small/${this.carte?.carteImageName()}-small.png`:
     `assets/images/carte/regular/${this.carte.carteImageName()}.png`
    ;
  }

}
