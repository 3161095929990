import {BootstrapVersion} from 'ng-bootstrap-form-validation';
import {CustomErrors} from '../errors/custom-errors';
import {NgBootstrapFormValidationModuleOptions} from 'ng-bootstrap-form-validation/lib/Models/NgBootstrapFormValidationModuleOptions';

/** les constantes de l'application */
export class AppConstantes {
  static ngBoostrapFormValidationParams: NgBootstrapFormValidationModuleOptions = {
    bootstrapVersion: BootstrapVersion.Four,
    customErrorMessages: CustomErrors.CUSTOM_ERRORS,
  };

}
