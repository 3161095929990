import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {User} from '../models/user';
import {Authentification} from '../../api/model/authentification';
import {RetourAuthentification} from '../../api/model/retourAuthentification';
import {LoginService} from '../../api/api/login.service';
import {HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment.local-dev';
import {Router} from '@angular/router';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {DataMessageService} from './data-message.service';
import {ToastService} from './toast.service';
import {Identification} from '../../api/model/identification';
import {InfoAnr} from '../../api/model/infoAnr';
import {Activation} from '../../api/model/activation';
import {AnrPublic} from '../../api/model/anrPublic';
import {ModificationMotDePasse} from '../../api/model/modificationMotDePasse';
import {Url} from '../constant/url';
import {CarteService} from './carte.service';
import {ModifierCodeSmsRenforce} from '../../api/model/modifierCodeSmsRenforce';


/** interface de UserService */
export interface IUserService {
  codePays(): string;
}

/**
 * Service des utilisateurs.
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  /** Utilisateur connecté. */
  #user: User;
  /** Clé pour stocker l'utilisateur dans le sessionStorage */
  private readonly key = 'currentUser';
  /** État de l'idle */
  private idleEnCours = false;
  /** Idle défini ou non */
  private idleSet = false;
  /** Durée de vie du token */
  private dureeVieToken: number;
  /** Timeout pour la déconnexion automatique lorsque le token devient invalide */
  private timeoutDeconnexion: any;


  constructor(
    private readonly logger: NGXLogger,
    private readonly router: Router,
    private readonly loginService: LoginService,
    private readonly dataMessageService: DataMessageService,
    private readonly toastService: ToastService,
    private readonly carteService: CarteService,
    private readonly idle: Idle) {


    if (environment.dev) {
      const store = sessionStorage.getItem(this.key);
      if (store) {
        this.logger.debug('User fetch from the store', store);
        const a = JSON.parse((store));
        this.#user = new User(a.numCli, a);
        this.logger.debug('User created from the session', this.user);
        this.idle.watch();
      }
    }
  }

  /**
   * @returns L'utilisateur stocké dans le sessionStorage, sinon null
   */
  get user(): User {
    return this.#user;
  }

  /**
   * @returns le token ou null.
   */
  getToken(): string {
    if (this.user == null) {
      this.logger.debug('no token');
      return null;
    }
    this.logger.trace('token found: Bearer ', this.user.token);
    return this.user.token;
  }


  public connect(auth: Authentification): Promise<RetourAuthentification> {
    return new Promise((resolve, reject) => {
      this.loginService.login(auth).subscribe({
        next: (res: RetourAuthentification) => {
          this.logger.info('authentification succeed', auth.numeroAbonne, res);
          const dureeInactivite = +this.dataMessageService.findParam('inactivite.duree');
          this.dureeVieToken = +this.dataMessageService.findParam('token.duree.vie');
          this.configureIdle(dureeInactivite);

          this.#user = new User(auth.numeroAbonne, res);
          this.stockeUser(this.user);
          this.idle.watch();

          // Déconnecte automatiquement apres un certain temps
          this.timeoutDeconnexion = setTimeout(() => {
            this.logOut();
          }, this.dureeVieToken * 60 * 1000);
          resolve(res);
        },
        error: (err: HttpErrorResponse) => {
          this.logger.debug(err);
          reject(err);
        }
      });
    });
  }

  public isConnected() {
    const connected = (this.user !== null && this.user !== undefined);
    this.logger.trace('isconnected?', this.user, connected);
    return connected;
  }

  public logOut() {
    this.idle.stop();
    this.router.navigate(['/' + Url.LOGIN]).finally(() => {
      this.logger.debug('Log out : clear the storage');
      sessionStorage.clear();
      this.#user = null;
      this.dataMessageService.displayMessagePromo = true;
      this.dataMessageService.displayTextePromo = true;
      this.carteService.clearData();
    });
    clearTimeout(this.timeoutDeconnexion);
    localStorage.removeItem('ACCESS_TOKEN');
  }


  /**
   * Déclenche l'enregistrement d'un fichier
   *
   * @param file - le fichier a enregistrer
   * @param fileName - le nom du fichier
   * @param mimeType - le type du fichier
   */
  dowloadFile(file: Blob, fileName: string, mimeType?: string) {

    // https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api/52687792
    const blob = new Blob([file], {type: mimeType});

    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');

    anchor.download = fileName;
    anchor.href = url;
    // this is necessary as link.click() does not work on the latest firefox
    anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url);
      anchor.remove();
    }, 500);
  }

  /**
   * Récupère le nom d'un fichier depuis le header Content-disposition de la response Http
   * @param contentDisposition le contenu du header
   */
  public parseFilenameFromContentDisposition(contentDisposition: string) {
    if (!contentDisposition) {
      return null;
    }
    const matches = /filename="(.*)"/g.exec(contentDisposition);

    return matches && matches.length > 1 ? matches[1] : null;
  }

  /**
   * Retourne le nom de domaine
   */
  domaine(): string {
    const url = window.location.hostname.split('.');
    return url[url.length - 1];
  }

  /**
   * Retourne le code pays a utilisé
   * ( celui de l'utilisateur si il est connecte sinon celui extrait du nom de domaine
   */
  codePays(): string {
    if (this.user) {
      return this.user.codePays;
    } else {
      this.logger.debug('codePays from domaine');
      return this.domaine();
    }
  }

  /**
   * Verification de l'identification
   */
  verificationIdenfication(identification: Identification): Promise<InfoAnr> {
    return new Promise<InfoAnr>((resolve, reject) => {
      this.loginService.verificationIdentification(identification).subscribe({
        next: (res: InfoAnr) => {
          this.logger.info('verification authentification succeed ', res);
          resolve(res);
        },
        error: (err: HttpErrorResponse) => {
          this.logger.debug(err);
          reject(err);
        }
      });
    });
  }

  /**
   * Activation de l'identification
   */
  activationIdentification(anr: AnrPublic, motDePasse: string): Promise<void> {
    const activation: Activation = {
      anr,
      motDePasse
    };
    this.logger.debug('Appel login service', activation);
    return new Promise<void>((resolve, reject) => {
      this.loginService.activationIdentification(activation).subscribe({
        next: () => {
          this.logger.info('activation authentification succeed ');
          resolve();
        },
        error: (err: HttpErrorResponse) => {
          this.logger.debug(err);
          reject(err);
        }
      });
    });

  }

  /**
   * Change le mot de passe
   *
   * @param oldPwd - l'ancien mot de passe
   * @param newPwd - le nouveau mot de passe
   * @returns une promesse d'ANR
   */
  changeMotPasse(oldPwd: string, newPwd: string): Promise<InfoAnr> {
    const modif: ModificationMotDePasse = {
      ancienMotDePasse: oldPwd,
      nouveauMotDePasse: newPwd
    };
    return new Promise<InfoAnr>((resolve, reject) => {
      this.loginService.modificationMotPasse(modif).subscribe({
        next: (value: InfoAnr) => {
          resolve(value);
        },
        error: (err) => {
          reject(err);
        }
      });
    });
  }

  /**
   * Change du code de sécurité renforcé
   *
   * @param codeSecuriteRenforce - code de sécurité renforcé
   * @returns une promesse
   */
  changeCodeSecuriteRenforce(codeSecuriteRenforce: string): Promise<void> {
    const modif: ModifierCodeSmsRenforce = {
      code: codeSecuriteRenforce,
    };
    return new Promise<void>((resolve, reject) => {
      this.loginService.smsRenforce(modif).subscribe({
        next: () => {
          this.logger.info('Changement de code de securité renforcé réussi');
          resolve();
        },
        error: (err) => {
          reject(err);
        }
      });
    });
  }

  /**
   * Reinitialisation date mot de passe
   *
   * @returns une promesse d'ANR
   */
  reinitDateMotPasse(): Promise<InfoAnr> {
    return new Promise<InfoAnr>((resolve, reject) => {
      this.loginService.reinitDateMotPasse().subscribe({
        next: (value: InfoAnr) => {
          resolve(value);
        },
        error: (err) => {
          reject(err);
        }
      });
    });
  }


  /**
   * Indique si les conditions generale d'utilisation ont ete valide
   *
   * @returns true si les cgu ont ete validée
   */
  isCguValide(): boolean {
    return this.user.isAccCgu();
  }

  /**
   * Accepte les conditions generale d'utilisation
   *
   * @returns une promise
   */
  accepterCgu(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.loginService.accepterCgu()
        .subscribe({
          next: () => {
            this.user.accCgu = true;
            this.stockeUser(this.user);
            resolve();
          },
          error: (err) => {
            reject(err);
          }
        });
    });
  }

  private stockeUser(user: User) {
    this.#user = user;
    if (environment.dev) {
      this.logger.debug('stocke l\'utilisateur dans la session');
      sessionStorage.setItem(this.key, JSON.stringify(user));
    }
  }


  /**
   * Initialise l'idle.
   *
   * @param dureeInactivite - la durée d'inactivité
   */
  private configureIdle(dureeInactivite: number) {
    const periodeGrace = 15;

    if (this.idleSet) {
      return;
    }
    this.idleSet = true;
    // le idle pour les déconnexion automatiques
    // au bout de 285 secondes d'inactivité...
    this.idle.setIdle(dureeInactivite * 60 - periodeGrace);
    // ... laisse 15 secondes à l'utilisateur avant de s'enclencher...
    this.idle.setTimeout(periodeGrace);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleEnCours = false;
    });

    this.idle.onTimeout.subscribe(() => {
      this.logger.info('Déconnexion automatique');
      this.logOut();
      this.idleEnCours = false;
    });

    this.idle.onTimeoutWarning.subscribe((countdown: number) => {
      if (!this.idleEnCours) {
        // TODO je n'utilise pas la période de grâce. Dans un monde parfait il faudrait afficher une popup à l'utilisateur...
        this.toastService.show('Inactivité détectée', `Vous allez être déconnecté dans ${countdown} secondes...`, periodeGrace * 1000);
        this.logger.debug(`Vous allez être déconnecté dans ${countdown} secondes...`);
      }
      this.idleEnCours = true;
    });
  }
}
