import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {Url} from '../constant/url';

/** interface de UserService */
export interface IMenuService {
  sidebarDisplay(s?: string): void;

  onNavbarClick($event: any): void;

  onSideBarClick(): void;

  onSideBarMenuClick(): void;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService implements IMenuService {

  #sidebarButton: boolean;
  #sidebarMenu: boolean;
  #sidebarContent: string;
  #idAccordionExpanded: string;
  #pageActive: string;

  constructor(
    private readonly router: Router,
    private readonly logger: NGXLogger,
  ) {
    this.#sidebarButton = false;
    this.#sidebarMenu = false;

    if (this.pageActive !== Url.PAGE_ACCUEIL_BASE) {
      this.sidebarDisplay();
    }
  }

  /**
   * Affichage du sidebar
   *
   * @param s - Contenu de la sidebar : ('relevés-et-activités' ,'services' ,'gestion-compte')
   */
  sidebarDisplay(s?: string) {
    if (s) {
      if (s === Url.RELEVE_ACTIVITES_BASE || s === Url.SERVICES_BASE || s === Url.GESTION_COMPTE_BASE) {
        if (!(this.#sidebarButton || this.#sidebarMenu)) {
          this.#sidebarButton = false;
          this.#sidebarMenu = true;
        }
        this.#sidebarContent = s;
      } else {
        this.#sidebarButton = false;
        this.#sidebarMenu = false;
      }
    } else {
      if (this.pageActive) {

        if (this.pageActive !== Url.PAGE_ACCUEIL) {
          this.sidebarDisplay(this.pageActive);
        }
      } else {
        this.logger.debug('sidebarDisplay: Il n\'y a pas de page active');
      }
    }
  }

  /**
   * @returns true si le sidebar menu est affiché
   */
  get sidebarMenu(): boolean {
    return this.#sidebarMenu;
  }

  /**
   * @returns true si le sidebar button est affiché
   */
  get sidebarButton(): boolean {
    return this.#sidebarButton;
  }

  /**
   * @returns le contenu de la sidebar
   */
  get sidebarContent(): string {
    return this.#sidebarContent;
  }

  /**
   * Récupère l'avant dernier element de l'url pour determiner si un accordéon du sidebar doit être ouvert
   */
  get idAccordionExpanded(): string {
    return this.#idAccordionExpanded;
  }

  /**
   * Referme l'accordéon du sidebar
   */
  unExpandeAccordion(){
    this.#idAccordionExpanded = '';
  }

  /**
   * Récupère dans l'url le nom de la page active
   */
  get pageActive(): string {
    return this.#pageActive;
  }

  /**
   * Action a réaliser lorsque qu'un onglet de la nav bar est cliqué
   */
  onNavbarClick($event: string) {
    if ($event === Url.PAGE_ACCUEIL_BASE) {
      this.#sidebarMenu = false;
      this.#sidebarButton = false;
      this.router.navigateByUrl('/' + Url.PAGE_ACCUEIL);
    } else {
      this.#sidebarButton = false;
      this.#sidebarMenu = true;
      this.sidebarDisplay($event);
    }
  }

  /**
   * Action au click sur la sidebar
   */
  onSideBarClick() {
    if (!this.router.url.startsWith('/' + Url.PAGE_ACCUEIL)) {
      this.#sidebarButton = true;
    }
    this.#sidebarMenu = false;
  }

  /**
   * Action au click sur le menu de la sideBar
   */
  onSideBarMenuClick() {
    this.#sidebarButton = false;
    this.#sidebarMenu = true;
  }

  /**
   * Navigation
   *
   * @param urlAfterRedirects - url de redirection
   */
  navigate(urlAfterRedirects: string) {
    // set page active
    const url = urlAfterRedirects.split('/');
    this.#pageActive = url[1];

    // set id_accordion
    url.pop();
    this.#idAccordionExpanded = url.pop();
      this.sidebarDisplay(this.pageActive);
  }
}
