/**
 * web services MooF adapté pour BLP2
 * Web Services REST **V1.0** pour le Home Banking de **OFINA** * 0.01: version initale (gestion du login) * 0.02: gestion de la création du login BLP * 0.03: gestion de l'ANR * 0.04: ajout du nom du client et du numéro 3ds dans le retour d'authentification * 0.05: liste des cartes * 0.06: détail carte * 0.07: détail point * 0.08: P3F liste des opérations * 0.09: P3F fractionnement * 0.10: gestion des versions * 0.11: infos sejour * 0.12: cartes supplémentaires * 0.13: coordonnées * 0.14: messagerie * 0.15: parametres * 0.16: oppositions * 0.17: déplafonnements * 0.18: activation carte * 0.19: notification push des messages * 0.20: catalogue Rewards * 0.21: transfert d'argent * 0.22: crédit * 0.23: ajout données NC * 2.00: nouveaux services pour BLP2
 *
 * OpenAPI spec version: 2.00
 * Contact: info@piti.pf
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../Conf/encoder';

import { Observable }                                        from 'rxjs/';

import { ParametreSimulation } from '../model/parametreSimulation';
import { ParametresEReleves } from '../model/parametresEReleves';
import { ParametresPush } from '../model/parametresPush';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../Conf/variables';
import { Configuration }                                     from '../Conf/configuration';


@Injectable({
  providedIn: 'root'
})
export class ParametreService {

    protected basePath = 'https://moof-api.piti.pf';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Récupére le paramètre d&#39;abonnement aux relevés électroniques du client
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getERelevesParametre(observe?: 'body', reportProgress?: boolean): Observable<ParametresEReleves>;
    public getERelevesParametre(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParametresEReleves>>;
    public getERelevesParametre(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParametresEReleves>>;
    public getERelevesParametre(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ParametresEReleves>(`${this.basePath}/parametres/e-releves`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Récupère les parametres pour la simulation de crédit
     *
     * @param idCarte ID de la carte
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getParametreSimulation(idCarte: string, observe?: 'body', reportProgress?: boolean): Observable<ParametreSimulation>;
    public getParametreSimulation(idCarte: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParametreSimulation>>;
    public getParametreSimulation(idCarte: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParametreSimulation>>;
    public getParametreSimulation(idCarte: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idCarte === null || idCarte === undefined) {
            throw new Error('Required parameter idCarte was null or undefined when calling getParametreSimulation.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ParametreSimulation>(`${this.basePath}/parametres/simulation/${encodeURIComponent(String(idCarte))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Récupère le parametre des notification PUSH associé au device
     *
     * @param deviceId device id
     * @param deviceType type de device
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPushParametre(deviceId: string, deviceType: 'android' | 'ios', observe?: 'body', reportProgress?: boolean): Observable<ParametresPush>;
    public getPushParametre(deviceId: string, deviceType: 'android' | 'ios', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParametresPush>>;
    public getPushParametre(deviceId: string, deviceType: 'android' | 'ios', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParametresPush>>;
    public getPushParametre(deviceId: string, deviceType: 'android' | 'ios', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getPushParametre.');
        }

        if (deviceType === null || deviceType === undefined) {
            throw new Error('Required parameter deviceType was null or undefined when calling getPushParametre.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('deviceId', <any>deviceId);
        }
        if (deviceType !== undefined && deviceType !== null) {
            queryParameters = queryParameters.set('deviceType', <any>deviceType);
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ParametresPush>(`${this.basePath}/parametres/notifications`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Met a jour le paramètre d&#39;abonnement aux relevés électroniques du client
     *
     * @param parametre
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setERelevesParametre(parametre: ParametresEReleves, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setERelevesParametre(parametre: ParametresEReleves, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setERelevesParametre(parametre: ParametresEReleves, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setERelevesParametre(parametre: ParametresEReleves, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parametre === null || parametre === undefined) {
            throw new Error('Required parameter parametre was null or undefined when calling setERelevesParametre.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/parametres/e-releves`,
            parametre,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Créer ou met à jour le paramètre des notifications PUSH
     *
     * @param parametre
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setPushParametre(parametre: ParametresPush, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setPushParametre(parametre: ParametresPush, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setPushParametre(parametre: ParametresPush, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setPushParametre(parametre: ParametresPush, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parametre === null || parametre === undefined) {
            throw new Error('Required parameter parametre was null or undefined when calling setPushParametre.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/parametres/notifications`,
            parametre,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
