<ul class="navbar-nav mr-auto sidenav" id="navAccordion3" >

  <li class="nav-item">
    <button   type="button" class="nav-link nav-link-collapse btn-none"
         (click)="onClick(url.COMPTE_CARTE_BASE, url.COMPTE_CARTE)" >
      Mon compte carte </button>
    <ul class="nav-second-level collapse" id="CompteCarte" data-parent="#navAccordion3" [ngClass]="loadIdAccordionExpanded()=== url.COMPTE_CARTE_BASE? 'show': ''">
      <li class="nav-item">
        <a class="nav-link" routerLink="{{'/' + url.DEPLAFONEMENT}}" routerLinkActive="active"> <span class="nav-link-text">Déplafonnement</span> </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="{{'/' + url.FREEZE_CARTE}}" routerLinkActive="active"> <span class="nav-link-text">Freeze de la carte</span> </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="{{'/' + url.MISE_OPPOSITION}}" routerLinkActive="active"> <span class="nav-link-text">Mise en opposition</span> </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="{{'/' + url.DATE_PRELEVEMENT}}" routerLinkActive="active"> <span class="nav-link-text">Date de prélèvement</span> </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="{{'/' + url.ALERTE_COMPTE_CARTE}}" routerLinkActive="active"> <span class="nav-link-text">Alertes compte-carte</span> </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="{{'/' + url.INSCRIPTION_E_RELEVE}}" routerLinkActive="active"> <span class="nav-link-text">Inscription au e-relevé</span> </a>
      </li>
    </ul>
  </li>

  <li class="nav-item">
    <a class="nav-link" routerLink="{{'/' + url.MES_INFORMATIONS}}" routerLinkActive="active">Mes informations</a>
  </li>

  <li class="nav-item">
    <a class="nav-link" routerLink="{{'/' + url.MESSAGERIE}}" routerLinkActive="active">Messagerie</a>
  </li>

  <li class="nav-item">
    <button type="button" class="nav-link nav-link-collapse btn-none"
     (click)="onClick(url.PARAMETRES_BASE, url.PARAMETRES)">
      Paramètres</button>
    <ul class="nav-second-level collapse" id="param" data-parent="#navAccordion3" [ngClass]="loadIdAccordionExpanded()=== url.PARAMETRES_BASE? 'show': ''">
      <li class="nav-item">
        <a class="nav-link" routerLink="{{'/' + url.MOT_PASSE}}" routerLinkActive="active"> <span class="nav-link-text">Mot de passe</span> </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="{{'/' + url.GESTION_COOKIES}}" routerLinkActive="active"> <span class="nav-link-text">Gestion des cookies</span> </a>
      </li>
    </ul>
  </li>

</ul>
