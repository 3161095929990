import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UserService} from '../../services/user.service';
import {CarteService} from '../../services/carte.service';
import {User} from '../../models/user';
import {MenuService} from '../../services/menu.service';
import {SituationCarte} from '../../models/situation-carte';
import {Url} from '../../constant/url';
import {Router} from '@angular/router';


@Component({
  selector: 'app-header-card',
  templateUrl: './header-card-page.component.html',
  styleUrls: ['./header-card-page.component.css']
})
export class HeaderCardPageComponent implements OnInit {
  @Output() navigation: EventEmitter<any> = new EventEmitter();
  /** Nombre de carte */
  nbCarte: number;
  /** Carte sélectionnée */
  carte: SituationCarte;
  /** Nom de l'image de la carte sélectionnée */
  carteImageName: string;
  /** Utilisateur courant */
  user: User;
  /** Utilisateur courant membre depuis */
  memberSince: string;
  /** Etat de la barre de navigation (true: open) */
  navBarOpen = false;
  url = Url;


  constructor(
    private readonly carteService: CarteService,
    private readonly userService: UserService,
    private readonly menuService: MenuService,
    private readonly router: Router,
  ) {
  }

  ngOnInit(): void {
    const pCarte = this.carteService.portefeuilleCarte;
    this.carte = this.carteService.selectedCard;
    this.nbCarte = pCarte?.getNbCarte();
    this.user = this.userService.user;
    this.memberSince = ((+this.user?.memberSince > 50) ? '19' : '20') + this.user?.memberSince;
  }

  /**
   * Déconnecte l'utilisateur
   */
  logOut(): void {
    this.userService.logOut();
  }

  /**
   * Change l'état de la barre de navigation
   */
  toogleNavBar(): void {
    this.navBarOpen = !this.navBarOpen;
  }

  /**
   * Emmet un évènement au click sur la bare de navigation
   *
   * @param s - nom de l'onglet de navigation cliqué
   */
  onNavbarClick(s: string) {
    this.navigation.emit(s);
  }

  /**
   * @returns le menu de la page active
   */
  menuPageActive(): string {
    return this.menuService.pageActive;
  }

  /**
   * Action au click sur la navigation relevés
   */
  onRelevesClick(): void {
    this.router.navigateByUrl('/' + Url.RELEVE_ACTIVITES).then(() => {
      this.onNavbarClick(Url.RELEVE_ACTIVITES_BASE);
    });
  }

  /**
   * Action au click sur la navigation du sms renforcé
   */
  onSmsRenforceClick(): void {
    this.router.navigateByUrl('/' + Url.SMS_RENFORCE).then(() => {
      this.onNavbarClick(Url.SMS_RENFORCE_BASE);
    });
  }
}
