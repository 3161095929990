export class MoofConstante {
  /* eslint-disable @typescript-eslint/naming-convention */
  //cle Texte
  public static TEXTE_CGU_ANG = 'CGU_ANG';
  public static TEXTE_CGU_RE = 'CGU_RE';
  public static TEXTE_CGU_ALERTE = 'CGU_ALERTE';
  public static TEXTE_CA_IPAY = 'CA_IPAY';
  public static TEXTE_FP_ANG_CONDITIONS = 'FP_ANG_Conditions';
  public static TEXTE_FP_ANG_COMMENT = 'FP_ANG_Comment';
  public static TEXTE_CR_ANG_COMMENT = 'CR_ANG_Comment';
  public static TEXTE_CR_ANG_CONDITIONS = 'CR_ANG_Conditions';
  // cle Paramètres
  public static URL_SOUSCRIRE = 'url.souscrire';
  public static URL_FLIGHT = 'url.flight';
  public static URL_DAB = 'url.dab';
  public static PARRAINAGE_VOUS = 'parrainage.pour.vous';
  public static PARRAINAGE_PROCHES = 'parrainage.vos.proches';
  public static DATE_PRELEVEMENT = 'jours.prelevement';
  public static URL_FIDEL_ATN = 'url.fidelite.atn';
  public static URL_FIDEL_QANTAS = 'url.fidelite.qantas';
  public static URL_PARRAINAGE = 'url.parrainage';
  public static TRANSFERT_MAX = 'transfert.max';
  public static TRANSFERT_MIN = 'transfert.min';
  public static URL_REWARDS = 'url.rewards';
  public static JOUR_P3F_PIVOT = 'jour.p3f.pivot';

  public static INACTIVITE_DUREE = 'inactivite.duree';
  public static JOUR_PRELEVEMENT = 'jours.prelevement';
  public static PARRAINAGE_POUR_VOUS_NC = 'parrainage.pour.vous.NC';
  public static PARRAINAGE_POUR_VOUS_PF = 'parrainage.pour.vous.PF';
  public static PARAINAGE_POUR_VOS_PROCHE_NC = 'parrainage.vos.proches.NC';
  public static PARRAINAGE_POUR_VOS_PROCHE_PF = 'parrainage.vos.proches.PF';
  public static TOKEN_DUREE_VIE = 'token.duree.vie';
  public static TRANSFERT_MAX_NC = 'transfert.max.NC';
  public static TRANSFERT_MAX_PF = 'transfert.max.PF';
  public static TRANSFERT_MIN_NC = 'transfert.min.NC';
  public static TRANSFERT_MIN_PF = 'transfert.min.PF';
  public static URL_FIDELITE_ATN = 'url.fidelite.atn';
  public static URL_FIDELITE_QANTAS = 'url.fidelite.qantas';
  public static URL_FIDELITE_REWARDS_NC = 'url.fidelite.rewards.nc';
  public static URL_FIDELITE_REWARDS_PF = 'url.fidelite.rewards.pf';
  public static URL_PARRAINAGE_NC = 'url.parrainage.NC';
  public static URL_PARRAINAGE_PF = 'url.parrainage.PF';
  public static URL_PF_ASSUR_ATN = 'url.pdf.assur.atn';
  public static URL_PF_ASSUR_CENT_NC = 'url.pdf.assur.cent.NC';
  public static URL_PF_ASSUR_CENT_PF = 'url.pdf.assur.cent.PF';
  public static URL_PF_ASSUR_QANTAS = 'url.pdf.assur.qantas';
  public static URL_PF_ASSUR_AIR_CALIN = 'url.pdf.assur.air.calin';
  public static URL_REWARDS_NC = 'url.rewards.NC';
  public static URL_REWARDS_PF = 'url.rewards.PF';
  public static URL_SOUSCRIRE_NC = 'url.souscrire.NC';
  public static URL_SOUSCRIRE_PF = 'url.souscrire.PF';
  public static PIECE_JOINTE_NB_MAX = 'piece.jointe.nombre.maximum';
  public static PIECE_JOINTE_POIDS_MAX = 'piece.jointe.poids.maximum';
  public static PIECE_JOINTE_POIDS_TOTAL_MAX = 'piece.jointe.poids.total.maximum';

  // Data message
  public static MESSAGE_ERR_APPLICATION = 'ERR_APPLICATION';
  public static MESSAGE_ERR_3DS = 'ERR_3DS';
  public static MESSAGE_ERR_3DS_MAIL = 'ERR_3DS_MAIL';
  public static MESSAGE_WS_ERR_SERVEUR = 'WS_ERR_SERVEUR';
  public static MESSAGE_WS_ID_RETRY = 'WS_ID_RETRY';
  public static MESSAGE_ERR_COMPTE_VERROUILLE = 'ERR_COMPTE_VERROUILLE';
  public static MESSAGE_ERR_AUTHENTIFICATION = 'ERR_AUTHENTIFICATION';
  public static MESSAGE_ERR_IDENTIFICATION = 'ERR_IDENTIFICATION';
  public static MESSAGE_ERR_ANR_FAUX = 'ERR_ANR_FAUX';
  public static MESSAGE_ERR_ANR_KEYPASS = 'ERR_ANR_KEYPASS';
  public static MESSAGE_ERR_ANR_EXPIRE = 'ERR_ANR_EXPIRE';
  public static MESSAGE_ERR_CLIENT = 'ERR_CLIENT';
  public static MESSAGE_ERR_TOKEN_EXPIRE = 'ERR_TOKEN_EXPIRE';
  public static MESSAGE_ERR_INTERDIT = 'ERR_INTERDIT';
  public static MESSAGE_ERR_CARTE_ABSENTE = 'ERR_CARTE_ABSENTE';
  public static MESSAGE_ERR_PERIODES_ABSENTE = 'ERR_PERIODES_ABSENTE';
  public static MESSAGE_ERR_PERIODE_ABSENTE = 'ERR_PERIODE_ABSENTE';
  public static MESSAGE_ERR_CPT_POINT_ABSENT = 'ERR_CPT_POINT_ABSENT';
  public static MESSAGE_ERR_FORM_AUTH = 'ERR_FORM_AUTH';
  public static MESSAGE_ERR_FORM_IDS1 = 'ERR_FORM_IDS1';
  public static MESSAGE_ERR_FORM_IDS2 = 'ERR_FORM_IDS2';
  public static MESSAGE_ERR_FORM_IDS3_1 = 'ERR_FORM_IDS3_1';
  public static MESSAGE_ERR_FORM_IDS3_2 = 'ERR_FORM_IDS3_2';
  public static MESSAGE_ERR_P3F_GEL = 'ERR_P3F_GEL';
  public static MESSAGE_ERR_P3F_INELIGIBLE = 'ERR_P3F_INELIGIBLE';
  public static MESSAGE_ERR_P3F_IMPAYE = 'ERR_P3F_IMPAYE';
  public static MESSAGE_ERR_P3F_VIDE = 'ERR_P3F_VIDE';
  public static MESSAGE_ERR_P3F_ACCEPTER_CGV = 'ERR_P3F_ACCEPTER_CGV';
  public static MESSAGE_ERR_P3F_SEUIL = 'ERR_P3F_SEUIL';
  public static MESSAGE_ERR_P3F_DEJA_FRACTIONNE = 'ERR_P3F_DEJA_FRACTIONNE';
  public static MESSAGE_ERR_COUVERTURE_GEL = 'ERR_COUVERTURE_GEL';
  public static MESSAGE_ERR_COUVERTURE_INELIGIBLE = 'ERR_COUVERTURE_INELIGIBLE';
  public static MESSAGE_ERR_COUVERTURE_DEJA_COUVERT = 'ERR_COUVERTURE_DEJA_COUVERT';
  public static MESSAGE_ERR_APP_OBSOLETE = 'ERR_APP_OBSOLETE';
  public static MESSAGE_ERR_APP_PERIMEE = 'ERR_APP_PERIMEE';
  public static MESSAGE_ERR_FORM_INFOS_SEJOUR = 'ERR_FORM_INFOS_SEJOUR';
  public static MESSAGE_ERR_FORM_INFOS_SEJOUR2 = 'ERR_FORM_INFOS_SEJOUR2';
  public static MESSAGE_ERR_FORM_CARTE_SUP = 'ERR_FORM_CARTE_SUP';
  public static MESSAGE_ERR_FORM_COORDONNEES = 'ERR_FORM_COORDONNEES';
  public static MESSAGE_ERR_FORM_COORDONNEES2 = 'ERR_FORM_COORDONNEES2';
  public static MESSAGE_ERR_FORM_COORDONNEES3 = 'ERR_FORM_COORDONNEES3';
  public static MESSAGE_ERR_FORM_COORDONNEES4 = 'ERR_FORM_COORDONNEES4';
  public static MESSAGE_ERR_FORM_COORDONNEES5 = 'ERR_FORM_COORDONNEES5';
  public static MESSAGE_ERR_FORM_COORDONNEES6 = 'ERR_FORM_COORDONNEES6';
  public static MESSAGE_ERR_FORM_COORDONNEES7 = 'ERR_FORM_COORDONNEES7';
  public static MESSAGE_ERR_FORM_COORDONNEES8 = 'ERR_FORM_COORDONNEES8';
  public static MESSAGE_ERR_FORM_MESSAGE = 'ERR_FORM_MESSAGE';
  public static MESSAGE_ERR_FORM_VALEUR_INCHANGE = 'ERR_FORM_VALEUR_INCHANGE';
  public static MESSAGE_ERR_OPPO_RETRY = 'ERR_OPPO_RETRY';
  public static MESSAGE_ERR_OPPO_FATALE = 'ERR_OPPO_FATALE';
  public static MESSAGE_ERR_OPPO_ANR = 'ERR_OPPO_ANR';
  public static MESSAGE_ERR_DEPLAFONNEMENT_NON_ELIGIBLE = 'ERR_DEPLAFONNEMENT_NON_ELIGIBLE';
  public static MESSAGE_ERR_DEPLAFONNEMENT_RETRY = 'ERR_DEPLAFONNEMENT_RETRY';
  public static MESSAGE_ERR_DEPLAFONNEMENT_FATALE = 'ERR_DEPLAFONNEMENT_FATALE';
  public static MESSAGE_ERR_FORM_ACTIVATION = 'ERR_FORM_ACTIVATION';
  public static MESSAGE_ERR_ACTIVATION_NOT_FOUND = 'ERR_ACTIVATION_NOT_FOUND';
  public static MESSAGE_ERR_ACTIVATION_DEJA = 'ERR_ACTIVATION_DEJA';
  public static MESSAGE_ERR_ACTIVATION_BLOQUEE = 'ERR_ACTIVATION_BLOQUEE';
  public static MESSAGE_ERR_DEVICE_ID = 'ERR_DEVICE_ID';
  public static MESSAGE_ERR_E_RELEVES = 'ERR_E_RELEVES';
  public static MESSAGE_ERR_E_RELEVES_ACCEPTER_CGV = 'ERR_E_RELEVES_ACCEPTER_CGV';
  public static MESSAGE_ERR_MENU_AUTH = 'ERR_MENU_AUTH';
  public static MESSAGE_ERR_ACCES_APPAREIL_PHOTO = 'ERR_ACCES_APPAREIL_PHOTO';
  public static MESSAGE_ERR_ACCES_PHOTOTHEQUE = 'ERR_ACCES_PHOTOTHEQUE';
  public static MESSAGE_ERR_CARTES_CENTURION = 'ERR_CARTES_CENTURION';
  public static MESSAGE_ERR_PRIME = 'ERR_PRIME';
  public static MESSAGE_ERR_PARTENAIRE = 'ERR_PARTENAIRE';
  public static MESSAGE_ERR_REWARDS_IMPAYE = 'ERR_REWARDS_IMPAYE';
  public static MESSAGE_ERR_REWARDS_TOTAL = 'ERR_REWARDS_TOTAL';
  public static MESSAGE_ERR_REWARDS_TITULAIRE = 'ERR_REWARDS_TITULAIRE';
  public static MESSAGE_ERR_FORM_TRANSFERT = 'ERR_FORM_TRANSFERT';
  public static MESSAGE_ERR_TRANSFERT_MONTANT_MIN = 'ERR_TRANSFERT_MONTANT_MIN';
  public static MESSAGE_ERR_TRANSFERT_MONTANT_MAX = 'ERR_TRANSFERT_MONTANT_MAX';
  public static MESSAGE_ERR_COUVERTURE_MONTANT_MIN = 'ERR_COUVERTURE_MONTANT_MIN';
  public static MESSAGE_ERR_COUVERTURE_MONTANT_MAX = 'ERR_COUVERTURE_MONTANT_MAX';
  public static MESSAGE_ERR_FORM_TRANSFERT_CGV = 'ERR_FORM_TRANSFERT_CGV';
  public static MESSAGE_ERR_TRANSFERT_NON_TROUVE = 'ERR_TRANSFERT_NON_TROUVE';
  public static MESSAGE_ERR_TRANSFERT_TROP_TROUVE = 'ERR_TRANSFERT_TROP_TROUVE';
  public static MESSAGE_ERR_TRANSFERT_LIMITE = 'ERR_TRANSFERT_LIMITE';
  public static MESSAGE_ERR_TRANSFERT_IMPAYE = 'ERR_TRANSFERT_IMPAYE';
  public static MESSAGE_ERR_TRANSFERT_INACTIF = 'ERR_TRANSFERT_INACTIF';
  public static MESSAGE_ERR_CREDIT_TELEPHONE = 'ERR_CREDIT_TELEPHONE';
  public static MESSAGE_ERR_CREDIT_DISPONIBLE = 'ERR_CREDIT_DISPONIBLE';
  public static MESSAGE_AIDE_NUM_CLIENT = 'AIDE_NUM_CLIENT';
  public static MESSAGE_AIDE_NUM_CARTE = 'AIDE_NUM_CARTE';
  public static MESSAGE_AIDE_DATE_VALIDITE = 'AIDE_DATE_VALIDITE';
  public static MESSAGE_AIDE_GSM_ABSENT = 'AIDE_GSM_ABSENT';
  public static MESSAGE_AIDE_MAIL_ABSENT = 'AIDE_MAIL_ABSENT';
  public static MESSAGE_AIDE_PASSWORD = 'AIDE_PASSWORD';
  public static MESSAGE_AIDE_REVENUS = 'AIDE_REVENUS';
  public static MESSAGE_AIDE_ECHEANCE_DU = 'AIDE_ECHEANCE_DU';
  public static MESSAGE_AIDE_ALERTE_TRANSACTION = 'AIDE_ALERTE_TRANSACTION';
  public static MESSAGE_AIDE_ENCOURS = 'AIDE_ENCOURS';
  public static MESSAGE_AIDE_CIVILITE = 'AIDE_CIVILITE';
  public static MESSAGE_VAL_P3F_FRACT = 'VAL_P3F_FRACT';
  public static MESSAGE_VAL_FORM_INFOS_SEJOUR = 'VAL_FORM_INFOS_SEJOUR';
  public static MESSAGE_VAL_FORM_CARTE_SUP = 'VAL_FORM_CARTE_SUP';
  public static MESSAGE_VAL_FORM_COORDONNEES = 'VAL_FORM_COORDONNEES';
  public static MESSAGE_VAL_FORM_MESSAGE = 'VAL_FORM_MESSAGE';
  public static MESSAGE_VAL_FORM_OPPOSITION = 'VAL_FORM_OPPOSITION';
  public static MESSAGE_VAL_FORM_DEPLAFONNEMENT = 'VAL_FORM_DEPLAFONNEMENT';
  public static MESSAGE_VAL_FORM_E_RELEVES = 'VAL_FORM_E_RELEVES';
  public static MESSAGE_VAL_FORM_DATE_PRELEVEMENT = 'VAL_FORM_DATE_PRELEVEMENT';
  public static MESSAGE_VAL_FORM_PARAMS_PUSH = 'VAL_FORM_PARAMS_PUSH';
  public static MESSAGE_VAL_FORM_PARAMS_ALERTE = 'VAL_FORM_PARAMS_ALERTE';
  public static MESSAGE_VAL_REWARDS_COMMANDE = 'VAL_REWARDS_COMMANDE';
  public static MESSAGE_VAL_FORM_TRANSFERT = 'VAL_FORM_TRANSFERT';
  public static MESSAGE_VAL_FORM_COUVERTURE = 'VAL_FORM_COUVERTURE';
  public static MESSAGE_VAL_CREDIT = 'VAL_CREDIT';
  public static MESSAGE_VAL_CREDIT_RETRACTATION = 'VAL_CREDIT_RETRACTATION';
  public static MESSAGE_CONF_IDENTIFICATION = 'CONF_IDENTIFICATION';
  public static MESSAGE_CONF_P3F_FRACT = 'CONF_P3F_FRACT';
  public static MESSAGE_CONF_FORM = 'CONF_FORM';
  public static MESSAGE_CONF_FORM_JUSTIFS = 'CONF_FORM_JUSTIFS';
  public static MESSAGE_CONF_MESSAGE = 'CONF_MESSAGE';
  public static MESSAGE_CONF_OPPOSITION = 'CONF_OPPOSITION';
  public static MESSAGE_CONF_OPPO_CHOICE_OTP = 'CONF_OPPO_CHOICE_OTP';
  public static MESSAGE_CONF_DEPLAFONNEMENT_IMMEDIAT = 'CONF_DEPLAFONNEMENT_IMMEDIAT';
  public static MESSAGE_CONF_DEPLAFONNEMENT_DIFFERE = 'CONF_DEPLAFONNEMENT_DIFFERE';
  public static MESSAGE_CONF_DATE_PRELEVEMENT_DIFFERE = 'CONF_DATE_PRELEVEMENT_DIFFERE';
  public static MESSAGE_CONF_ACTIVATION_CARTE = 'CONF_ACTIVATION_CARTE';
  public static MESSAGE_CONF_PARMS_PUSH = 'CONF_PARMS_PUSH';
  public static MESSAGE_CONF_PARMS_ALERTE = 'CONF_PARMS_ALERTE';
  public static MESSAGE_CONF_REWARDS_PRIME = 'CONF_REWARDS_PRIME';
  public static MESSAGE_CONF_REWARDS_PRIME_SUPPLEMENT = 'CONF_REWARDS_PRIME_SUPPLEMENT';
  public static MESSAGE_CONF_TRANSFERT_OK = 'CONF_TRANSFERT_OK';
  public static MESSAGE_CONF_COUVERTURE_OK = 'CONF_COUVERTURE_OK';
  public static MESSAGE_CONF_CREDIT = 'CONF_CREDIT';
  public static MESSAGE_CONF_CREDIT_RETRACTATION = 'CONF_CREDIT_RETRACTATION';
  public static MESSAGE_CONF_COORDONEES_DIFFERE = 'CONF_COORDONEES_DIFFERE';
  public static MESSAGE_CONF_COORDONEES_IMMEDIAT = 'CONF_COORDONEES_IMMEDIAT';
  public static MESSAGE_INF_PSWD_OUBLIE = 'INF_PSWD_OUBLIE';
  public static MESSAGE_INF_CREDIT = 'INF_CREDIT';
  public static MESSAGE_BIO_INDISPONIBLE = 'BIO_INDISPONIBLE';
  public static MESSAGE_BIO_DESACTIVEE = 'BIO_DESACTIVEE';
  public static MESSAGE_BIO_VIDE = 'BIO_VIDE';
  public static MESSAGE_BIO_ECHOUEE = 'BIO_ECHOUEE';
  public static MESSAGE_BIO_SAVE = 'BIO_SAVE';
  public static MESSAGE_BIO_PRM_NONMODIFIE = 'BIO_PRM_NONMODIFIE';
  public static MESSAGE_VAL_FORM_PARAMS_BIO = 'VAL_FORM_PARAMS_BIO';
  public static MESSAGE_CONF_PARMS_BIO_OFF = 'CONF_PARMS_BIO_OFF';
  public static MESSAGE_CONF_PARMS_BIO_ON = 'CONF_PARMS_BIO_ON';

  public static MESSAGE_BLP2_NO_NEW_MVMT = 'BLP2_NO_NEW_MVMT';
  public static MESSAGE_ERR_CNF_INELIGIBLE = 'ERR_CNF_INELIGIBLE';
  public static MESSAGE_ERR_MENU_OPTION = 'ERR_MENU_OPTION';
  public static MESSAGE_INF_CREDIT_MEN1 = 'INF_CREDIT_MEN1';
  public static MESSAGE_INF_CREDIT_MEN2 = 'INF_CREDIT_MEN2';
  public static MESSAGE_INF_CREDIT_MEN3 = 'INF_CREDIT_MEN3';
  public static MESSAGE_ERR_FREEZE_RETRY = 'ERR_FREEZE_RETRY';
  public static MESSAGE_ERR_FREEZE_FATALE = 'ERR_FREEZE_FATALE';
  public static MESSAGE_VAL_FORM_FREEZE = 'VAL_FORM_FREEZE';
  public static MESSAGE_CONF_FORM_FREEZE = 'CONF_FORM_FREEZE';

  public static MESSAGE_ERR_CARTE_FREEZE = 'ERR_CARTE_FREEZE';

  public static MESSAGE_ERR_ACTIVATION_RETRY = 'ERR_ACTIVATION_RETRY';
  public static MESSAGE_ERR_ACTIVATION_FATALE = 'ERR_ACTIVATION_FATALE';
  public static MESSAGE_ERR_TIMEOUT = 'ERR_TIMEOUT';
  public static messageInexistant = 'Erreur application';

  public static MESSAGE_VAL_FORM_MOT_PASSE = 'VAL_FORM_MOT_PASSE';
  public static MESSAGE_CONF_FORM_MOT_PASSE = 'CONF_FORM_MOT_PASSE';
  public static MESSAGE_ERR_FORM_MOT_PASSE = 'ERR_FORM_MOT_PASSE';
  public static MESSAGE_ERR_FORM_MOT_PASSE2 = 'ERR_FORM_MOT_PASSE2';
  public static MESSAGE_ERR_FORM_MOT_PASSE3 = 'ERR_FORM_MOT_PASSE3';
  public static MESSAGE_ERR_FORM_MOT_PASSE4 = 'ERR_FORM_MOT_PASSE4';
  public static MESSAGE_ERR_CARTE_OPPOSE = 'ERR_CARTE_OPPOSE';
  public static MESSAGE_ERR_CARTE_BLOQUE = 'ERR_CARTE_BLOQUE';
  public static MESSAGE_CARTE_SUPP_COND = 'CARTE_SUPP_COND';

  public static MESSAGE_ERR_FORM_PARAM_ALERTE = 'ERR_FORM_PARAM_ALERTE';
  public static MESSAGE_ERR_FORM_PARAM_ALERTE2 = 'ERR_FORM_PARAM_ALERTE2';
  public static MESSAGE_ERR_FORM_PARAM_ALERTE_CGV = 'ERR_FORM_PARAM_ALERTE_CGV';
  public static MESSAGE_ERR_CGU = 'ERR_CGU';
  public static MESSAGE_ERR_CGU2 = 'ERR_CGU2';

  public static URL_PROTECTION_DONNES_PF = 'https://www.americanexpress.pf/protection-des-donnees/';
  public static URL_PROTECTION_DONNES_NC = 'https://www.americanexpress.nc/protection-des-donnees/';


}








