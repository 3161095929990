/***
 * Classe stockant les information de l'utilisateur connecté.
 */
import {RetourAuthentification} from '../../api/model/retourAuthentification';

export class User {

  /** Token fournis par le WebService à l'utilisateur lors de son authentification */
  readonly token: string;
  /** civilité du client */
  civiliteClient: string;
  /** nom du client */
  nomClient: string;
  /** prénom du client */
  prenomClient: string;
  /** numéro de téléphone GSM connu pour le client */
  numeroGsm?: string;
  /** nombre de message non lu */
  nbMessageNonLu: number;
  /** true si le client est actuellement en impayé */
  impaye: boolean;
  /** code pays */
  codePays: string;
  /** nombre de carte */
  nbCarte: number;
  /** numéro de client */
  numCli: string;
  /** Validation cgu */
  accCgu: boolean;
  /** date d'inscription du client */
  memberSince: string;
  /**
   * true si le client a activé keypass
   */
  keypass: boolean;
  /**
   * true si le client a activé le sms renforcé
   */
  smsRenforce: boolean;
  /**
   * true si le client doit changer son mot de passe
   */
  changePassword: boolean;


  constructor(numCli: string, retourAuth?: RetourAuthentification) {
    this.numCli = numCli;
    this.token = retourAuth.token;
    this.civiliteClient = retourAuth.civiliteClient;
    this.nomClient = retourAuth.nomClient;
    this.prenomClient = retourAuth.prenomClient;
    this.nbMessageNonLu = retourAuth.nbMessageNonLu;
    this.impaye = retourAuth.impaye;
    this.codePays = retourAuth.codePays;
    this.accCgu = retourAuth.accCgu;
    this.memberSince = retourAuth.memberSince;
    this.keypass = retourAuth.keypass;
    this.smsRenforce = retourAuth.smsRenforce;
    this.changePassword = retourAuth.changePassword;
  }

  /**
   * @returns le token
   */
  getToken(): string {
    return this.token;
  }

  /**
   * Indique le nombre carte
   *
   * @param nb - le nombre de carte
   */
  setNbcarte(nb: number) {
    this.nbCarte = nb;
  }

  /**
   * @returns le nombre de carte
   */
  getNbCarte(): number {
    return this.nbCarte;
  }

  /**
   * @returns true si le code pays du client est NC
   */
  isNC(): boolean {
    return this.codePays.toUpperCase() === 'NC';
  }

  /**
   * @returns true si le code pays du client est PF
   */
  isPF(): boolean {
    return this.codePays.toUpperCase() === 'PF';
  }

  /**
   * @returns true si le client a validé les cgu
   */
  isAccCgu(): boolean {
    return this.accCgu;
  }
}
