/**
 * web services MooF
 * Web Services REST **V1.0** pour le Home Banking de **OFINA** * 0.01: version initale (gestion du login) * 0.02: gestion de la création du login BLP * 0.03: gestion de l'ANR * 0.04: ajout du nom du client et du numéro 3ds dans le retour d'authentification * 0.05: liste des cartes * 0.06: détail carte * 0.07: détail point * 0.08: P3F liste des opérations * 0.09: P3F fractionnement * 0.10: gestion des versions * 0.11: infos sejour * 0.12: cartes supplémentaires * 0.13: coordonnées * 0.14: messagerie * 0.15: parametres * 0.16: oppositions * 0.17: déplafonnements * 0.18: activation carte * 0.19: notification push des messages * 0.20: catalogue Rewards * 0.21: transfert d'argent * 0.22: crédit * 0.23: ajout données NC
 *
 * OpenAPI spec version: 0.23
 * Contact: info@piti.pf
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../Conf/encoder';

import { Observable }                                        from 'rxjs';

import { Credit } from '../model/credit';
import { DemandeCredit } from '../model/demandeCredit';
import { SimulationCredit } from '../model/simulationCredit';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../Conf/variables';
import { Configuration }                                     from '../Conf/configuration';


@Injectable({
  providedIn: 'root'
})
export class CreditService {

    protected basePath = 'https://be.piti.pf/MOOF/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Callback pour le changement d&#39;état du dossier de crédit
     *
     * @param packageId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public callback(packageId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public callback(packageId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public callback(packageId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public callback(packageId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (packageId === null || packageId === undefined) {
            throw new Error('Required parameter packageId was null or undefined when calling callback.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (packageId !== undefined && packageId !== null) {
            queryParameters = queryParameters.set('PackageId', <any>packageId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/credits/callback`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Téléchargement du contrat complet d&#39;un crédit
     *
     * @param id id du crédit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contratCredit(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public contratCredit(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public contratCredit(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public contratCredit(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling contratCredit.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/credits/${encodeURIComponent(String(id))}/contrat`,
            {
                responseType: 'blob',
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Demande d&#39;un crédit
     *
     * @param idCarte ID de la carte
     * @param demandeCredit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public demandeCredit(idCarte: string, demandeCredit: DemandeCredit, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public demandeCredit(idCarte: string, demandeCredit: DemandeCredit, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public demandeCredit(idCarte: string, demandeCredit: DemandeCredit, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public demandeCredit(idCarte: string, demandeCredit: DemandeCredit, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idCarte === null || idCarte === undefined) {
            throw new Error('Required parameter idCarte was null or undefined when calling demandeCredit.');
        }

        if (demandeCredit === null || demandeCredit === undefined) {
            throw new Error('Required parameter demandeCredit was null or undefined when calling demandeCredit.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.basePath}/credits/demande/${encodeURIComponent(String(idCarte))}`,
            demandeCredit,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                responseType: 'text',
                reportProgress
            }
        );
    }

    /**
     * Liste des crédits actifs, en attente et signés
     *
     * @param idCarte ID de la carte
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listeCredit(idCarte: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Credit>>;
    public listeCredit(idCarte: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Credit>>>;
    public listeCredit(idCarte: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Credit>>>;
    public listeCredit(idCarte: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idCarte === null || idCarte === undefined) {
            throw new Error('Required parameter idCarte was null or undefined when calling listeCredit.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Credit>>(`${this.basePath}/credits/${encodeURIComponent(String(idCarte))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Retractation sur une demande de crédit
     *
     * @param id id du crédit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retractationCredit(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public retractationCredit(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public retractationCredit(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public retractationCredit(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling retractationCredit.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/credits/${encodeURIComponent(String(id))}/retractation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Récupère l&#39;impression de la simulation
     *
     * @param simulation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public simulationCreditPdf(simulation: SimulationCredit, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public simulationCreditPdf(simulation: SimulationCredit, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public simulationCreditPdf(simulation: SimulationCredit, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public simulationCreditPdf(simulation: SimulationCredit, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simulation === null || simulation === undefined) {
            throw new Error('Required parameter simulation was null or undefined when calling simulationCreditPdf.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.basePath}/credits/simulation`,
            simulation,
            {
                responseType: 'blob',
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
