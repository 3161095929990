/**
 * web services MooF
 * Web Services REST **V1.0** pour le Home Banking de **OFINA** * 0.01: version initale (gestion du login) * 0.02: gestion de la création du login BLP * 0.03: gestion de l'ANR * 0.04: ajout du nom du client et du numéro 3ds dans le retour d'authentification * 0.05: liste des cartes * 0.06: détail carte * 0.07: détail point * 0.08: P3F liste des opérations * 0.09: P3F fractionnement * 0.10: gestion des versions * 0.11: infos sejour * 0.12: cartes supplémentaires * 0.13: coordonnées * 0.14: messagerie * 0.15: parametres * 0.16: oppositions * 0.17: déplafonnements * 0.18: activation carte * 0.19: notification push des messages * 0.20: catalogue Rewards * 0.21: transfert d'argent * 0.22: crédit * 0.23: ajout données NC
 *
 * OpenAPI spec version: 0.23
 * Contact: info@piti.pf
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../Conf/encoder';

import { Observable }                                        from 'rxjs';

import { PeriodePoints } from '../model/periodePoints';
import { PeriodesSituation } from '../model/periodesSituation';
import { RecherchePeriode } from '../model/recherchePeriode';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../Conf/variables';
import { Configuration }                                     from '../Conf/configuration';


@Injectable({
  providedIn: 'root'
})
export class PointService {

    protected basePath = 'https://be.piti.pf/MOOF/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Consultation des points d&#39;une carte
     *
     * @param id id de la carte
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detailPoint(id: string, observe?: 'body', reportProgress?: boolean): Observable<PeriodesSituation>;
    public detailPoint(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PeriodesSituation>>;
    public detailPoint(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PeriodesSituation>>;
    public detailPoint(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling detailPoint.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PeriodesSituation>(`${this.basePath}/points/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Consultation des points d&#39;une carte sur une période
     *
     * @param id id de la carte
     * @param recherche la période que l&#39;on recherche
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public periodePoint(id: string, recherche: RecherchePeriode, observe?: 'body', reportProgress?: boolean): Observable<PeriodePoints>;
    public periodePoint(id: string, recherche: RecherchePeriode, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PeriodePoints>>;
    public periodePoint(id: string, recherche: RecherchePeriode, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PeriodePoints>>;
    public periodePoint(id: string, recherche: RecherchePeriode, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling periodePoint.');
        }

        if (recherche === null || recherche === undefined) {
            throw new Error('Required parameter recherche was null or undefined when calling periodePoint.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PeriodePoints>(`${this.basePath}/points/${encodeURIComponent(String(id))}/periodes`,
            recherche,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
