<ul class="navbar-nav mr-auto sidenav" id="navAccordion" >
  <li class="nav-item">
    <button
      type="button"
      class="nav-link nav-link-collapse btn-none"
      (click)="onClick(url.FLE3PAY_BASE, url.FLE3PAY)">

      FL3XPAY
    </button>
    <ul class="nav-second-level collapse" id="FL3XPAY" data-parent="#navAccordion" [ngClass]="loadIdAccordionExpanded()=== url.FLE3PAY_BASE? 'show': ''">
      <li class="nav-item"> <a class="nav-link" routerLink="{{'/' + url.FRACTIONNER_ACHATS}}" routerLinkActive="active">
        <span class="nav-link-text">Fractionner des achats</span> </a> </li>
      <li class="nav-item"> <a class="nav-link" routerLink="{{'/' + url.MES_FLE3PAY}}" routerLinkActive="active">
        <span class="nav-link-text">Mes FL3XPAY</span> </a> </li>
      <li class="nav-item"> <a class="nav-link" routerLink="{{'/' + url.FLEXPAY_INFOS_UTILES}}" routerLinkActive="active">
        <span class="nav-link-text">Infos utiles</span> </a> </li>
    </ul>
  </li>
  <li class="nav-item" *ngIf="!carteService.selectedCard.carteSupp" >
    <button class="nav-link nav-link-collapse btn-none"
            type="button"
            (click)="onClick(url.PLANPAY_BASE, url.PLANPAY)">
      PLAN&PAY
    </button>
    <ul class="nav-second-level collapse" id="credits" data-parent="#navAccordion" [ngClass]="loadIdAccordionExpanded()=== url.PLANPAY_BASE? 'show': ''">
      <li class="nav-item"> <a class="nav-link" routerLink="{{'/' + url.SIMULATION}}" routerLinkActive="active">
        <span class="nav-link-text">Simulation/Récap</span> </a> </li>
      <li class="nav-item"> <a class="nav-link" routerLink="{{'/' + url.MES_PLANPAY}}" routerLinkActive="active">
        <span class="nav-link-text">Mes PLAN&PAY</span> </a> </li>
      <li class="nav-item"> <a class="nav-link" routerLink="{{'/' + url.PLANPAY_INFO_UTILES}}" routerLinkActive="active">
        <span class="nav-link-text">Infos utiles</span> </a> </li>
    </ul>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="{{'/' + url.IPAY}}" routerLinkActive="active">IPAY</a>
  </li>
  <li class="nav-item">
    <button class="nav-link nav-link-collapse btn-none"
            type="button"
            (click)="onClick(url.SERVICES_VOYAGES_BASE, url.SERVICES_VOYAGES)"
    >
      Services voyage
    </button>
    <ul class="nav-second-level collapse" id="voyage" data-parent="#navAccordion" [ngClass]="loadIdAccordionExpanded()=== url.SERVICES_VOYAGES_BASE? 'show': ''">
      <li class="nav-item"> <a class="nav-link" routerLink="{{'/' + url.INFOS_SEJOUR}}" routerLinkActive="active">
        <span class="nav-link-text">Infos séjour</span> </a> </li>
      <li class="nav-item"> <a class="nav-link" routerLink="{{'/' + url.CONVERTISSEUR_DEVISES}}" routerLinkActive="active">
        <span class="nav-link-text">Convertisseur devises</span> </a> </li>
      <li class="nav-item"> <a class="nav-link" routerLink="{{'/' + url.RETRAITS_ARGENT}}" routerLinkActive="active">
        <span class="nav-link-text">Retraits d’argent</span> </a> </li>
      <li class="nav-item"> <a class="nav-link" routerLink="{{'/' + url.ASSURANCE_VOYAGES}}" routerLinkActive="active">
        <span class="nav-link-text">Assurances voyage</span> </a> </li>
    </ul>
  </li>
  <li class="nav-item">
    <button
      class="nav-link nav-link-collapse btn-none text-left"
      routerLink="{{'/' + url.PARRAINAGE}}"
      type="button"
      (click)="onClick(url.PARRAINAGE_BASE, url.PARRAINAGE)">
      Partagez vos avantages</button>
    <ul class="nav-second-level collapse" id="partagez" data-parent="#navAccordion"  [ngClass]="loadIdAccordionExpanded()=== url.PARTAGEZ_AVANTAGES_BASE? 'show': ''">
      <li class="nav-item"> <a class="nav-link" routerLink="{{'/' + url.PARRAINAGE}}" routerLinkActive="active">
        <span class="nav-link-text">Parrainage</span> </a> </li>
      <li class="nav-item"> <a class="nav-link" routerLink="{{'/' + url.CARTE_SUPPLEMENTAIRE}}" routerLinkActive="active">
        <span class="nav-link-text">Carte supplémentaire</span> </a> </li>
    </ul>
  </li>
</ul>
