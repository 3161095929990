/*
 * Environnement ALPHA = TESTS PITI : déploiement Angular sur serveur d'alpha-tests PITI, serveur API d'alpha-testsPITI
 * --------------------------------------------------------------------------------------------------------------------
 */
export const environment = {
  production: false,
  test: true,
  dev: false,
  version: require('../../package.json').version,

  // Accès au serveur de test PITI
  urlApi: 'https://moof-api.piti.pf/api',
  urlApiDevise: 'https://hoba-tests.socredo.pf/hoba3/api',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
