<div class="d-inline-block position-relative">
  <img src="{{imageName()}}" alt="" class="float-left"/>
  <div class="rond-container" [ngClass]="carteSize">
    <div class="rond" [ngClass]="(carteStatus? carteStatus : carte?.etatCarte)"></div>
  </div>
  <div class="rond-container" [ngClass]="carteSize">
    <div class="icone" [ngClass]="(carteStatus? carteStatus : carte?.etatCarte)"></div>
  </div>

</div>
