import {Injectable, } from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Subject} from 'rxjs';
import {Modal} from '../models/modal';
import {DataMessageService} from './data-message.service';
import {UserService} from './user.service';
import {MoofConstante} from '../constant/moof-constante';

export interface IModalService {
  opened: boolean;
  event: any ;

  openModalError(title: string , content: string, clickOk?: () => void, preFormattedText?: boolean): void ;
  openModalErrorKey(title: string , contentKey: string, clickOk?: () => void, preFormattedText?: boolean ): void ;
  openModalInformation(title: string , content: string, clickOk?: () => void, preFormattedText?: boolean): void ;
  openModalInformationKey(title: string , contentKey: string, clickOk?: () => void, preFormattedText?: boolean): void ;
  openModalValidation(title: string , content: string, clickOk?: () => void, clickCancel?: () => void, preFormattedText?: boolean): void ;
  openModalValidationKey(title: string , contentKey: string, clickOk?: () => void, clickCancel?: () => void,
                         preFormattedText?: boolean): void ;
  openModalConfirmation(title: string , content: string, clickOk?: () => void, preFormattedText?: boolean): void ;
  openModalConfirmationKey(title: string , contentKey: string, clickOk?: () => void, preFormattedText?: boolean): void ;
  openModalConfirmationDelay(title: string , content: string, clickOk?: () => void, preFormattedText?: boolean): void ;
  openModalConfirmationDelayKey(title: string , contentKey: string, clickOk?: () => void, preFormattedText?: boolean): void ;
  contentFromKey(contentKey: string): string ;
  close(motif: string): void ;

}

@Injectable({
  providedIn: 'root'
})
export class ModalService implements IModalService {

  public opened: boolean;
  /** Évènement d'ouverture d'une modale */
  #event = new Subject<Modal>();
  event = this.#event.asObservable();
  /** Évènement pour changer la valuer de submit */
  #eventSubmit = new Subject<boolean>();
  eventSubmit = this.#eventSubmit.asObservable();
  /** Évènement pour changer la modale */
  #eventClose = new Subject<string>();
  eventClose = this.#eventClose.asObservable();


  constructor(
    private readonly logger: NGXLogger,
    private readonly dataMessageService: DataMessageService,
    private readonly userService: UserService) {}

  /**
   * Ouvre une modale d'erreur et execute la fonction clickOk a la fermeture.
   *
   * @param title - le titre
   * @param content - le contenu
   * @param clickOk - function click ok
   * @param preFormattedText - true si le texte est préformaté
   */
  openModalError(title: string , content: string, clickOk?: () => void, preFormattedText = false): void {
    this.open(Modal.EnumModalType.Error, title, content, clickOk, null,  preFormattedText);
  }

  /**
   * Ouvre une modale d'erreur, execute la fonction clique ok a la fermeture et récupère le content grace a la cle
   *
   * @param title - le titre
   * @param contentKey - le contenu
   * @param clickOk - function click ok
   * @param preFormattedText - true si le texte est préformaté
   */
  openModalErrorKey(title: string , contentKey: string, clickOk?: () => void, preFormattedText = false): void {
    this.open(Modal.EnumModalType.Error, title, this.contentFromKey(contentKey), clickOk, null,  preFormattedText);
  }

  /**
   * Ouvre une modale d'information' et récupère le content grace a la cle
   *
   * @param title - le titre
   * @param contentKey - le contenu
   * @param clickOk - function click ok
   * @param preFormattedText - true si le texte est préformaté
   */
  openModalConfirmationDelayKey(title: string , contentKey: string, clickOk?: () => void, preFormattedText = false): void {
    this.open(Modal.EnumModalType.ConfirmationDelay, title, this.contentFromKey(contentKey), clickOk, null,  preFormattedText);
  }

  /**
   * Ouvre une modale d'information
   */
  openModalConfirmationDelay(title: string , content: string, clickOk?: () => void, preFormattedText = false): void {
    this.open(Modal.EnumModalType.ConfirmationDelay, title, content, clickOk, null, preFormattedText);
  }

  /**
   * Ouvre une modale d'information
   */
  openModalInformation(title: string , content: string, clickOk?: () => void, preFormattedText = false): void {
    this.open(Modal.EnumModalType.Information, title, content, clickOk, null, preFormattedText);
  }

  /**
   * Ouvre une modale d'information et récupère le content grace a la cle
   *
   * @param title - le titre
   * @param contentKey - le contenu
   * @param clickOk - function click ok
   * @param preFormattedText - true si le texte est préformaté
   */
  openModalInformationKey(title: string , contentKey: string, clickOk?: () => void, preFormattedText = false): void {
    this.open(Modal.EnumModalType.Information, title, this.contentFromKey(contentKey), clickOk, null,  preFormattedText);
  }

  /**
   * Ouvre une modale d'information
   */
  openModalConfirmation(title: string , content: string, clickOk?: () => void, preFormattedText = false): void {
    this.open(Modal.EnumModalType.Confirmation, title, content, clickOk, null, preFormattedText);
  }

  /**
   * Ouvre une modale d'information et récupère le content grace a la cle
   *
   * @param title - le titre
   * @param contentKey - le contenu
   * @param clickOk - function click ok
   * @param preFormattedText - true si le texte est préformaté
   */
  openModalConfirmationKey(title: string , contentKey: string, clickOk?: () => void, preFormattedText = false): void {
    this.open(Modal.EnumModalType.Confirmation, title, this.contentFromKey(contentKey), clickOk, null,  preFormattedText);
  }



  /**
   * Ouvre une modale de validation
   */
  openModalValidation(
    title: string ,
    content: string,
    clickOk?: () => void,
    clickCancel?: () => void,
    preFormattedText = false
  ): void {
    this.open(Modal.EnumModalType.Validation, title, content, clickOk, clickCancel, preFormattedText);
  }

  /**
   * Ouvre une modale de validation et récupère le content grace a la cle
   *
   * @param title - le titre
   * @param contentKey - le contenu
   * @param clickOk - function click ok
   * @param clickCancel - function click cancel
   * @param preFormattedText - true si le texte est préformaté
   */
  openModalValidationKey(
    title: string ,
    contentKey: string,
    clickOk?: () => void,
    clickCancel?: () => void,
    preFormattedText = false
  ): void {
    this.open(Modal.EnumModalType.Validation, title, this.contentFromKey(contentKey), clickOk, clickCancel, preFormattedText );
  }

  /** Récupère le contenu grace a la cle */
  contentFromKey(contentKey: string): string {
    const codePays = this.userService.codePays();
    return this.dataMessageService.findMessageData(contentKey, codePays) || MoofConstante.messageInexistant;
  }

  /** Ferme la modale */
  close(motif= ''): void {
    this.opened = false;
    this.logger.debug('Emission event close');
    this.#eventClose.next(motif);
  }

  /** Commence la soumission */
  startSubmit(): void {
    this.#eventSubmit.next(true);
  }

  /** Termine la soumissions */
  endSubmit(): void {
    this.#eventSubmit.next(false);
  }

  /** Termine la soumission et ferme la modale */
  endSubmitAndCLose(): void {
    this.endSubmit();
    this.close();
  }

  /**
   * Ouvre une modale
   */
  private open(
    type: Modal.EnumModalType,
    title: string,
    content: string,
    clickOk?: () => void,
    clickCancel?: () => void,
    preFormattedText = false
  ): void  {
    const modal = new Modal(title, content, type, clickOk, clickCancel,  preFormattedText);
    if (!this.opened) {
      this.opened = true;
      this.#event.next(modal);
    } else {
      this.logger.debug('Impossible d\'ouvrir la modale, une modale est deja ouverte', modal);
    }

  }
}


