import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {Url} from '../constant/url';
import {UserService} from '../services/user.service';
import {CarteService} from '../services/carte.service';
import {NGXLogger} from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class SelecDefaultCarteGuard implements CanActivate {

  constructor(
    private readonly userService: UserService,
    private readonly carteService: CarteService,
    private readonly logger: NGXLogger,
    private readonly router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise<boolean>((resolve) => {
      if (this.carteService.selectedCard) {
        resolve(true);
      } else {
        if (!this.carteService.selectedCard && this.carteService.portefeuilleCarte) {
          const p = this.carteService.portefeuilleCarte;
          const newCarte = p.getCartes() ? p.getCartes()[0] : p.getCartesSuplementaire()[0];
          this.carteService.loadCarte(newCarte.id)
            .then(() => resolve(true))
            .catch(() => {
            this.router.navigateByUrl('/' + Url.CARTE_SELECTION);
            resolve(false);
          });
        } else {
          this.router.navigateByUrl('/' + Url.CARTE_SELECTION);
          resolve(false);
        }

      }
    });
  }
}








