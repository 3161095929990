import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment.local-dev';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  environnement: string = environment.version;

  /**
   * Le numero de version
   *
   * @returns le numero de version
   */
  version(): string {
    let versions = environment.version.split('-');
    if (versions.length === 2) {
      return `${versions[0]} (dev ${versions[1]})`;
    }

    versions = environment.version.split('.');
    if (versions.length === 3) {
      if (versions[2] === '0') {
        return `${versions[0]}.${versions[1]}`;
      } else {
        return `${versions[0]}.${versions[1]} (alpha ${versions[2]})`;
      }
    }

    return environment.version;
  }

  versionOff(): string {
    let version: string;
    const versions = environment.version.split('\.');
    if (versions.length === 4) {
      version = `${versions[0]}.${versions[1]}.${versions[2]} (alpha ${versions[3]})`;
    } else {
      version = environment.version;
    }
    return version;
  }
}
