/**
 * web services MooF
 * Web Services REST **V1.0** pour le Home Banking de **OFINA** * 0.01: version initale (gestion du login) * 0.02: gestion de la création du login BLP * 0.03: gestion de l'ANR * 0.04: ajout du nom du client et du numéro 3ds dans le retour d'authentification * 0.05: liste des cartes * 0.06: détail carte * 0.07: détail point * 0.08: P3F liste des opérations * 0.09: P3F fractionnement * 0.10: gestion des versions * 0.11: infos sejour * 0.12: cartes supplémentaires * 0.13: coordonnées * 0.14: messagerie * 0.15: parametres * 0.16: oppositions * 0.17: déplafonnements * 0.18: activation carte * 0.19: notification push des messages * 0.20: catalogue Rewards * 0.21: transfert d'argent * 0.22: crédit * 0.23: ajout données NC
 *
 * OpenAPI spec version: 0.23
 * Contact: info@piti.pf
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../Conf/encoder';


import { Anr } from '../model/anr';
import { CartePoints } from '../model/cartePoints';
import { CommandeReward } from '../model/commandeReward';
import { CoordonneeClient } from '../model/coordonneeClient';
import { Credit } from '../model/credit';
import { DemandeCarteSupplementaire } from '../model/demandeCarteSupplementaire';
import { DemandeCredit } from '../model/demandeCredit';
import { Deplafonnement } from '../model/deplafonnement';
import { Erreur } from '../model/erreur';
import { FractionnementFlexPay } from '../model/fractionnementFlexPay';
import { InfoAnr } from '../model/infoAnr';
import { InfosDeplafonnement } from '../model/infosDeplafonnement';
import { InfosSejour } from '../model/infosSejour';
import { JourPrelevement } from '../model/jourPrelevement';
import { Message } from '../model/message';
import { ModificationCoordonneeClient } from '../model/modificationCoordonneeClient';
import { MotifOpposition } from '../model/motifOpposition';
import { OperationsFlexPay } from '../model/operationsFlexPay';
import { OperationsFractionnable } from '../model/operationsFractionnable';
import { ParametreSimulation } from '../model/parametreSimulation';
import { ParametresPush } from '../model/parametresPush';
import { PeriodeOperations } from '../model/periodeOperations';
import { PeriodePoints } from '../model/periodePoints';
import { PeriodesSituation } from '../model/periodesSituation';
import { PortefeuilleCarte } from '../model/portefeuilleCarte';
import { RecherchePeriode } from '../model/recherchePeriode';
import { SimulationCredit } from '../model/simulationCredit';
import { TransfertArgent } from '../model/transfertArgent';
import {Observable} from 'rxjs';
import {BASE_PATH} from '../Conf/variables';
import {Configuration} from '../Conf/configuration';



@Injectable()
export class PrivService {

    protected basePath = 'https://be.piti.pf/MOOF/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Déplafonnement d&#39;une carte
     *
     * @param id ID de la carte
     * @param deplafonnement le deplafonnement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public carteDeplafonnement(id: string, deplafonnement: Deplafonnement, observe?: 'body', reportProgress?: boolean): Observable<Erreur>;
    public carteDeplafonnement(id: string, deplafonnement: Deplafonnement, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Erreur>>;
    public carteDeplafonnement(id: string, deplafonnement: Deplafonnement, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Erreur>>;
    public carteDeplafonnement(id: string, deplafonnement: Deplafonnement, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling carteDeplafonnement.');
        }

        if (deplafonnement === null || deplafonnement === undefined) {
            throw new Error('Required parameter deplafonnement was null or undefined when calling carteDeplafonnement.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Erreur>(`${this.basePath}/cartes/${encodeURIComponent(String(id))}/deplafonnement`,
            deplafonnement,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte la liste des informations pour un déplafonnement
     *
     * @param id ID de la carte
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public carteInfosDeplafonnement(id: string, observe?: 'body', reportProgress?: boolean): Observable<InfosDeplafonnement>;
    public carteInfosDeplafonnement(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InfosDeplafonnement>>;
    public carteInfosDeplafonnement(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InfosDeplafonnement>>;
    public carteInfosDeplafonnement(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling carteInfosDeplafonnement.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InfosDeplafonnement>(`${this.basePath}/cartes/${encodeURIComponent(String(id))}/infosDeplafonnement`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Mise en opposition d&#39;une carte
     *
     * @param id ID de la carte
     * @param motif le motif de l&#39;opposition
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public carteOpposition(id: string, motif: MotifOpposition, observe?: 'body', reportProgress?: boolean): Observable<InfoAnr>;
    public carteOpposition(id: string, motif: MotifOpposition, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InfoAnr>>;
    public carteOpposition(id: string, motif: MotifOpposition, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InfoAnr>>;
    public carteOpposition(id: string, motif: MotifOpposition, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling carteOpposition.');
        }

        if (motif === null || motif === undefined) {
            throw new Error('Required parameter motif was null or undefined when calling carteOpposition.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<InfoAnr>(`${this.basePath}/cartes/${encodeURIComponent(String(id))}/opposition`,
            motif,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Envoi d&#39;un formulaire de demande de carte supplémentaire
     *
     * @param demandeCarteSupplementaire demande de carte supplémentaire
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public carteSupplementaire(demandeCarteSupplementaire: DemandeCarteSupplementaire, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public carteSupplementaire(demandeCarteSupplementaire: DemandeCarteSupplementaire, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public carteSupplementaire(demandeCarteSupplementaire: DemandeCarteSupplementaire, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public carteSupplementaire(demandeCarteSupplementaire: DemandeCarteSupplementaire, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (demandeCarteSupplementaire === null || demandeCarteSupplementaire === undefined) {
            throw new Error('Required parameter demandeCarteSupplementaire was null or undefined when calling carteSupplementaire.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/formulaires/carteSupplementaire`,
            demandeCarteSupplementaire,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Recupère la liste des cartes
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cartes(observe?: 'body', reportProgress?: boolean): Observable<PortefeuilleCarte>;
    public cartes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PortefeuilleCarte>>;
    public cartes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PortefeuilleCarte>>;
    public cartes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PortefeuilleCarte>(`${this.basePath}/cartes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Récupère la liste des cartes à points
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cartesRewards(observe?: 'body', reportProgress?: boolean): Observable<Array<CartePoints>>;
    public cartesRewards(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CartePoints>>>;
    public cartesRewards(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CartePoints>>>;
    public cartesRewards(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CartePoints>>(`${this.basePath}/cartes/rewards`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Téléchargement du contrat complet d&#39;un crédit
     *
     * @param id id du crédit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contratCredit(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public contratCredit(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public contratCredit(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public contratCredit(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling contratCredit.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/credits/${encodeURIComponent(String(id))}/contrat`,
            {
                responseType: 'blob',
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Envoi d&#39;un formulaire de modification des coordonnées client
     *
     * @param coordonnees demande de modification des coordonnées client
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public coordonneeClient(coordonnees: ModificationCoordonneeClient, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public coordonneeClient(coordonnees: ModificationCoordonneeClient, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public coordonneeClient(coordonnees: ModificationCoordonneeClient, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public coordonneeClient(coordonnees: ModificationCoordonneeClient, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (coordonnees === null || coordonnees === undefined) {
            throw new Error('Required parameter coordonnees was null or undefined when calling coordonneeClient.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/formulaires/coordonnees`,
            coordonnees,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Modification de la date de prélèvement
     *
     * @param jourPrelevement jour de prélèvement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public datePrelevement(jourPrelevement: JourPrelevement, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public datePrelevement(jourPrelevement: JourPrelevement, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public datePrelevement(jourPrelevement: JourPrelevement, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public datePrelevement(jourPrelevement: JourPrelevement, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (jourPrelevement === null || jourPrelevement === undefined) {
            throw new Error('Required parameter jourPrelevement was null or undefined when calling datePrelevement.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/formulaires/datePrelevement`,
            jourPrelevement,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Demande d&#39;un crédit
     *
     * @param idCarte ID de la carte
     * @param demandeCredit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public demandeCredit(idCarte: string, demandeCredit: DemandeCredit, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public demandeCredit(idCarte: string, demandeCredit: DemandeCredit, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public demandeCredit(idCarte: string, demandeCredit: DemandeCredit, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public demandeCredit(idCarte: string, demandeCredit: DemandeCredit, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idCarte === null || idCarte === undefined) {
            throw new Error('Required parameter idCarte was null or undefined when calling demandeCredit.');
        }

        if (demandeCredit === null || demandeCredit === undefined) {
            throw new Error('Required parameter demandeCredit was null or undefined when calling demandeCredit.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<string>(`${this.basePath}/credits/demande/${encodeURIComponent(String(idCarte))}`,
            demandeCredit,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Consultation des opérations d&#39;une carte
     *
     * @param id id de la carte
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detailOperation(id: string, observe?: 'body', reportProgress?: boolean): Observable<PeriodesSituation>;
    public detailOperation(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PeriodesSituation>>;
    public detailOperation(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PeriodesSituation>>;
    public detailOperation(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling detailOperation.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PeriodesSituation>(`${this.basePath}/operations/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Consultation des points d&#39;une carte
     *
     * @param id id de la carte
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public detailPoint(id: string, observe?: 'body', reportProgress?: boolean): Observable<PeriodesSituation>;
    public detailPoint(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PeriodesSituation>>;
    public detailPoint(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PeriodesSituation>>;
    public detailPoint(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling detailPoint.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<PeriodesSituation>(`${this.basePath}/points/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Envoi d&#39;un message
     *
     * @param message le message à envoyer (sujet + message) en JSON. Il faut utiliser (&#39;#/definitions/MessageClient&#39;)
     * @param fichier fichier joint par le client
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public envoiMessage(message: string, fichier?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public envoiMessage(message: string, fichier?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public envoiMessage(message: string, fichier?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public envoiMessage(message: string, fichier?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (message === null || message === undefined) {
            throw new Error('Required parameter message was null or undefined when calling envoiMessage.');
        }


        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams };
        let useForm = false;
        const convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (fichier !== undefined) {
            formParams = formParams.append('fichier', <any>fichier) || formParams;
        }
        if (message !== undefined) {
            formParams = formParams.append('message', <any>message) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/messages`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Liste des opérations flexpay en cours sur une carte
     *
     * @param id id de la carte
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public flexPayCarte(id: string, observe?: 'body', reportProgress?: boolean): Observable<OperationsFlexPay>;
    public flexPayCarte(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OperationsFlexPay>>;
    public flexPayCarte(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OperationsFlexPay>>;
    public flexPayCarte(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling flexPayCarte.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OperationsFlexPay>(`${this.basePath}/flexpay/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Fractionnement d&#39;une liste d&#39;opérations
     *
     * @param id id de la carte
     * @param fractionnements les fractionnements à réaliser
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fractionnement(id: string, fractionnements: Array<FractionnementFlexPay>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public fractionnement(id: string, fractionnements: Array<FractionnementFlexPay>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public fractionnement(id: string, fractionnements: Array<FractionnementFlexPay>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public fractionnement(id: string, fractionnements: Array<FractionnementFlexPay>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling fractionnement.');
        }

        if (fractionnements === null || fractionnements === undefined) {
            throw new Error('Required parameter fractionnements was null or undefined when calling fractionnement.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/flexpay/${encodeURIComponent(String(id))}/fractionnement`,
            fractionnements,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Récupère les parametres pour la simulation de crédit
     *
     * @param idCarte ID de la carte
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getParametreSimulation(idCarte: string, observe?: 'body', reportProgress?: boolean): Observable<ParametreSimulation>;
    public getParametreSimulation(idCarte: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParametreSimulation>>;
    public getParametreSimulation(idCarte: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParametreSimulation>>;
    public getParametreSimulation(idCarte: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idCarte === null || idCarte === undefined) {
            throw new Error('Required parameter idCarte was null or undefined when calling getParametreSimulation.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ParametreSimulation>(`${this.basePath}/parametres/simulation/${encodeURIComponent(String(idCarte))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Récupère le parametre des notification PUSH associé au device
     *
     * @param deviceId device id
     * @param deviceType type de device
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPushParametre(deviceId: string, deviceType: 'android' | 'ios', observe?: 'body', reportProgress?: boolean): Observable<ParametresPush>;
    public getPushParametre(deviceId: string, deviceType: 'android' | 'ios', observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParametresPush>>;
    public getPushParametre(deviceId: string, deviceType: 'android' | 'ios', observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParametresPush>>;
    public getPushParametre(deviceId: string, deviceType: 'android' | 'ios', observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceId === null || deviceId === undefined) {
            throw new Error('Required parameter deviceId was null or undefined when calling getPushParametre.');
        }

        if (deviceType === null || deviceType === undefined) {
            throw new Error('Required parameter deviceType was null or undefined when calling getPushParametre.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (deviceId !== undefined && deviceId !== null) {
            queryParameters = queryParameters.set('deviceId', <any>deviceId);
        }
        if (deviceType !== undefined && deviceType !== null) {
            queryParameters = queryParameters.set('deviceType', <any>deviceType);
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ParametresPush>(`${this.basePath}/parametres/notifications`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Récupération des infos client
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public infoClient(observe?: 'body', reportProgress?: boolean): Observable<CoordonneeClient>;
    public infoClient(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CoordonneeClient>>;
    public infoClient(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CoordonneeClient>>;
    public infoClient(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CoordonneeClient>(`${this.basePath}/formulaires/coordonnees`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Envoi d&#39;un formulaire d&#39;informations séjours
     *
     * @param infosSejour les informations séjours
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public infosSejour(infosSejour: InfosSejour, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public infosSejour(infosSejour: InfosSejour, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public infosSejour(infosSejour: InfosSejour, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public infosSejour(infosSejour: InfosSejour, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (infosSejour === null || infosSejour === undefined) {
            throw new Error('Required parameter infosSejour was null or undefined when calling infosSejour.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/formulaires/infosSejour`,
            infosSejour,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Liste des crédits actifs, en attente et signés
     *
     * @param idCarte ID de la carte
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listeCredit(idCarte: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Credit>>;
    public listeCredit(idCarte: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Credit>>>;
    public listeCredit(idCarte: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Credit>>>;
    public listeCredit(idCarte: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idCarte === null || idCarte === undefined) {
            throw new Error('Required parameter idCarte was null or undefined when calling listeCredit.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Credit>>(`${this.basePath}/credits/${encodeURIComponent(String(idCarte))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Récupération des messages
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listeMessage(observe?: 'body', reportProgress?: boolean): Observable<Array<Message>>;
    public listeMessage(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Message>>>;
    public listeMessage(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Message>>>;
    public listeMessage(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Message>>(`${this.basePath}/messages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Lecture d&#39;un message
     *
     * @param id id du message
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public messageLu(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public messageLu(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public messageLu(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public messageLu(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling messageLu.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<any>(`${this.basePath}/messages/${encodeURIComponent(String(id))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Liste des opérations fractionnables d&#39;une carte
     *
     * @param id id de la carte
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public operationsFractionnable(id: string, observe?: 'body', reportProgress?: boolean): Observable<OperationsFractionnable>;
    public operationsFractionnable(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OperationsFractionnable>>;
    public operationsFractionnable(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OperationsFractionnable>>;
    public operationsFractionnable(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling operationsFractionnable.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OperationsFractionnable>(`${this.basePath}/flexpay/${encodeURIComponent(String(id))}/fractionnement`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Consultation d&#39;une opérations d&#39;une carte sur une période
     *
     * @param id id de la carte
     * @param recherche la période que l&#39;on recherche
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public periodeOperation(id: string, recherche: RecherchePeriode, observe?: 'body', reportProgress?: boolean): Observable<PeriodeOperations>;
    public periodeOperation(id: string, recherche: RecherchePeriode, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PeriodeOperations>>;
    public periodeOperation(id: string, recherche: RecherchePeriode, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PeriodeOperations>>;
    public periodeOperation(id: string, recherche: RecherchePeriode, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling periodeOperation.');
        }

        if (recherche === null || recherche === undefined) {
            throw new Error('Required parameter recherche was null or undefined when calling periodeOperation.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PeriodeOperations>(`${this.basePath}/operations/${encodeURIComponent(String(id))}/periodes`,
            recherche,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Consultation des points d&#39;une carte sur une période
     *
     * @param id id de la carte
     * @param recherche la période que l&#39;on recherche
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public periodePoint(id: string, recherche: RecherchePeriode, observe?: 'body', reportProgress?: boolean): Observable<PeriodePoints>;
    public periodePoint(id: string, recherche: RecherchePeriode, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PeriodePoints>>;
    public periodePoint(id: string, recherche: RecherchePeriode, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PeriodePoints>>;
    public periodePoint(id: string, recherche: RecherchePeriode, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling periodePoint.');
        }

        if (recherche === null || recherche === undefined) {
            throw new Error('Required parameter recherche was null or undefined when calling periodePoint.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<PeriodePoints>(`${this.basePath}/points/${encodeURIComponent(String(id))}/periodes`,
            recherche,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Récupère la pièce jointe d&#39;un message
     *
     * @param id id du message
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pieceJointeMessage(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public pieceJointeMessage(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public pieceJointeMessage(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public pieceJointeMessage(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling pieceJointeMessage.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];

        return this.httpClient.get(`${this.basePath}/messages/${encodeURIComponent(String(id))}/pieceJointe`,
            {
                responseType: 'blob',
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Envoyer une commande
     *
     * @param commandes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postCommande(commandes: CommandeReward, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public postCommande(commandes: CommandeReward, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public postCommande(commandes: CommandeReward, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public postCommande(commandes: CommandeReward, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commandes === null || commandes === undefined) {
            throw new Error('Required parameter commandes was null or undefined when calling postCommande.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/rewards/commande`,
            commandes,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Retractation sur une demande de crédit
     *
     * @param id id du crédit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retractationCredit(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public retractationCredit(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public retractationCredit(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public retractationCredit(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling retractationCredit.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/credits/${encodeURIComponent(String(id))}/retractation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Créer ou met à jour le paramètre des notifications PUSH
     *
     * @param parametre
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setPushParametre(parametre: ParametresPush, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setPushParametre(parametre: ParametresPush, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setPushParametre(parametre: ParametresPush, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setPushParametre(parametre: ParametresPush, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parametre === null || parametre === undefined) {
            throw new Error('Required parameter parametre was null or undefined when calling setPushParametre.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/parametres/notifications`,
            parametre,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Récupère l&#39;impression de la simulation
     *
     * @param simulation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public simulationCreditPdf(simulation: SimulationCredit, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public simulationCreditPdf(simulation: SimulationCredit, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public simulationCreditPdf(simulation: SimulationCredit, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public simulationCreditPdf(simulation: SimulationCredit, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (simulation === null || simulation === undefined) {
            throw new Error('Required parameter simulation was null or undefined when calling simulationCreditPdf.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.basePath}/credits/simulation`,
            simulation,
            {
                responseType: 'blob',
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Transfert d&#39;argent à un ami
     *
     * @param transfertArgent
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public transfert(transfertArgent: TransfertArgent, observe?: 'body', reportProgress?: boolean): Observable<Erreur>;
    public transfert(transfertArgent: TransfertArgent, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Erreur>>;
    public transfert(transfertArgent: TransfertArgent, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Erreur>>;
    public transfert(transfertArgent: TransfertArgent, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (transfertArgent === null || transfertArgent === undefined) {
            throw new Error('Required parameter transfertArgent was null or undefined when calling transfert.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Erreur>(`${this.basePath}/formulaires/transfertArgent`,
            transfertArgent,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Validation par code ANR reçu par SMS
     *
     * @param anr l&#39;ANR à valider
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validationAnrPrive(anr: Anr, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public validationAnrPrive(anr: Anr, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public validationAnrPrive(anr: Anr, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public validationAnrPrive(anr: Anr, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (anr === null || anr === undefined) {
            throw new Error('Required parameter anr was null or undefined when calling validationAnrPrive.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/anr/prive`,
            anr,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
