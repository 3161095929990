import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataMessageService} from './core/services/data-message.service';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {MenuService} from './core/services/menu.service';
// @ts-ignore
import browserUpdate from 'browser-update';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  navEnd: Observable<NavigationEnd>;
  /** Navigateur pas supporté. */
  notSupported = false;
  private souscription = new Subscription();

  /** Pour les observables. */
  private subscription = new Subscription();
  constructor(
    private readonly dataMessageService: DataMessageService,
    private readonly router: Router,
    private readonly logger: NGXLogger,
    private readonly menuService: MenuService,
  ) {
    this.navEnd = router.events.pipe(
      filter(evt => evt instanceof NavigationEnd)
    )as Observable<NavigationEnd>;
  }

  ngOnInit(): void {
    this.souscription = this.navEnd.subscribe(evt => {
      this.menuService.navigate(evt.urlAfterRedirects);
    });

    // pour surveiller la valeur qui va être mise à jour par le plugin "browser update".
    // je ne peux pas passer par une propriété de la classe car le plugin ne la trouve pas
    const res = new BehaviorSubject<boolean>(false);
    this.subscription.add(res.asObservable().subscribe({
      next: (response: boolean) => {
        this.notSupported = response;
      },
    }));

    // plugin qui vérifie la version du navigateur
    browserUpdate({
      required: {e: -2, f: -6, o: -6, s: -1, c: -6, v: -3}, reminder: 0, reminderClosed: 0, noclose: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      nomessage: false, insecure: false, api: 2020.05, notify_esr: false,
      // eslint-disable-next-line max-len
      text: '<b>Votre navigateur Web ({brow_name}) est obsolète</b> et n\'est pas compatible avec l’Espace Client OFINA.' +
        '<br/>Pour plus de sécurité, mettez à jour votre navigateur  <br/> <a{up_but}>Mettre à jour</a>',
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions,@typescript-eslint/no-unused-vars
      onshow(infos: any) {
        res.next(true);
      },
    });
  }

  ngOnDestroy(): void {
    this.souscription.unsubscribe();
    this.subscription.unsubscribe();
  }
}
