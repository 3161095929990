import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {UserService} from '../services/user.service';
import {environment} from '../../../environments/environment.local-dev';

/**
 * Intercepteur HTTP qui ajoute l'authorisation si l'utilisateur est connecté.
 * https://medium.com/@ryanchenkie_40935/angular-authentication-using-the-http-client-and-http-interceptors-2f9d1540eb8
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    public readonly userService: UserService,
    private readonly logger: NGXLogger) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.userService.isConnected() && request.url.startsWith(environment.urlApi) ) {
      this.logger.debug('On ajoute le token moof');
      request = request.clone({
        setHeaders: {
          'Token-moof': `${this.userService.getToken()}`,
        },
      });
    }
    return next.handle(request);
  }
}
