
export class Modal {
  /** Titre de la modale */
  title: string;
  /** Contenu de la modale */
  content: string;
  /** Type de modale */
  type: Modal.EnumModalType;
  /** Indique si le contenu de la modale est préformaté */
  preFormattedText: boolean;
  /** Fonction exécuté au cancel */
  clickCancel: () => void;
  /** Fonction exécuté au click OK */
  clickOk: () => void;

  constructor(title: string,
              content: string,
              type: Modal.EnumModalType,
              clickOk?: () => void,
              clickCancel?: () => void,
              preFormattedText = false
  ) {
    this.title =  title;
    this.content = content;
    this.type = type;
    this.preFormattedText = preFormattedText;
    this.clickCancel = clickCancel;
    this.clickOk = clickOk;
  }

}

export namespace Modal {
  export type EnumModalType = 'Error' | 'Validation' | 'Information' | 'Confirmation' |'ConfirmationInformationDelay';
  export const EnumModalType = {
    Error: 'Error' as EnumModalType,
    Validation: 'Validation' as EnumModalType,
    Information: 'Information' as EnumModalType,
    Confirmation: 'Confirmation' as EnumModalType,
    ConfirmationDelay: 'ConfirmationDelay' as EnumModalType,
  };
}




