export class Url {
  /* eslint-disable @typescript-eslint/naming-convention */
  public static ERREUR_BASE = 'error';
  public static ERREUR = Url.ERREUR_BASE;

  public static AUTHENTIFICATION_BASE = 'auth';
  public static AUTHENTIFICATION = Url.AUTHENTIFICATION_BASE;
  public static CGU_BASE = 'cgu';
  public static CGU = `${Url.AUTHENTIFICATION_BASE}/${Url.CGU_BASE}`;

  public static LOGIN_BASE = 'login';
  public static LOGIN = `${Url.AUTHENTIFICATION}/${Url.LOGIN_BASE}`;
  public static ACTIVATION_CARTE_BASE = 'activation-carte';
  public static ACTIVATION_CARTE = Url.ACTIVATION_CARTE_BASE;
  public static PREMIERE_CONNEXION_BASE = 'Premiere-connexion';
  public static PREMIERE_CONNEXION = Url.PREMIERE_CONNEXION_BASE;

  public static PAGE_ACCUEIL_BASE = 'accueil-carte';
  public static PAGE_ACCUEIL = Url.PAGE_ACCUEIL_BASE;
  public static CARTE_SELECTION_BASE = 'carte-selection';
  public static CARTE_SELECTION = Url.CARTE_SELECTION_BASE;

  public static RELEVE_ACTIVITES_BASE = 'releves-et-activites';
  public static RELEVE_ACTIVITES = Url.RELEVE_ACTIVITES_BASE;
  public static SERVICES_BASE = 'services';
  public static SERVICES = Url.SERVICES_BASE;
  public static GESTION_COMPTE_BASE = 'gestion-compte';
  public static GESTION_COMPTE = Url.GESTION_COMPTE_BASE;
  public static SMS_RENFORCE_BASE = 'sms-renforce';
  public static SMS_RENFORCE = Url.SMS_RENFORCE_BASE;

  public static BESOIN_AIDE_BASE = 'besoin-daide';
  public static BESOIN_AIDE = Url.BESOIN_AIDE_BASE;

  public static DETAIL_OPERATION_BASE = 'details-operations';
  public static CATEGORISATION_DEPENSE_BASE = 'categorisation-depenses';
  public static COMPTE_POINTS_MILES_BASE = 'compte-fidelite';
  public static REMBOURSEMENT_AIR_CALIN_BASE = 'remboursement-air-calin';

  public static FLE3PAY_BASE = 'fl3xpay';
  public static FRACTIONNER_ACHATS_BASE = 'fractionner-achats';
  public static MES_FLE3PAY_BASE = 'mes-dossiers';
  public static FLEXPAY_INFO_UTILE_BASE = 'infos-utiles';
  public static PLANPAY_BASE = 'plan&pay';
  public static SOUSCRIPTION_BASE = 'souscription';
  public static SIMULATION_BASE = 'simulation';
  public static MES_PLANPAY_BASE = 'mes-dossiers';
  public static PLANPAY_INFO_UTILE_BASE = 'infos-utiles';
  public static SERVICES_VOYAGES_BASE = 'services-voyages';
  public static INFOS_SEJOUR_BASE = 'infos-sejours';
  public static CONVERTISSEUR_DEVISES_BASE = 'convertisseur-devises';
  public static RETRAITS_ARGENT_BASE = 'retraits';
  public static ASSURANCE_VOYAGES_BASE = 'assurances';
  public static PARTAGEZ_AVANTAGES_BASE = 'partagez-vos-avantages';
  public static PARRAINAGE_BASE = 'parrainage';
  public static CARTE_SUPPLEMENTAIRE_BASE = 'carte-supp';
  public static IPAY_BASE = 'ipay';

  public static COMPTE_CARTE_BASE = 'compte-carte';
  public static DEPLAFONEMENT_BASE = 'deplafonnement';
  public static FREEZE_CARTE_BASE = 'freeze';
  public static MISE_OPPOSITION_BASE = 'opposition';
  public static DATE_PRELEVEMENT_BASE = 'prelevement';
  public static ALERTE_COMPTE_CARTE_BASE = 'alertes';
  public static GESTION_CODE_PIN_BASE = 'code-pin';
  public static INSCRIPTION_E_RELEVE_BASE = 'e-releve';
  public static MES_INFORMATIONS_BASE = 'mes-informations';
  public static MESSAGERIE_BASE = 'messagerie';

  public static FAQ_BASE = 'faq';
  public static NOUS_CONTACTER_BASE = 'nous-contacter';
  public static INFO_LEGALES_BASE = 'infos-legales';
  public static CONDITIONS_GENERALE_SERVICE_BASE = 'cg-service';
  public static PARAMETRES_BASE = 'parametres';
  public static MOT_PASSE_BASE = 'mot-de-passe';
  public static REINIT_MOT_PASSE_BASE = 'reinit-mot-de-passe';
  public static GESTION_COOKIES_BASE = 'gestion-cookies';
  public static ID_BASE = ':id';

  public static DETAIL_OPERATION = `${Url.RELEVE_ACTIVITES}/${Url.DETAIL_OPERATION_BASE}`;
  public static CATEGORISATION_DEPENSE = `${Url.RELEVE_ACTIVITES}/${Url.CATEGORISATION_DEPENSE_BASE}`;
  public static COMPTE_POINTS_MILES = `${Url.RELEVE_ACTIVITES}/${Url.COMPTE_POINTS_MILES_BASE}`;
  public static REMBOURSEMENT_AIR_CALIN_BASE_ID = `${Url.REMBOURSEMENT_AIR_CALIN_BASE}/${Url.ID_BASE}`;
  public static REMBOURSEMENT_AIR_CALIN = `${Url.RELEVE_ACTIVITES}/${Url.REMBOURSEMENT_AIR_CALIN_BASE}`;

  public static FLE3PAY = `${Url.SERVICES}/${Url.FLE3PAY_BASE}`;
  public static FRACTIONNER_ACHATS = `${Url.FLE3PAY}/${Url.FRACTIONNER_ACHATS_BASE}`;
  public static MES_FLE3PAY = `${Url.FLE3PAY}/${Url.MES_FLE3PAY_BASE}`;
  public static FLEXPAY_INFOS_UTILES = `${Url.FLE3PAY}/${Url.FLEXPAY_INFO_UTILE_BASE}`;
  public static PLANPAY = `${Url.SERVICES}/${Url.PLANPAY_BASE}`;
  public static SOUSCRIPTION = `${Url.PLANPAY}/${Url.SOUSCRIPTION_BASE}`;
  public static SIMULATION = `${Url.PLANPAY}/${Url.SIMULATION_BASE}`;
  public static MES_PLANPAY = `${Url.PLANPAY}/${Url.MES_PLANPAY_BASE}`;
  public static PLANPAY_INFO_UTILES = `${Url.PLANPAY}/${Url.PLANPAY_INFO_UTILE_BASE}`;
  public static IPAY = `${Url.SERVICES}/${Url.IPAY_BASE}`;
  public static SERVICES_VOYAGES = `${Url.SERVICES}/${Url.SERVICES_VOYAGES_BASE}`;
  public static INFOS_SEJOUR = `${Url.SERVICES_VOYAGES}/${Url.INFOS_SEJOUR_BASE}`;
  public static CONVERTISSEUR_DEVISES = `${Url.SERVICES_VOYAGES}/${Url.CONVERTISSEUR_DEVISES_BASE}`;
  public static RETRAITS_ARGENT = `${Url.SERVICES_VOYAGES}/${Url.RETRAITS_ARGENT_BASE}`;
  public static ASSURANCE_VOYAGES = `${Url.SERVICES_VOYAGES}/${Url.ASSURANCE_VOYAGES_BASE}`;
  public static PARTAGEZ_AVANTAGES = `${Url.SERVICES}/${Url.PARTAGEZ_AVANTAGES_BASE}`;
  public static PARRAINAGE = `${Url.PARTAGEZ_AVANTAGES}/${Url.PARRAINAGE_BASE}`;
  public static CARTE_SUPPLEMENTAIRE = `${Url.PARTAGEZ_AVANTAGES}/${Url.CARTE_SUPPLEMENTAIRE_BASE}`;
  public static COMPTE_CARTE = `${Url.GESTION_COMPTE}/${Url.COMPTE_CARTE_BASE}`;
  public static DEPLAFONEMENT = `${Url.COMPTE_CARTE}/${Url.DEPLAFONEMENT_BASE}`;
  public static FREEZE_CARTE = `${Url.COMPTE_CARTE}/${Url.FREEZE_CARTE_BASE}`;
  public static MISE_OPPOSITION = `${Url.COMPTE_CARTE}/${Url.MISE_OPPOSITION_BASE}`;
  public static DATE_PRELEVEMENT = `${Url.COMPTE_CARTE}/${Url.DATE_PRELEVEMENT_BASE}`;
  public static ALERTE_COMPTE_CARTE = `${Url.COMPTE_CARTE}/${Url.ALERTE_COMPTE_CARTE_BASE}`;
  public static GESTION_CODE_PIN = `${Url.COMPTE_CARTE}/${Url.GESTION_CODE_PIN_BASE}`;
  public static INSCRIPTION_E_RELEVE = `${Url.COMPTE_CARTE}/${Url.INSCRIPTION_E_RELEVE_BASE}`;
  public static MES_INFORMATIONS = `${Url.GESTION_COMPTE}/${Url.MES_INFORMATIONS_BASE}`;
  public static MESSAGERIE = `${Url.GESTION_COMPTE}/${Url.MESSAGERIE_BASE}`;
  public static PARAMETRES = `${Url.GESTION_COMPTE}/${Url.PARAMETRES_BASE}`;
  public static MOT_PASSE = `${Url.PARAMETRES}/${Url.MOT_PASSE_BASE}`;
  public static GESTION_COOKIES = `${Url.PARAMETRES}/${Url.GESTION_COOKIES_BASE}`;
  public static FAQ = `${Url.BESOIN_AIDE}/${Url.FAQ_BASE}`;
  public static NOUS_CONTACTER = `${Url.BESOIN_AIDE}/${Url.NOUS_CONTACTER_BASE}`;
  public static INFO_LEGALES = `${Url.BESOIN_AIDE}/${Url.INFO_LEGALES_BASE}`;
  public static CONDITIONS_GENERALE_SERVICE = `${Url.INFO_LEGALES}/${Url.CONDITIONS_GENERALE_SERVICE_BASE}`;

  // FOOTER
  public A_PROPOS_AMERICAN_EXPRES = 'https://www.americanexpress.pf/american-express-et-ofina/';
  public MENTION_LEGALES = 'https://www.americanexpress.pf/mentions-legales/';
  public PROTECTION_DONNEES = 'https://www.americanexpress.pf/protection-des-donnees/';
  public MARQUE_DEPOSES = 'https://www.americanexpress.pf/marques-deposees/';

  public static REMBOURSEMENT_AIR_CALIN_FUN = (id: number) => `${Url.REMBOURSEMENT_AIR_CALIN}/${id}`;

}
