<app-header-card (navigation)="onNavbarClick($event)"></app-header-card>

<main class="main ">

  <!--menu button-->
  <app-sidebar-button></app-sidebar-button>
  <!--menu button-->

  <div class="container mt-4">
      <div class="wrapper align-items-stretch"
           [ngClass]="loadSidebarMenu()? 'block':''"
           id="wrapper">
        <div class="row">

          <div id="sidebar"  class="pr-sm-0 pr-md-3" [ngClass]="loadSidebarMenu() ? 'col-md-3': 'active'">
            <app-sidebar></app-sidebar>
          </div>

          <div class="w-100" [ngClass]="loadSidebarMenu() ? 'col-md-9': 'col-12'" >
            <router-outlet></router-outlet>
          </div>

        </div>


    </div>
  </div>

</main>




