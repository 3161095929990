/**
 * web services MooF adapté pour BLP2
 * Web Services REST **V2.0** pour le Home Banking de **OFINA** * 0.01: version initale (gestion du login) * 0.02: gestion de la création du login BLP * 0.03: gestion de l'ANR * 0.04: ajout du nom du client et du numéro 3ds dans le retour d'authentification * 0.05: liste des cartes * 0.06: détail carte * 0.07: détail point * 0.08: P3F liste des opérations * 0.09: P3F fractionnement * 0.10: gestion des versions * 0.11: infos sejour * 0.12: cartes supplémentaires * 0.13: coordonnées * 0.14: messagerie * 0.15: parametres * 0.16: oppositions * 0.17: déplafonnements * 0.18: activation carte * 0.19: notification push des messages * 0.20: catalogue Rewards * 0.21: transfert d'argent * 0.22: crédit * 0.23: ajout données NC * 2.00: nouveaux services pour BLP2 * 2.10: Aircalin * 2.11: activation carte via ANR * 2.12: homogénéisation des retours pour media ANR non renseigné dans SAB (HTTP 417)
 *
 * OpenAPI spec version: 2.12
 * Contact: info@piti.pf
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs/';

import {Anr} from '../model/anr';
import {AnrPublic} from '../model/anrPublic';
import {CheckValidationAnr} from '../model/checkValidationAnr';

import {BASE_PATH} from '../Conf/variables';
import {Configuration} from '../Conf/configuration';


@Injectable()
export class ANRService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = 'https://moof-api.piti.pf';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
              @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * Validation par code ANR reçu par SMS
   *
   * @param anr l&#39;ANR à valider
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public anrActivationCarte(anr: Anr, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public anrActivationCarte(anr: Anr, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public anrActivationCarte(anr: Anr, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public anrActivationCarte(anr: Anr, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (anr === null || anr === undefined) {
      throw new Error('Required parameter anr was null or undefined when calling anrActivationCarte.');
    }

    let headers = this.defaultHeaders;

    // authentication (token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
      headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/anr/activationCarte`,
      anr,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Vérifie si un ANR est validé (utilisé pour KEYPASS)
   *
   * @param cleAnr Clé ANR
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public checkValidationAnr(cleAnr: string, observe?: 'body', reportProgress?: boolean): Observable<CheckValidationAnr>;

  public checkValidationAnr(cleAnr: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CheckValidationAnr>>;

  public checkValidationAnr(cleAnr: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CheckValidationAnr>>;

  public checkValidationAnr(cleAnr: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (cleAnr === null || cleAnr === undefined) {
      throw new Error('Required parameter cleAnr was null or undefined when calling checkValidationAnr.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<CheckValidationAnr>(`${this.basePath}/anr/check/${encodeURIComponent(String(cleAnr))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Validation par code ANR reçu par SMS
   *
   * @param anr l&#39;ANR à valider
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public validationAnrPrive(anr: Anr, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public validationAnrPrive(anr: Anr, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public validationAnrPrive(anr: Anr, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public validationAnrPrive(anr: Anr, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (anr === null || anr === undefined) {
      throw new Error('Required parameter anr was null or undefined when calling validationAnrPrive.');
    }

    let headers = this.defaultHeaders;

    // authentication (token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
      headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/anr/prive`,
      anr,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * Validation par code ANR reçu par SMS
   *
   * @param anr l&#39;ANR à valider
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public validationAnrPublic(anr: AnrPublic, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public validationAnrPublic(anr: AnrPublic, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public validationAnrPublic(anr: AnrPublic, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public validationAnrPublic(anr: AnrPublic, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (anr === null || anr === undefined) {
      throw new Error('Required parameter anr was null or undefined when calling validationAnrPublic.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'text/plain'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/vnd.moof.v10+json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/anr/public`,
      anr,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

}
