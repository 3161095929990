import { Component} from '@angular/core';
import {MenuService} from '../../../services/menu.service';

@Component({
  selector: 'app-sidebar-button',
  templateUrl: './sidebar-button.component.html',
  styleUrls: ['./sidebar-button.component.css']
})
export class SidebarButtonComponent  {

  constructor(private readonly menuService: MenuService) { }


  /**
   * Action au click sur le menu sidebar
   */
  onSideBarMenuClick() {
    this.menuService.onSideBarMenuClick();
  }

  /**
   * @returns true si le bouton est activé
   */
  loadSidebarButton(): boolean {
    return this.menuService.sidebarButton;
  }
}
