import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {UserService} from '../services/user.service';
import {NGXLogger} from 'ngx-logger';
import {tap} from 'rxjs/operators';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  constructor(
    private readonly userService: UserService,
    private readonly logger: NGXLogger) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // si on veut on peut filtrer par les URLs (il faudrait le faire pour le login, normalement).
    // https://stackoverflow.com/questions/49746548/how-to-exclude-some-services-like-login-register-from-interceptor-angular-5-ht

    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // nothing to do
      }
    }, (err: any) => {
      if (err instanceof  HttpErrorResponse && err.status === 401 && this.userService.isConnected()) {
        this.logger.debug('401');
        this.userService.logOut();
      }
      return new Observable();
    }));
  }
}
