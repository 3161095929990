<div class="container">
  <div class=" row  my-2">
    <div class="col-7 col-md-3 col-lg-4 text-md-left d-flex justify-content-start">
      <a href="{{homeLink()}}" rel="noopener noreferrer" target="_blank">
        <div class="bg-blue-light p-2 rounded fit-content " >
          <i class="fas fa-home text-white txt-32"></i>
        </div>
      </a>
      <div class="d-md-none d-inline pt-2 pl-2">
        <img  src = "assets/images/main-logo-staked2.png"  alt="" class="img-fluid"/>
      </div>
    </div>
    <div class="col-md-6 col-lg-4 text-center d-none d-md-inline ">
      <img  src = "assets/images/main_logo2.png"  alt="" class="img-fluid"/>
    </div>

    <div class="col-5 col-md-3 col-lg-4 text-right pt-2">
      <button type="button" (click)="nouscontacter()" class="top-head-lnk mr-3 btn-none">
        <i class="fas fa-envelope"></i>
        <span class="d-none d-lg-inline">Nous contacter</span>
      </button>
      <a routerLink="#" class="top-head-lnk" (click)="logOut()">
        <i class="fas fa-sign-out-alt"></i>
        <span class="d-none d-lg-inline">déconnexion</span>
      </a>
    </div>
  </div>
</div>
