import {SituationCartePoints as ApiSituationCartepoints} from '../../api/model/situationCartePoints';
import {SituationCarte as ApiSituationCarte} from '../../api/model/situationCarte';
import {SituationCarte} from './situation-carte';

export class SituationCartePoints extends SituationCarte {
  /**
   * points aquis sur la période
   */
  pointsPeriode: number;
  /**
   * le solde de points en fin de la première période retournée par /points/{id}
   */
  pointsTotaux: number;

  constructor( carte: ApiSituationCartepoints ) {
    const carteSituation = carte as ApiSituationCarte;
    super( carteSituation);
    this.pointsPeriode = carte.pointsPeriode;
    this.pointsTotaux = carte.pointsTotaux;
  }
}
