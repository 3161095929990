import { Component} from '@angular/core';
import {Router, } from '@angular/router';
import {MenuService} from '../../services/menu.service';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout-card.component.html',
  styleUrls: ['./content-layout-card.component.css']
})
export class ContentLayoutCardComponent {

  constructor(private readonly router: Router,
              private readonly menuService: MenuService) { }

  /**
   * Action au click sur la barre de navigation
   *
   * @param $event - l'évènement
   */
  onNavbarClick($event: any) {
   this.menuService.onNavbarClick($event);
  }

  /**
   * @returns true si le menu est en mode bouton
   */
  loadSidebarMenu(): boolean {
    return this.menuService.sidebarMenu;
  }

  /**
   * @returns true si la sidebar est en mode bouton
   */
  loadSidebarButton(): boolean {
    return this.menuService.sidebarButton;
  }

  /**
   * @returns l'id de l'accordéon déplié
   */
  loadIdAccordionExpanded(): string {
    return this.menuService.idAccordionExpanded;
  }
}
