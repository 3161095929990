/**
 * web services MooF adapté pour BLP2
 * Web Services REST **V1.0** pour le Home Banking de **OFINA** * 0.01: version initale (gestion du login) * 0.02: gestion de la création du login BLP * 0.03: gestion de l'ANR * 0.04: ajout du nom du client et du numéro 3ds dans le retour d'authentification * 0.05: liste des cartes * 0.06: détail carte * 0.07: détail point * 0.08: P3F liste des opérations * 0.09: P3F fractionnement * 0.10: gestion des versions * 0.11: infos sejour * 0.12: cartes supplémentaires * 0.13: coordonnées * 0.14: messagerie * 0.15: parametres * 0.16: oppositions * 0.17: déplafonnements * 0.18: activation carte * 0.19: notification push des messages * 0.20: catalogue Rewards * 0.21: transfert d'argent * 0.22: crédit * 0.23: ajout données NC * 2.00: nouveaux services pour BLP2
 *
 * OpenAPI spec version: 2.00
 * Contact: info@piti.pf
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface Carte {
  /**
   * id de la carte
   */
  id: string;
  /**
   * type de carte
   */
  typeCarte: string;
  /**
   * libelle de la carte
   */
  libelleCarte: string;
  /**
   * numéro de la carte masqué
   */
  numeroCarte: string;
  /**
   * type de point
   */
  typePoint?: Carte.TypePointEnum;
  /**
   * numéro fidélité
   */
  numeroFidelite?: string;
  /**
   * type de carte eligible P3F
   */
  eligibleP3f: boolean;
  /**
   * carte eligible Cnf
   */
  eligibleCnf: boolean;
  /**
   * etat courant de la carte
   */
  etatCarte?: Carte.EtatCarteEnum;
  /**
   * true si le client connecté est le titulaire de la carte
   */
  titulaire: boolean;
}
export namespace Carte {
  export type TypePointEnum = 'rewards' | 'miles' | 'miles qantas' | 'points air calin';
  export const TypePointEnum = {
    Rewards: 'rewards' as TypePointEnum,
    Miles: 'miles' as TypePointEnum,
    MilesQantas: 'miles qantas' as TypePointEnum,
    PointsAirCalin: 'points air calin' as TypePointEnum
  };
  export type EtatCarteEnum = 'non activee' | 'active' | 'freeze' | 'oppose' | 'bloque' | 'expire';
  export const EtatCarteEnum = {
    NonActivee: 'non activee' as EtatCarteEnum,
    Active: 'active' as EtatCarteEnum,
    Freeze: 'freeze' as EtatCarteEnum,
    Oppose: 'oppose' as EtatCarteEnum,
    Bloque: 'bloque' as EtatCarteEnum,
    Expire: 'expire' as EtatCarteEnum
  };
}
