
<!-- DEBUT du Footer -->
<footer>
  <div class="container-fluid">
    <div class="frise">
      <img class="frise-gauche" src="assets/images/footer-gauche.png" alt="footer-gauche">
      <div class="frise-milieu"></div>
      <img class="frise-droite" src="assets/images/footer-droit.png" alt="footer-droit">
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <a target="_blank" rel="noopener noreferrer" href="https://www.americanexpress.pf/american-express-et-ofina/">A propos d'American Express et OFINA</a>&nbsp;|&nbsp;
        <a target="_blank" rel="noopener noreferrer" href="https://www.americanexpress.pf/mentions-legales/">Mentions légales</a>&nbsp;|&nbsp;
        <a target="_blank" rel="noopener noreferrer" href="https://www.americanexpress.pf/protection-des-donnees/">Protection des données</a>&nbsp;|&nbsp;
        <a target="_blank" rel="noopener noreferrer" href="https://www.americanexpress.pf/marques-deposees/">Marques déposées</a>
      </div>
      <div class="col-12 text-center txt-10 text-dark">Copyright © 2016 American Express Company</div>
      <div class="col-12 text-center my-3"><img src="assets/images/ofina-logo.png" width="90" alt=""/></div>
      <div class="col-12 text-right txt-8 text-dark pb-1 version"><span>{{version}}</span> </div>
    </div>
  </div>
</footer>
<!-- FIN du Footer -->
