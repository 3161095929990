<div class="container-wrap border-bottom mb-4 header">
  <div class=" row  my-2 justify-content-center">

    <div class="col-md-6 col-lg-4 text-center">
      <img  src = "assets/images/main_logo2.png"  alt="" class="img-fluid"/>
    </div>

  </div>
</div>

<div class="row justify-content-center pt-5">
  <div class="col-11 col-md-6">
    <div class="row">
      <div class="col-6">
        <img src="assets/images/ico-erreur-500.svg" class="img-err" alt="ico-err">
      </div>
      <div class="col-6">
        <div class="display-4 text-sm txt-blue-deep font-weight-bold"> ERREUR {{erreur}} </div>
        <a routerLink="{{portefeuilleCarte}}" class="txt-grey-light" *ngIf="init"> > Retour a la page accueil</a>
        <button (click)="reloadApp()" class="txt-grey-light btn btn-none" *ngIf="!init"> > Recharger l'application</button>
      </div>
    </div>
  </div>

</div>

