import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {CoreModule} from '../core.module';
import {UserService} from '../services/user.service';

/**
 * Redirige le client vers la page de changement de mdp si nécessaire.
 */
@Injectable({
  providedIn: CoreModule,
})
export class ModificationMdpGuard implements CanActivateChild {

  constructor(private userService: UserService, private router: Router) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // on vérifie que l'utilisateur est connecté et doit changer son mdp
    if (this.userService.user && this.userService.user.changePassword) {
      if (state.url === '/reinit-mot-de-passe') {
        return true;
      } else {
        this.router.navigate(['/reinit-mot-de-passe']);
        return false;
      }
    }
    return true;
  }
}
