import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {NgIdleModule} from '@ng-idle/core';
import {DataMessageService} from './core/services/data-message.service';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {Url} from './core/constant/url';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import {ApiModule} from './api/api.module';

export function appInit(dataMessageService: DataMessageService, router: Router) {
  return (): Promise<void> => new Promise((resolve) => {
    Promise.all([dataMessageService.loadMessage(), dataMessageService.loadParam()])
      .then(() => {
        dataMessageService.initSucessfull();
        resolve();
      })
      .catch((err: HttpErrorResponse) => {
        router.navigate([Url.ERREUR], {state: {erreur: err.status}});
        resolve();
      });
  });
}

registerLocaleData(localeFr, 'fr', localeFrExtra);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ApiModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    // https://angular.io/guide/singleton-services
    // il ne faut importer ce service qu'ici sinon les services ne sont pas des singletons!
    // https://www.npmjs.com/package/@ng-idle/core
    NgIdleModule.forRoot(),
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'fr'},
    {provide: APP_INITIALIZER, useFactory: appInit, multi: true, deps: [DataMessageService, Router]},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
