
  <div class="box-menu-blp" *ngIf="loadSidebarContent()">
    <!--NavBar Button-->
    <div class="navOpenClose" id="sidecontrol2">
      <button  id="sidebarCollapseOpen btn-lignt btn" class="btnOpenClose" (click)="onSideBarClick()">
        <i class="fas fa-angle-left fa-2x " ></i>
        <span class="sr-only">Toggle Menu</span>
      </button>
    </div>

    <div [ngSwitch]="loadSidebarContent()">
      <!-- Sidebar service-->
      <app-sidebar-services *ngSwitchCase="'services'"></app-sidebar-services>

      <!-- Sidebar releves-->
      <app-sidebar-releves *ngSwitchCase="'releves-et-activites'"></app-sidebar-releves>

      <!--Sidebar compte-->
      <app-sidebar-compte *ngSwitchCase="'gestion-compte'"></app-sidebar-compte>
    </div>

  </div>



