<ng-template #content let-modal>

  <div class="modal-body modal-authentification">
    <h4 class="modal-title">{{modale.title}}</h4>

    <ng-container [ngSwitch]="modale.type">
      <i *ngSwitchCase="enum.Information" class="fas fa-info-circle icone-information modal-icone mb-3"></i>
      <div *ngSwitchCase="enum.Error" class="icone modal-icone popup-erreur-ico mb-3"></div>
      <i *ngSwitchCase="enum.Validation" class="fas fa-question-circle modal-icone icone-validation mb-3"></i>
      <div *ngSwitchCase="enum.ConfirmationDelay" class="icone modal-icone popup-confirmer-delay-ico mb-3 "></div>
      <div *ngSwitchCase="enum.Confirmation" class="icone modal-icone popup-confirmer-ico mb-3 "></div>

    </ng-container>


    <ng-container *ngIf="!modale.preFormattedText"><p class="mb-0 txt-16 text-dark">{{modale.content}}</p> </ng-container>
    <ng-container *ngIf="modale.preFormattedText"><p class="mb-0"> <pre class="text-left">{{modale.content}}</pre> </ng-container>

  </div>
  <div class="modal-footer justify-content-center border-0">
    <div class="row mb-4">
      <div class="col" *ngIf="modale.type==='Validation'">
        <button type="submit" class="btn btn-blp-grey btn-sm text-white btn-modal-grey px-sm-2 px-0" id="btn-modal2" [disabled]="submitEnCours"  (click)="onClickCancel()">
          <div class="text-btn-modal">Annuler</div>
        </button>
      </div>
      <div class="col">
        <button type="submit" class="btn btn-dark-blue btn-sm px-sm-2 px-0" id="btn-modal" [ladda]="submitEnCours" (click)="onClickOk()">
          <div class="text-btn-modal">OK</div>
        </button>
      </div>

    </div>
  </div>

</ng-template>



