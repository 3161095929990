/**
 * web services MooF adapté pour BLP2
 * Web Services REST **V1.0** pour le Home Banking de **OFINA** * 0.01: version initale (gestion du login) * 0.02: gestion de la création du login BLP * 0.03: gestion de l'ANR * 0.04: ajout du nom du client et du numéro 3ds dans le retour d'authentification * 0.05: liste des cartes * 0.06: détail carte * 0.07: détail point * 0.08: P3F liste des opérations * 0.09: P3F fractionnement * 0.10: gestion des versions * 0.11: infos sejour * 0.12: cartes supplémentaires * 0.13: coordonnées * 0.14: messagerie * 0.15: parametres * 0.16: oppositions * 0.17: déplafonnements * 0.18: activation carte * 0.19: notification push des messages * 0.20: catalogue Rewards * 0.21: transfert d'argent * 0.22: crédit * 0.23: ajout données NC * 2.00: nouveaux services pour BLP2
 *
 * OpenAPI spec version: 2.00
 * Contact: info@piti.pf
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../Conf/encoder';

import { Observable }                                        from 'rxjs';

import { Message } from '../model/message';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../Conf/variables';
import { Configuration }                                     from '../Conf/configuration';


@Injectable({
  providedIn: 'root'
})
export class MessageService {

  protected basePath = 'https://moof-api.piti.pf';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Envoi d&#39;un message
     *
     * @param message le message à envoyer (sujet + message) en JSON. Il faut utiliser (&#39;#/definitions/MessageClient&#39;)
     * @param fichier fichier joint par le client
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public envoiMessage(message: string, fichier?: Blob, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public envoiMessage(message: string, fichier?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public envoiMessage(message: string, fichier?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public envoiMessage(message: string, fichier?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (message === null || message === undefined) {
            throw new Error('Required parameter message was null or undefined when calling envoiMessage.');
        }


        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams };
        let useForm = false;
        const convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (fichier !== undefined) {
            formParams = formParams.append('fichier', <any>fichier) || formParams;
        }
        if (message !== undefined) {
            formParams = formParams.append('message', <any>message) || formParams;
        }

        return this.httpClient.post<any>(`${this.basePath}/messages`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Récupération des messages
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listeMessage(observe?: 'body', reportProgress?: boolean): Observable<Array<Message>>;
    public listeMessage(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Message>>>;
    public listeMessage(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Message>>>;
    public listeMessage(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Message>>(`${this.basePath}/messages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Lecture d&#39;un message
     *
     * @param id id du message
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public messageLu(id: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public messageLu(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public messageLu(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public messageLu(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling messageLu.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
          'application/vnd.moof.v10+json'
        ];


        return this.httpClient.put<any>(`${this.basePath}/messages/${encodeURIComponent(String(id))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Récupère la pièce jointe d&#39;un message
     *
     * @param id id du message
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public pieceJointeMessage(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public pieceJointeMessage(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public pieceJointeMessage(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public pieceJointeMessage(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling pieceJointeMessage.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];

        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];

        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
          headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get(`${this.basePath}/messages/${encodeURIComponent(String(id))}/pieceJointe`,
            {
                responseType: 'blob',
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }


  /**
   * Supression d&#39;une liste de messages
   *
   * @param idList id des messages a supprimer
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public supressionMessages(idList: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public supressionMessages(idList: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public supressionMessages(idList: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public supressionMessages(idList: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    if (idList === null || idList === undefined) {
      throw new Error('Required parameter idList was null or undefined when calling supressionMessages.');
    }

    let headers = this.defaultHeaders;

    // authentication (token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
      headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/vnd.moof.v10+json'
    ];

    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.delete<any>(`${this.basePath}/messages/${encodeURIComponent(String(idList))}/suppression`,
      {
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress
      }
    );
  }
}
