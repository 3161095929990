import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: any[] = [];


  /**
   * Ajoute un toast.
   *
   * @param delay - d'affichage du toast
   * @param titre - titre du toast
   * @param contenu - contenu du toast
   */
  show(titre: string, contenu: string, delay = 5000) {
    this.toasts.push({titre, contenu, delay});
  }

  /**
   * Retire un toast.
   *
   * @param toast - le toast à retirer
   */
  remove(toast: any) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
