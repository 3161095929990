/**
 * web services MooF
 * Web Services REST **V1.0** pour le Home Banking de **OFINA** * 0.01: version initale (gestion du login) * 0.02: gestion de la création du login BLP * 0.03: gestion de l'ANR * 0.04: ajout du nom du client et du numéro 3ds dans le retour d'authentification * 0.05: liste des cartes * 0.06: détail carte * 0.07: détail point * 0.08: P3F liste des opérations * 0.09: P3F fractionnement * 0.10: gestion des versions * 0.11: infos sejour * 0.12: cartes supplémentaires * 0.13: coordonnées * 0.14: messagerie * 0.15: parametres * 0.16: oppositions * 0.17: déplafonnements * 0.18: activation carte * 0.19: notification push des messages * 0.20: catalogue Rewards * 0.21: transfert d'argent * 0.22: crédit * 0.23: ajout données NC
 *
 * OpenAPI spec version: 0.23
 * Contact: info@piti.pf
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../Conf/encoder';


import { Activation } from '../model/activation';
import { ActivationCarte } from '../model/activationCarte';
import { AnrPublic } from '../model/anrPublic';
import { Authentification } from '../model/authentification';
import { CatalogueMrp } from '../model/catalogueMrp';
import { Erreur } from '../model/erreur';
import { FaqData } from '../model/faqData';
import { Identification } from '../model/identification';
import { InfoAnr } from '../model/infoAnr';
import { MessageData } from '../model/messageData';
import { Parametre } from '../model/parametre';
import { PaysData } from '../model/paysData';
import { ProfessionData } from '../model/professionData';
import { RetourAuthentification } from '../model/retourAuthentification';
import { RetourCheckVersionTechnique } from '../model/retourCheckVersionTechnique';
import { TexteData } from '../model/texteData';
import { VersionData } from '../model/versionData';
import { VersionTechnique } from '../model/versionTechnique';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../Conf/variables';
import { Configuration }                                     from '../Conf/configuration';
import {Observable} from 'rxjs';


@Injectable()
export class PublicService {

    protected basePath = 'https://be.piti.pf/MOOF/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Création de l&#39;identifiant de login - étape 3 (activation du compte AD)
     * Fait la création du compte A.D. avec le mot de passe choisi
     *
     * @param activation les données d&#39;activation du client à appliquer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activationIdentification(activation: Activation, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public activationIdentification(activation: Activation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public activationIdentification(activation: Activation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public activationIdentification(activation: Activation, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activation === null || activation === undefined) {
            throw new Error('Required parameter activation was null or undefined when calling activationIdentification.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/identification/activation`,
            activation,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Callback pour le changement d&#39;état du dossier de crédit
     *
     * @param packageId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public callback(packageId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public callback(packageId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public callback(packageId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public callback(packageId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (packageId === null || packageId === undefined) {
            throw new Error('Required parameter packageId was null or undefined when calling callback.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (packageId !== undefined && packageId !== null) {
            queryParameters = queryParameters.set('PackageId', <any>packageId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/credits/callback`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Activation d&#39;une carte
     *
     * @param activationCarte l&#39;activation de la carte
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public carteActivation(activationCarte: ActivationCarte, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public carteActivation(activationCarte: ActivationCarte, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public carteActivation(activationCarte: ActivationCarte, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public carteActivation(activationCarte: ActivationCarte, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (activationCarte === null || activationCarte === undefined) {
            throw new Error('Required parameter activationCarte was null or undefined when calling carteActivation.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/cartes/activation`,
            activationCarte,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Contrôle des numéros de version (api et mobile)
     *
     * @param version la version de l&#39;application mobile
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkVersion(version: VersionTechnique, observe?: 'body', reportProgress?: boolean): Observable<RetourCheckVersionTechnique>;
    public checkVersion(version: VersionTechnique, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RetourCheckVersionTechnique>>;
    public checkVersion(version: VersionTechnique, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RetourCheckVersionTechnique>>;
    public checkVersion(version: VersionTechnique, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling checkVersion.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RetourCheckVersionTechnique>(`${this.basePath}/versions/check`,
            version,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte le Catalogue MRP
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCatalogueMrp(observe?: 'body', reportProgress?: boolean): Observable<CatalogueMrp>;
    public getCatalogueMrp(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CatalogueMrp>>;
    public getCatalogueMrp(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CatalogueMrp>>;
    public getCatalogueMrp(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CatalogueMrp>(`${this.basePath}/datas/catalogueMrp`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte la liste des faq data
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listeFaqData(observe?: 'body', reportProgress?: boolean): Observable<Array<FaqData>>;
    public listeFaqData(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FaqData>>>;
    public listeFaqData(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FaqData>>>;
    public listeFaqData(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<FaqData>>(`${this.basePath}/datas/faq`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte la liste des messages data
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listeMessageData(observe?: 'body', reportProgress?: boolean): Observable<Array<MessageData>>;
    public listeMessageData(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MessageData>>>;
    public listeMessageData(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MessageData>>>;
    public listeMessageData(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MessageData>>(`${this.basePath}/datas/message`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte la liste des parametres
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listeParametre(observe?: 'body', reportProgress?: boolean): Observable<Array<Parametre>>;
    public listeParametre(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Parametre>>>;
    public listeParametre(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Parametre>>>;
    public listeParametre(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Parametre>>(`${this.basePath}/datas/parametre`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte la liste des pays data
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listePaysData(observe?: 'body', reportProgress?: boolean): Observable<Array<PaysData>>;
    public listePaysData(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PaysData>>>;
    public listePaysData(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PaysData>>>;
    public listePaysData(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PaysData>>(`${this.basePath}/datas/pays`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte la liste des profession data
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listeProfessionData(observe?: 'body', reportProgress?: boolean): Observable<Array<ProfessionData>>;
    public listeProfessionData(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProfessionData>>>;
    public listeProfessionData(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProfessionData>>>;
    public listeProfessionData(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ProfessionData>>(`${this.basePath}/datas/profession`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte la liste des textes data
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listeTexteData(observe?: 'body', reportProgress?: boolean): Observable<Array<TexteData>>;
    public listeTexteData(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TexteData>>>;
    public listeTexteData(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TexteData>>>;
    public listeTexteData(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TexteData>>(`${this.basePath}/datas/texte`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte la liste des versions data
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listeVersionData(observe?: 'body', reportProgress?: boolean): Observable<Array<VersionData>>;
    public listeVersionData(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionData>>>;
    public listeVersionData(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionData>>>;
    public listeVersionData(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<VersionData>>(`${this.basePath}/versions/datas`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     *
     * Permet à un abonné de s&#39;authentifier avec son numéro d&#39;abonnement et son mot de passe BLP/MooF et génère un token afin qu&#39;il puisse ensuite utiliser les web services nécessitant une authentification
     *
     * @param authentification l&#39;authentification a effectuer
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public login(authentification: Authentification, observe?: 'body', reportProgress?: boolean): Observable<RetourAuthentification>;
    public login(authentification: Authentification, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RetourAuthentification>>;
    public login(authentification: Authentification, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RetourAuthentification>>;
    public login(authentification: Authentification, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (authentification === null || authentification === undefined) {
            throw new Error('Required parameter authentification was null or undefined when calling login.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RetourAuthentification>(`${this.basePath}/login`,
            authentification,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Validation par code ANR reçu par SMS
     *
     * @param anr l&#39;ANR à valider
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validationAnrPublic(anr: AnrPublic, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public validationAnrPublic(anr: AnrPublic, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public validationAnrPublic(anr: AnrPublic, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public validationAnrPublic(anr: AnrPublic, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (anr === null || anr === undefined) {
            throw new Error('Required parameter anr was null or undefined when calling validationAnrPublic.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/anr/public`,
            anr,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Création de l&#39;identifiant de login - étape 1 (vérification)
     * Vérifie les éléments d&#39;identification présentés par le client
     *
     * @param identification les données d&#39;identification du client à vérifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verificationIdentification(identification: Identification, observe?: 'body', reportProgress?: boolean): Observable<InfoAnr>;
    public verificationIdentification(identification: Identification, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InfoAnr>>;
    public verificationIdentification(identification: Identification, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InfoAnr>>;
    public verificationIdentification(identification: Identification, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (identification === null || identification === undefined) {
            throw new Error('Required parameter identification was null or undefined when calling verificationIdentification.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<InfoAnr>(`${this.basePath}/identification/verification`,
            identification,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
