/**
 * web services MooF adapté pour BLP2
 * Web Services REST **V1.0** pour le Home Banking de **OFINA** * 0.01: version initale (gestion du login) * 0.02: gestion de la création du login BLP * 0.03: gestion de l'ANR * 0.04: ajout du nom du client et du numéro 3ds dans le retour d'authentification * 0.05: liste des cartes * 0.06: détail carte * 0.07: détail point * 0.08: P3F liste des opérations * 0.09: P3F fractionnement * 0.10: gestion des versions * 0.11: infos sejour * 0.12: cartes supplémentaires * 0.13: coordonnées * 0.14: messagerie * 0.15: parametres * 0.16: oppositions * 0.17: déplafonnements * 0.18: activation carte * 0.19: notification push des messages * 0.20: catalogue Rewards * 0.21: transfert d'argent * 0.22: crédit * 0.23: ajout données NC * 2.00: nouveaux services pour BLP2
 *
 * OpenAPI spec version: 2.00
 * Contact: info@piti.pf
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../Conf/encoder';

import { Observable }                                        from 'rxjs/';

import { CatalogueMrp } from '../model/catalogueMrp';
import { FaqData } from '../model/faqData';
import { LienUtile } from '../model/lienUtile';
import { MessageData } from '../model/messageData';
import { MessagePromotionel } from '../model/messagePromotionel';
import { Parametre } from '../model/parametre';
import { PaysData } from '../model/paysData';
import { ProfessionData } from '../model/professionData';
import { Publicite } from '../model/publicite';
import { TexteData } from '../model/texteData';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../Conf/variables';
import { Configuration }                                     from '../Conf/configuration';
import {EnumTypeFaq} from '../model/enumTypeFaq';


@Injectable({
  providedIn: 'root',
})
export class DataService {

    protected basePath = 'https://moof-api.piti.pf';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Remonte le Catalogue MRP
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCatalogueMrp(observe?: 'body', reportProgress?: boolean): Observable<CatalogueMrp>;
    public getCatalogueMrp(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CatalogueMrp>>;
    public getCatalogueMrp(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CatalogueMrp>>;
    public getCatalogueMrp(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CatalogueMrp>(`${this.basePath}/datas/catalogueMrp`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte la liste des liens utiles
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public liensUtiles(observe?: 'body', reportProgress?: boolean): Observable<Array<LienUtile>>;
    public liensUtiles(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LienUtile>>>;
    public liensUtiles(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LienUtile>>>;
    public liensUtiles(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<LienUtile>>(`${this.basePath}/datas/liensUtiles`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte la liste des faq data
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listeFaqData(observe?: 'body', reportProgress?: boolean): Observable<Array<FaqData>>;
    public listeFaqData(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FaqData>>>;
    public listeFaqData(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FaqData>>>;
    public listeFaqData(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<FaqData>>(`${this.basePath}/datas/faq`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte la liste des faq thématiques pour un thème
     *
     * @param faqType type de FAQ thématique retourner
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listeFaqThematique(faqType: EnumTypeFaq, observe?: 'body', reportProgress?: boolean): Observable<Array<FaqData>>;
    public listeFaqThematique(faqType: EnumTypeFaq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FaqData>>>;
    public listeFaqThematique(faqType: EnumTypeFaq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FaqData>>>;
    public listeFaqThematique(faqType: EnumTypeFaq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (faqType === null || faqType === undefined) {
            throw new Error('Required parameter faqType was null or undefined when calling listeFaqThematique.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (faqType !== undefined && faqType !== null) {
            queryParameters = queryParameters.set('faqType', <any>faqType);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
          'application/vnd.moof.v10+json'
        ];

        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
          headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.get<Array<FaqData>>(`${this.basePath}/datas/faqthematique`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte la liste des messages data
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listeMessageData(observe?: 'body', reportProgress?: boolean): Observable<Array<MessageData>>;
    public listeMessageData(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MessageData>>>;
    public listeMessageData(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MessageData>>>;
    public listeMessageData(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MessageData>>(`${this.basePath}/datas/message`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte la liste des parametres
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listeParametre(observe?: 'body', reportProgress?: boolean): Observable<Array<Parametre>>;
    public listeParametre(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Parametre>>>;
    public listeParametre(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Parametre>>>;
    public listeParametre(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Parametre>>(`${this.basePath}/datas/parametre`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte la liste des pays data
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listePaysData(observe?: 'body', reportProgress?: boolean): Observable<Array<PaysData>>;
    public listePaysData(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PaysData>>>;
    public listePaysData(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PaysData>>>;
    public listePaysData(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PaysData>>(`${this.basePath}/datas/pays`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte la liste des profession data
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listeProfessionData(observe?: 'body', reportProgress?: boolean): Observable<Array<ProfessionData>>;
    public listeProfessionData(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProfessionData>>>;
    public listeProfessionData(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProfessionData>>>;
    public listeProfessionData(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ProfessionData>>(`${this.basePath}/datas/profession`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte la liste des textes data
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listeTexteData(observe?: 'body', reportProgress?: boolean): Observable<Array<TexteData>>;
    public listeTexteData(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TexteData>>>;
    public listeTexteData(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TexteData>>>;
    public listeTexteData(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<TexteData>>(`${this.basePath}/datas/texte`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Donne le message promotionel a afficher si il y en a un
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public messagePromotionel(observe?: 'body', reportProgress?: boolean): Observable<MessagePromotionel>;
    public messagePromotionel(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MessagePromotionel>>;
    public messagePromotionel(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MessagePromotionel>>;
    public messagePromotionel(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MessagePromotionel>(`${this.basePath}/datas/messagePromotionel`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte la configuration de la publicité
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicite(observe?: 'body', reportProgress?: boolean): Observable<Publicite>;
    public publicite(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Publicite>>;
    public publicite(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Publicite>>;
    public publicite(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Publicite>(`${this.basePath}/datas/publicite`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Remonte le texte de l&#39;encadré promotionnel
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public textePromotionnel(observe?: 'body', reportProgress?: boolean): Observable<Parametre>;
    public textePromotionnel(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Parametre>>;
    public textePromotionnel(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Parametre>>;
    public textePromotionnel(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Parametre>(`${this.basePath}/datas/textePromotionnel`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
