import {SituationCarte} from '../../api/model/situationCarte';
import {PortefeuilleCarte as PCarte} from '../../api/model/portefeuilleCarte';

export class PortefeuilleCarte  implements PCarte {
  cartes: SituationCarte[];
  cartesSupplementaires: SituationCarte[];

  constructor( readonly portefeuille: PCarte ) {
    this.cartes = portefeuille.cartes ;
    this.cartesSupplementaires = portefeuille.cartesSupplementaires ;

  }

  /**
   * @returns le nombre de carte du portefeuille
   */
  getNbCarte(): number {
    let nbCarte = 0;
    if (this.cartes) {
      nbCarte += this.cartes.length ;
    }
    if (this.cartesSupplementaires) {
      nbCarte += this.cartesSupplementaires.length;
    }
    return nbCarte;
  }

  /**
   * @returns le solde total de la carte
   */
  getSoldeTotalCarte(): number {
      let solde = 0;
      if (this.cartes ) {
        solde += this.cartes.reduce((a, c) => a + c.soldeActuel, 0);
      }
      if (this.cartesSupplementaires) {
        solde += this.cartesSupplementaires.reduce((a, c) => a + c.soldeActuel, 0);
      }
      return solde;
  }

  /**
   * @returns le solde total des encours fl3xpay
   */
  getSoldeTotalEncoursFL3XPAY(): number {
      let solde = 0;
      if (this.cartes) {
        solde += this.cartes.reduce((a, c) => a + c.encoursP3f, 0);
      }
      if (this.cartesSupplementaires) {
        solde += this.cartesSupplementaires.reduce((a, c) => a + c.encoursP3f, 0);
      }
      return solde;
  }

  /**
   * @returns le solde total des encours planpay
   */
  getSoldeTotalEnCoursPlanPay(): number {
      let solde = 0;
      if (this.cartes) {
        solde += this.cartes.reduce((a, c) => a + c.encoursCnf, 0);
      }
      if (this.cartesSupplementaires) {
        solde += this.cartesSupplementaires.reduce((a, c) => a + c.encoursCnf, 0);
      }
      return solde;
  }

  /**
   * Indique si il y a des cartes supplémentaires
   */
  hasCarteSuplementaire(): boolean {
      return this.cartesSupplementaires &&
        this.cartesSupplementaires.length > 0;
  }

  /**
   * @returns les cartes du portefeuille
   */
  getCartes(): SituationCarte[] {
      return this.cartes;
  }

  /**
   * @returns les cartes supplémentaire du portefeuille
   */
  getCartesSuplementaire(): SituationCarte[] {
      return this.cartesSupplementaires;
  }
}
