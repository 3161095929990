import {Injectable} from '@angular/core';
import {PortefeuilleCarte} from '../models/PortefeuilleCarte';
import {NGXLogger} from 'ngx-logger';
import {HttpErrorResponse} from '@angular/common/http';
import {CarteService as ApiCarteService} from '../../api/api/carte.service';
import {environment} from '../../../environments/environment.local-dev';
import {SituationCartePoints} from '../models/situation-carte-points';
import {Router} from '@angular/router';
import {InfosDeplafonnement} from '../../api/model/infosDeplafonnement';
import {Deplafonnement} from '../../api/model/deplafonnement';
import {PlafondCarte} from '../../api/model/plafondCarte';
import {Erreur} from '../../api/model/erreur';
import {InfoAnr} from '../../api/model/infoAnr';
import {MotifOpposition} from '../../api/model/motifOpposition';
import {ActivationCarte} from '../../api/model/activationCarte';
import {ParamAlerteCarte} from '../../api/model/paramAlerteCarte';
import {Url} from '../constant/url';

export interface ICarteService {

  loadCarte(): Promise<SituationCartePoints>;

  naviguateAccueilCarte(newCarte: any): void;

  infoDeplafonement(): Promise<InfosDeplafonnement>;

  deplafonement(paramDuree: number, paramPlafondPaiement: PlafondCarte): Promise<Erreur>;

  loadParamAlerteCarte(): Promise<ParamAlerteCarte>;

  gestionAlertePossible(): boolean;

}

@Injectable({
  providedIn: 'root'
})
export class CarteService {
  /**
   * Portefeuille de carte de l'utilisateur courant
   */
  #portefeuilleCarte: PortefeuilleCarte;
  /**
   * Carte sélectionné dans le portefeuille, ou seule carte du portefeuille
   */
  #selectedCard: SituationCartePoints;
  /**
   * Cle de la carte pour la stocker dans le store
   */
  private readonly keyCard = 'selectedCard';
  /**
   * Clés du portefeuille de carte pour le stocker dans le store
   */
  private readonly keyPortfeuille = 'portefeuilleCarte';


  constructor(
    private readonly logger: NGXLogger,
    private readonly apiCarteService: ApiCarteService,
    private readonly router: Router,
  ) {
    if (environment.dev) {
      const storeCard = sessionStorage.getItem(this.keyCard);
      const storePortefeuille = sessionStorage.getItem(this.keyPortfeuille);
      if (storeCard) {
        this.logger.info('Card fetch from the store', storeCard);
        this.selectedCard = new SituationCartePoints(JSON.parse((storeCard)));
        this.logger.debug('Card created from the session', this.selectedCard);
      }
      if (storePortefeuille && storePortefeuille !== 'null') {
        this.logger.info('Portefeuille fetch from the store', storeCard);
        this.#portefeuilleCarte = new PortefeuilleCarte(JSON.parse((storePortefeuille)));
        this.logger.debug('Portefeuille created from the session', this.portefeuilleCarte);
      }
    }
  }

  /**
   * @returns le portefeuille de carte
   */
  get portefeuilleCarte(): PortefeuilleCarte {
    return this.#portefeuilleCarte;
  }

  /**
   * @returns la carte sélectionnée
   */
  get selectedCard(): SituationCartePoints {
    return this.#selectedCard;
  }

  /**
   * Change la carte sélectionné
   *
   * @param c - la nouvelle carte
   */
  set selectedCard(c: SituationCartePoints) {
    this.#selectedCard = c;
    this.stockeCarte();
    this.logger.info('Carte sélectionnée', c);
  }

  /**
   * Charge le portefeuille de carte
   *
   * @returns une promesse du portefeuille de carte
   */
  loadPortefeuille(): Promise<PortefeuilleCarte> {
    this.logger.debug('Appel WS cartes');

    return new Promise<PortefeuilleCarte>((resolve, reject) => {
      this.apiCarteService.cartes().subscribe({
        next: (pCarte) => {
          this.logger.debug(pCarte);
          this.#portefeuilleCarte = new PortefeuilleCarte({
            cartes: pCarte.cartes,
            cartesSupplementaires: pCarte.cartesSupplementaires
          });
          this.stockePortefeuilleCarte();
          resolve(this.portefeuilleCarte);
        },
        error: (err: HttpErrorResponse) => {
          this.logger.error(err);
          reject(err);
        }
      });
    });
  }

  /**
   * Enlève la carte stocké (changement de carte sélectionné)
   */
  removeSelectedCard(): void {
    this.selectedCard = null;
    this.stockeCarte();
  }

  /**
   * Enlève le portefeuille de carte stocker
   */
  removePortefeuille(): void {
    this.#portefeuilleCarte = null;
    this.stockePortefeuilleCarte();
  }

  /**
   * Chargement de la carte selectedId
   *
   * @returns une promise de carte
   */
  loadCarte(newCarteId: string): Promise<SituationCartePoints> {
    return new Promise<SituationCartePoints>((resolve, reject) => {
      if (newCarteId) {
        this.logger.debug('Appel WS carte', newCarteId);
        this.apiCarteService.situationCartePoints(newCarteId).subscribe({
          next: (situationCartePoints) => {
            this.logger.debug('CarteSituationPoint', situationCartePoints);
            this.selectedCard = new SituationCartePoints(situationCartePoints);
            this.stockeCarte();
            resolve(this.selectedCard);
          }, error: err => {
            this.logger.error('Erreur de recuperation de la carte');
            reject(err);
          }
        });
      } else {
        reject('No card selected');
      }
    });

  }


  /**
   * Navigue a la page accueil carte
   *
   * @param newCarteId - id de la carte
   * @returns un boolean indiquant si la navigation est réussi ou non
   */
  naviguateAccueilCarte(newCarteId: string = this.selectedCard.id): Promise<boolean> {
    this.removeSelectedCard();
    return this.router.navigateByUrl('/' + Url.PAGE_ACCUEIL, {
      state: {
        newCarteId
      },
    });
  }

  /**
   * Chargement des information de déplafonnement
   *
   * @returns une promise des informations de déplafonnement
   */
  infoDeplafonement(): Promise<InfosDeplafonnement> {
    this.logger.debug('Appel WS carte info déplafonnement', this.selectedCard.id);
    return new Promise<InfosDeplafonnement>((resolve, reject) => {
      this.apiCarteService.carteInfosDeplafonnement(this.selectedCard.id).subscribe({
        next: (infosDeplafonement: InfosDeplafonnement) => {
          this.logger.debug('Info déplafonnement', infosDeplafonement);
          resolve(infosDeplafonement);
        }, error: err => {
          this.logger.error('Erreur de recuperation des informations de déplafonnement');
          reject(err);
        }
      });
    });
  }

  /**
   * Demande de déplafonnement de la carte
   *
   * @param paramDuree - number
   * @param paramPlafondPaiement - PlafondCarte
   */
  deplafonnement(paramDuree: number, paramPlafondPaiement: PlafondCarte): Promise<InfoAnr> {
    this.logger.debug('Appel WS carte déplafonnement', this.selectedCard.id);
    const deplafonement: Deplafonnement = {
      duree: paramDuree,
      plafondPaiement: paramPlafondPaiement
    };
    return new Promise<InfoAnr>((resolve, reject) => {
      this.apiCarteService.carteDeplafonnement(this.selectedCard.id, deplafonement).subscribe({
        next: (value) => {
          this.logger.debug('Déplafonnement', value);
          resolve(value);
        }, error: err => {
          this.logger.error('Erreur de soumissions du déplafonnement', err.error);
          reject(err);
        }
      });
    });
  }

  /**
   * Mise en opposition d'une carte pour le motif "motif".
   *
   * @param motif - motif de mise en opposition
   */
  opposition(motif: MotifOpposition): Promise<InfoAnr> {
    this.logger.debug('Appel WS carte opposition', this.selectedCard.id, motif);
    return new Promise<InfoAnr>((resolve, reject) => {

      this.apiCarteService.carteOpposition(this.selectedCard.id, motif).subscribe({
        next: (infoAnr: InfoAnr) => {
          this.logger.debug('Opposition', infoAnr);
          resolve(infoAnr);
        }, error: err => {
          this.logger.error('Erreur de soumissions de l\'opposition');
          reject(err);
        }
      });
    });
  }

  /**
   * Freeze de la carte sélectionnée
   *
   * @returns une promesse
   */
  freeze(): Promise<void> {
    this.logger.debug('Appel WS carte freeze', this.selectedCard.id);
    return new Promise<void>((resolve, reject) => {
      this.apiCarteService.carteFreeze(this.selectedCard.id).subscribe({
        next: () => {
          this.logger.debug('Freeze succeed');
          resolve();
        }, error: err => {
          this.logger.error('Erreur lors du freeze');
          reject(err);
        }
      });
    });
  }

  /**
   * Unfreeze de la carte courante
   *
   * @returns une promesse
   */
  unfreeze(): Promise<any> {
    this.logger.debug('Appel WS carte unfreeze', this.selectedCard.id);
    return new Promise<any>((resolve, reject) => {
      this.apiCarteService.carteUnfreeze(this.selectedCard.id).subscribe({
        next: () => {
          this.logger.debug('unfreeze succeed');
          resolve();
        }, error: err => {
          this.logger.error('Erreur lors du unfreeze');
          reject(err);
        }
      });
    });
  }

  /**
   * Toggle le freeze status
   *
   * @param status - le status
   */
  changeFreezeStatus(status: boolean): Promise<void> {
    if (status) {
      return this.freeze();
    } else {
      return this.unfreeze();
    }
  }

  /**
   * Activation de la carte
   *
   * @param cardNumber - numéro de la carte
   * @param cardExp - date d'expiration de la carte
   */
  activationCarte(cardNumber: string, cardExp: string): Promise<InfoAnr> {
    this.logger.debug('Appel WS activation carte');
    const activationCarte: ActivationCarte = {
      dateValidite: cardExp,
      numeroCarte: cardNumber
    };
    return new Promise<InfoAnr>((resolve, reject) => {
      this.apiCarteService.carteActivation(activationCarte).subscribe({
        next: (info: InfoAnr) => {
          this.logger.debug('Activation réussie');
          resolve(info);
        },
        error: err => {
          this.logger.debug('Erreur d\'activation de la carte ');
          reject(err);
        }
      });
    });
  }

  /**
   * Recuperation des paramètre d'alerte de la carte sélectionnée
   *
   * @returns une promesse de paramètre de carte
   */
  loadParamAlerteCarte(): Promise<ParamAlerteCarte> {
    this.logger.debug('Appel du WS param alerte cartes');
    return new Promise<ParamAlerteCarte>((resolve, reject) => {
      this.apiCarteService.getCartesParamAlerte(this.selectedCard.id).subscribe({
        next: (value) => {
          this.logger.debug('Param alerte carte', value);
          resolve(value);
        },
        error: err => {
          this.logger.debug('Echec de chargement des paramettre d\'alerte de la carte');
          reject(err);
        }
      });
    });
  }

  /**
   * Change les paramètre d'alerte de la carte sélectionnée
   *
   * @param paramAlerte - les paramètres d'alertes
   */
  changeParamAlerteCarte(paramAlerte: ParamAlerteCarte): Promise<void> {
    this.logger.debug('Appel WS parametre alerte carte', paramAlerte);
    return new Promise<void>((resolve, reject) => {
      this.apiCarteService.setCartesParamAlerte(this.selectedCard.id, paramAlerte).subscribe({
        next: () => {
          this.logger.debug('Modification parametre alerte carte reussi');
          resolve();
        },
        error: err => {
          this.logger.debug('Echec modification parametre alerte carte');
          reject(err);

        }
      });
    });
  }


  /**
   * Vide les données du service
   */
  clearData(): void {
    this.removePortefeuille();
    this.removeSelectedCard();
  }

  /**
   * @returns true si la gestion des alertes est disponible pour cette carte
   */
  public gestionAlertePossible(): boolean {
    return (!this.selectedCard.isBloque() && !this.selectedCard.isOppose() && !this.selectedCard.isFreeze());
  }

  /**
   * Stoke la carte dans le store
   */
  private stockeCarte(): void {
    if (environment.dev) {
      this.logger.debug('Stocke la carte dans la session');
      if (this.selectedCard) {
        sessionStorage.setItem(this.keyCard, JSON.stringify(this.selectedCard));
      } else {
        sessionStorage.removeItem(this.keyCard);
      }
    }
  }

  /**
   * Stocke le portefeuille de carte dans le store
   */
  private stockePortefeuilleCarte(): void {
    if (environment.dev) {
      this.logger.debug('Stocke le portefeuille de carte dans la session');
      if (this.portefeuilleCarte) {
        sessionStorage.setItem(this.keyPortfeuille, JSON.stringify(this.portefeuilleCarte));
      } else {
        sessionStorage.removeItem(this.keyPortfeuille);
      }
    }
  }


}
