import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './Conf/configuration';
import { HttpClient } from '@angular/common/http';


import { ANRService } from './api/aNR.service';
import { CarteService } from './api/carte.service';
import { CreditService } from './api/credit.service';
import { DataService } from './api/data.service';
import { FlexPayService } from './api/flexPay.service';
import { FormulaireService } from './api/formulaire.service';
import { LoginService } from './api/login.service';
import { MRPService } from './api/mRP.service';
import { MessageService } from './api/message.service';
import { OperationService } from './api/operation.service';
import { ParametreService } from './api/parametre.service';
import { PointService } from './api/point.service';
import { PrivService } from './api/priv.service';
import { PublicService } from './api/public.service';
import { TransfertArgentService } from './api/transfertArgent.service';
import { VersionService } from './api/version.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ANRService,
    CarteService,
    CreditService,
    DataService,
    FlexPayService,
    FormulaireService,
    LoginService,
    MRPService,
    MessageService,
    OperationService,
    ParametreService,
    PointService,
    PrivService,
    PublicService,
    TransfertArgentService,
    VersionService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<any> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
