import { Injectable } from '@angular/core';
import {DataService as ApiDataService} from '../../api/api/data.service';
import {MessageData} from '../../api/model/messageData';
import {NGXLogger} from 'ngx-logger';
import {TexteData} from '../../api/model/texteData';
import {FaqData} from '../../api/model/faqData';
import {Parametre} from '../../api/model/parametre';
import {MessagePromotionel} from '../../api/model/messagePromotionel';
import {PaysData} from '../../api/model/paysData';
import {Publicite} from '../../api/model/publicite';
import {LienUtile} from '../../api/model/lienUtile';
import {EnumTypeFaq} from '../../api/model/enumTypeFaq';
import {MoofConstante} from '../constant/moof-constante';
import {SituationCarte} from '../models/situation-carte';


export interface IDataMessageService {
  listeMessageData: Map<string, { messageNC: string; message: string }>;
  domaine: string;

  loadMessage(): Promise<MessageData[]>;

  findMessageData(cle: string, codePays: string): string;

  loadTexteData(): Promise<TexteData[]>;

  findTexteData(cle: string, codePays: string): string;

  loadFaqData(): Promise<FaqData[]>;

  loadFaqDataThematique(theme: EnumTypeFaq): Promise<FaqData[]>;

  loadParam(): Promise<Parametre[]>;

  findParam(cle: string): string;

  loadPays(): Promise<PaysData[]>;

  actionCarteImpossible(carte: SituationCarte, codePays: string): string;

}

@Injectable({
  providedIn: 'root'
})
export class DataMessageService  implements IDataMessageService {
  /** Instance du service */
  static instance: DataMessageService;
  /** Indique si le message promotionnel a été afficher */
  #displayMessagePromo: boolean;
  /** Indique si le texte promotionnel a été afficher */
  #displayTextePromo: boolean;
  init = false;
  /** Le nom de domaine */
  domaine: string;
  /** Liste des messages data */
  listeMessageData: Map<string, { messageNC: string; message: string }>;
  /** Liste des textes data */
  private listeTextData: Map<string, { texteNC: string; titre: string; texte: string }>;
  /** Liste des paramètres */
  private listeParam: Map<string, string>;


  constructor(
    private readonly apiDataService: ApiDataService,
    private readonly logger: NGXLogger,
  ) {
    this.#displayMessagePromo = true;
    this.#displayTextePromo = true;
    DataMessageService.instance = this;
  }

  /**
   * Indique que l'initialisation c'est bien terminé
   */
  initSucessfull(): void {
    this.init = true;
  }

  /**
   * Chargement des messages
   *
   * @returns la liste des messages data
   */
  loadMessage(): Promise<MessageData[]> {
    this.logger.debug('Appel WS Message Data');
    return new Promise<MessageData[]>((resolve, reject) => {
      this.apiDataService.listeMessageData().subscribe({
        next: (m: MessageData[]) => {
          this.listeMessageData = new Map(
            m.map((messageData) => [
                messageData.cle,
                {message: messageData.message, messageNC: messageData.messageNC}
              ]
            ));
          this.logger.debug('Chargement message Data', m);
          resolve(m);
        },
        error: (err) => {
          this.logger.error(err);
          reject(err);
        }});
    });
  }

  /**
   * Permet de récupérer un message data
   *
   * @param cle - nom du message data
   * @param codePays - code du pays (PF ou NC)
   * @returns Le message data demandé pour le pays
   */
  findMessageData(cle: string, codePays: string): string {
    if (codePays.toUpperCase() === 'NC') {
      return this.listeMessageData?.get(cle)?.messageNC;
    } else {
      return this.listeMessageData?.get(cle)?.message;
    }
  }

  /**
   * Permet de récupérer la liste des textes data
   *
   * @returns Les texte data
   */
  loadTexteData(): Promise<TexteData[]> {
    this.logger.debug('Appel WS texte Data');
    return new Promise<TexteData[]>((resolve, reject) => {
      this.apiDataService.listeTexteData().subscribe({
        next: (t: TexteData[]) => {
          this.logger.debug('Texte Data', t);
          this.listeTextData = new Map(
            t.map((texteData: TexteData) => [
                texteData.cle,
                {texte: texteData.texte, texteNC: texteData.texteNC, titre: texteData.titre}
              ]
            ));
          resolve(t);
        },
        error: (err) => {
          this.logger.error(err);
          reject(err);
        }});
    });
  }

  /**
   * Permet de récupérer un texte data
   *
   * @param cle -  nom du message data
   * @param codePays - code du pays (PF ou NC)
   * @returns Le texte data demande pour le pays
   */
  findTexteData(cle: string, codePays: string): string {
    if (codePays.toUpperCase() === 'NC') {
      return this.listeTextData?.get(cle)?.texteNC;
    } else {
      return this.listeTextData?.get(cle)?.texte;
    }
  }

  /**
   * Permet de récupérer la liste des questions de la faq
   *
   * @returns La liste des message de la faq
   */
  loadFaqData(): Promise<FaqData[]> {
    return new Promise<FaqData[]>((resolve, reject) => {
      this.apiDataService.listeFaqData().subscribe({
        next: (faqData) => {
          this.logger.debug('Recuperation des faqData', faqData);
          resolve(faqData);
        },
        error: err => {
          this.logger.debug('Erreur de recuperation des faqData', err);
          reject(err);
        } } );
    });
  }

  /**
   * Permet de récupérer la liste des questions de la faq
   */
  loadFaqDataThematique(theme: EnumTypeFaq): Promise<FaqData[]> {
    return new Promise<FaqData[]>((resolve, reject) => {
      this.apiDataService.listeFaqThematique(theme).subscribe({
        next: (faqData) => {
          this.logger.debug('Recuperation des faqData thematique', faqData);
          resolve(faqData);
        },
        error: err => {
          this.logger.debug('Erreur de recuperation des faqData thematique', err);
          reject(err);
        } } );
    });
  }

  /**
   * Recuperation des paramètres
   *
   * @returns La liste des paramètres
   */
  loadParam(): Promise<Parametre[]> {
    return new Promise<Parametre[]>((resolve, reject) => {
      this.apiDataService.listeParametre().subscribe({
        next: (params) => {
          this.logger.debug('Load param', params);
          this.listeParam = new Map(
            params.map((param) => [
                param.cle, param.valeur
              ]
            ));
          resolve(params);
        }, error: err => {
          this.logger.error('Échec de recuperation des paramètres', err);
          reject(err);
        }
      });
    });
  }

  /**
   * Permet de récupérer un paramètre
   *
   * @param cle - nom du message data
   * @returns Le paramètre
   */
  findParam(cle: string): string {
    return this.listeParam?.get(cle);
  }

  /**
   * Recuperation du message promotionnel
   *
   * @returns Le message promotionnel
   */
  loadMessagePromo(): Promise<MessagePromotionel> {
    this.logger.debug('Appel WS message promo');
    return new Promise<MessagePromotionel>((resolve, reject) => {
      this.apiDataService.messagePromotionel().subscribe({
        next: (value: MessagePromotionel) => {
          this.logger.debug('Recuperation message promo', value);
          resolve(value);
        },
        error: (err) => {
          this.logger.debug('Echec de recuperation du message promo', err);
          reject(err);
        }
      });
    });
  }

  /**
   * Indique si le message promotionnel doit être affiché.
   *
   * @returns true si le message doit être affiché.
   */
  get displayMessagePromo(): boolean {
    return this.#displayMessagePromo;
  }

  /**
   * Change la valeur du champ message promo display
   *
   * @param disp - la nouvelle valeur
   */
  set displayMessagePromo( disp: boolean) {
    this.#displayMessagePromo = disp;
  }

  /**
   * Indique le message promotionnel ne doit plus être affiche.
   */
  messagePromoDisplayed(): void {
    this.#displayMessagePromo = false;
  }

  /**
   * Charge le texte promotionnel
   *
   * @returns Le texte promotionnel
   */
  loadtextePromotionnel(): Promise<Parametre> {
    this.logger.debug('Appel WS texte promo');
    return new Promise<Parametre>((resolve, reject) => {
      this.apiDataService.textePromotionnel().subscribe({
        next: (value: Parametre) => {
          this.logger.debug('Recuperation texte promo', value);
          resolve(value);
        },
        error: (err) => {
          this.logger.debug('Echec de recuperation du texte promo', err);
          reject(err);
        }
      });
    });

  }

  /**
   * Indique si le texte promotionnel doit être affiché
   *
   * @returns true si le texte doit être affiché.
   */
  get displayTextePromo(): boolean {
    return this.#displayTextePromo;
  }

  /**
   * Change la valeur du champ display texte Promo
   *
   * @param disp - true si le texte doit être affiché.
   */
  set displayTextePromo(disp: boolean) {
    this.#displayTextePromo = disp;
  }

  /**
   * Indique le texte promotionnel ne doit plus être affiché.
   */
  textePromoDisplayed(): void {
    this.#displayTextePromo = false;
  }

  /**
   * Chargement des pays
   *
   * @returns un promesse des pays
   */
  loadPays(): Promise<PaysData[]> {
    this.logger.debug('Appel WS data pays');
    return new Promise<PaysData[]>((resolve, reject) => {
      this.apiDataService.listePaysData().subscribe({
        next: (value: PaysData[]) => {
          this.logger.debug('Recuperation pays', value);
          resolve(value);
        },
        error: (err) => {
          this.logger.debug('Echec de recuperation des pays', err);
          reject(err);
        }
      });
    });
  }

  /**
   * Chargement des publicités
   *
   * @returns les publicités
   */
  loadPublicite(): Promise<Publicite> {
    this.logger.debug('Appel WS data publicite');
    return new Promise<Publicite>((resolve, reject) => {
      this.apiDataService.publicite().subscribe({
        next: (value: Publicite) => {
          this.logger.debug('Recuperation publicite', value);
          resolve(value);
        },
        error: (err) => {
          this.logger.debug('Echec de recuperation des publicite', err);
          reject(err);
        }
      });
    });
  }

  /**
   * Chargement des liens utiles
   *
   * @returns un promesse des liens utiles
   */
  loadLienUtiles(): Promise<LienUtile[]> {
    this.logger.debug('Appel WS data lien utiles');
    return new Promise<LienUtile[]>((resolve, reject) => {
      this.apiDataService.liensUtiles().subscribe({
        next: (value: LienUtile[]) => {
          this.logger.debug('Recuperation lien utiles', value);
          resolve(value);
        },
        error: (err) => {
          this.logger.debug('Echec de recuperation des lien utiles', err);
          reject(err);
        }
      });
    });
  }

  /**
   * Message action carte impossible
   *
   * @param carte la carte
   * @param codePays le code pays
   */
  actionCarteImpossible(carte: SituationCarte, codePays: string): string{
    if (carte.isFreeze()) {
       return this.findMessageData(MoofConstante.MESSAGE_ERR_CARTE_FREEZE, codePays);
    } else if (carte.isOppose()){
      return  this.findMessageData(MoofConstante.MESSAGE_ERR_CARTE_OPPOSE, codePays);
    } else if (carte.isBloque()){
      return this.findMessageData(MoofConstante.MESSAGE_ERR_CARTE_BLOQUE, codePays);
    }
    return '';
  }
}
