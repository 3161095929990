import {ErrorMessage} from 'ng-bootstrap-form-validation';

/**
 * Messages d'erreurs version française pour le module ng-bootstrap-form-validation.
 */
export class CustomErrors {
  /** La liste des messages d'erreur. */
  public static CUSTOM_ERRORS: ErrorMessage[] = [
    {
      error: 'required',
      format: CustomErrors.requiredFormat,
    }, {
      error: 'email',
      format: CustomErrors.emailFormat,
    },
    {
      error: 'minlength',
      format: CustomErrors.minlengthFormat,
    }, {
      error: 'maxlength',
      format: CustomErrors.maxlengthFormat,
    },
    {
      error: 'pattern',
      format: CustomErrors.patternFormat,
    },
    {
      error: 'min',
      format: CustomErrors.min,
    },
    {
      error: 'max',
      format: CustomErrors.max,
    },
    // TODO mettre les autres erreurs -> voir le fichier ng-bootstrap-for-validation.umd.js ligne 398 dans le projet
  ];

  private static emailFormat(label: string, error: any): string {
    return `L'adresse mail n'est pas correcte`;
  }

  private static requiredFormat(label: string): string {
    return `Le champ est obligatoire`;
  }

  private static minlengthFormat(label: string, error: any): string {
    return `Le champ doit faire au moins ${error.requiredLength} caractères`;
  }

  private static maxlengthFormat(label: string, error: any): string {
    return `Le champ doit faire au plus ${error.requiredLength} caractères`;
  }

  private static patternFormat(label: string): string {
    return 'Le champ est invalide';
  }

  private static min(label: string, value: { min: number; actual: string }): string {
    return 'Le champ doit être au minimum égal à ' + value.min;
  }

  private static max(label: string, value: { max: number; actual: string }): string {
    return 'Le champ doit être au maximum égal à ' + value.max;
  }
}
