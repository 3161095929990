

<div class="custom-menu float-left"  id="sidecontrol" [ngClass]="loadSidebarButton()? '': 'hide'">
  <button
    type="button"
    id="sidebarCollapse"
    (click)="onSideBarMenuClick()"
    class="btn btn-blp-grey">

    <i class="fa fa-bars"></i>
    <span class="sr-only">Toggle Menu</span>
  </button>
  <i class="ml-1 fas fa-angle-right fa-2x  align-middle"></i>
</div>
