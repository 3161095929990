import { Component } from '@angular/core';
import {MenuService} from '../../../services/menu.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent  {

  constructor( private readonly menuService: MenuService) { }



  /**
   * Charge le contenu de la sidebar
   */
  loadSidebarContent(): string {
    return this.menuService.sidebarContent;
  }

  /**
   * Action au click sur la sideBar
   */
  onSideBarClick(): void {
    this.menuService.onSideBarClick();
  }

  /**
   * @returns l'id de l'accordéon déplié
   */
  loadIdAccordionExpanded(): string {
    return this.menuService.idAccordionExpanded;
  }

  /**
   * @returns true si le menu est en mode bouton
   */
  loadSidebarMenu(): boolean {
    return this.menuService.sidebarMenu;
  }
}
