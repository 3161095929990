/**
 * web services MooF
 * Web Services REST **V1.0** pour le Home Banking de **OFINA** * 0.01: version initale (gestion du login) * 0.02: gestion de la création du login BLP * 0.03: gestion de l'ANR * 0.04: ajout du nom du client et du numéro 3ds dans le retour d'authentification * 0.05: liste des cartes * 0.06: détail carte * 0.07: détail point * 0.08: P3F liste des opérations * 0.09: P3F fractionnement * 0.10: gestion des versions * 0.11: infos sejour * 0.12: cartes supplémentaires * 0.13: coordonnées * 0.14: messagerie * 0.15: parametres * 0.16: oppositions * 0.17: déplafonnements * 0.18: activation carte * 0.19: notification push des messages * 0.20: catalogue Rewards * 0.21: transfert d'argent * 0.22: crédit * 0.23: ajout données NC
 *
 * OpenAPI spec version: 0.23
 * Contact: info@piti.pf
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';


import {CartePoints} from '../model/cartePoints';
import {CommandeReward} from '../model/commandeReward';

import {Observable} from 'rxjs';
import {Configuration} from '../Conf/configuration';
import {BASE_PATH} from '../Conf/variables';


@Injectable()
export class MRPService {

    protected basePath = 'https://be.piti.pf/MOOF/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Récupère la liste des cartes à points
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cartesRewards(observe?: 'body', reportProgress?: boolean): Observable<Array<CartePoints>>;
    public cartesRewards(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CartePoints>>>;
    public cartesRewards(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CartePoints>>>;
    public cartesRewards(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CartePoints>>(`${this.basePath}/cartes/rewards`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Envoyer une commande
     *
     * @param commandes
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postCommande(commandes: CommandeReward, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public postCommande(commandes: CommandeReward, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public postCommande(commandes: CommandeReward, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public postCommande(commandes: CommandeReward, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (commandes === null || commandes === undefined) {
            throw new Error('Required parameter commandes was null or undefined when calling postCommande.');
        }

        let headers = this.defaultHeaders;

        // authentication (token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Token-moof']) {
            headers = headers.set('Token-moof', this.configuration.apiKeys['Token-moof']);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/vnd.moof.v10+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/rewards/commande`,
            commandes,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

}
