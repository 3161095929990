import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from '../services/user.service';
import {Url} from '../constant/url';
import {NGXLogger} from 'ngx-logger';



@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly userService: UserService,
    private readonly logger: NGXLogger,
    private readonly router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (! this.userService.isConnected()) {
      this.logger.debug('redirection login');
      this.userService.logOut();
    } else if ( ! this.userService.isCguValide()) {
      this.logger.debug('redirection cgu', Url.CGU);
      this.router.navigateByUrl('/' + Url.CGU);
    }

    return this.userService.isConnected() && this.userService.isCguValide();
  }

}
